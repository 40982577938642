<ng-template let-notificationMSG="data" #notifyTmp >
  <div class="ant-notification-notice-content">
    <div class="ant-notification-notice-with-icon">
          <span class="ant-notification-notice-icon position-relative">
            <i nz-icon nzType="bell" [style.color]="'#0070f7'" [style.font-size]="'22px'"></i>
          </span>
      <div class="ant-notification-notice-description" [style.margin-top]="'-22px'" [style.padding-right]="'10px'" >
        <a *ngIf="checkNotificationLink(notificationMSG) && notificationMSG.key !== 'content.linkedin.expired'"
           target="_blank"
           href="{{notificationMSG.notifiable_path}}"
           (click)="mark_read(notificationMSG)"
           [style.font-weight]="'bold'"
           [style.color]="'#0070f7'">
          {{notificationMSG.text}}
        </a>
        <a *ngIf="!checkNotificationLink(notificationMSG) && notificationMSG.key !== 'content.linkedin.expired'"
          routerLink="/{{notificationMSG.notifiable_path}}"
           (click)="mark_read(notificationMSG)"
           [style.font-weight]="'bold'"
           [style.color]="'#0070f7'">
          {{notificationMSG.text}}
        </a>
        <a *ngIf="notificationMSG.key === 'content.linkedin.expired'"
           href="javascript:"
          (click)="mark_read(notificationMSG)"
           [style.font-weight]="'bold'"
           [style.color]="'#0070f7'">
          {{notificationMSG.text}}
        </a>
      </div>
    </div>
  </div>
</ng-template>
<a nz-dropdown [nzDropdownMenu]="menu" [nzTrigger]="'click'" class="" [nzClickHide]="false" [(nzVisible)]="visible">
    <nz-badge [nzCount]="newCount">
      <a class="notification-box">
        <i nz-icon nzType="bell" nzTheme="outline"></i>
      </a>
    </nz-badge>
  </a>
<nz-dropdown-menu #menu="nzDropdownMenu" >

  <div nz-menu class="notification-dropdown">
    <nz-list class="notification-item" [nzDataSource]="notifications" [nzNoResult]="noNotifications" [nzRenderItem]="item" [nzItemLayout]="'horizontal'" [nzLoading]="loading">
      <ng-template #item let-item>
        <nz-list-item class="active {{ item?.opened || mark_all_clear ? '' : 'un-read' }}" [id]="'notification-item-'+item.id">
          <a href="javascript:" (click)="openNotification(item, $event)">
            <nz-list-item-meta
              [nzDescription]="nzDescription" class="nf-list">
              <ng-template #nzDescription>
<!--                <div class="noti-icon">-->
<!--                  <i nz-icon nzType="{{item.icon}}" nzTheme="outline"></i>-->
<!--                </div>-->
                <div class="noti-text">
                  {{ item.text }}<br />
                  <span>{{item.created_at | date: 'MMM dd hh:mma'}}</span>
                </div>
              </ng-template>
            </nz-list-item-meta>
          </a>
        </nz-list-item>
      </ng-template>
    </nz-list>
    <nz-list (click)="visible = false" *ngIf="notifications && notifications.length > 0">
      <a [routerLink]="['/notifications']" class="nf-view-all"  (click)="visible = false">
        View All
      </a>
    </nz-list>
  </div>
</nz-dropdown-menu>
<ng-template #noNotifications>
  <nz-empty nzNotFoundContent="You have no notifications yet" class="no-content" [style.margin-top]="'5px'"></nz-empty>
</ng-template>
