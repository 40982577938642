<div *ngIf="isHomePage">
  <nz-spin [nzSize]="'large'" *ngIf="loading"></nz-spin>
  <div nz-row nzJustify="start">
    <div *ngFor="let new of news | slice:0:4" class="tip-card" nz-col
         [ngClass]="{'home-page': isHomePage}">

      <div class="main-div">
        <img class="news-image" [src]="new.image_url" />
        <div class="inner-div">
          <div class="card-start">
            <div class="new-ttitle"> {{(new.title.length>50)? (new.title | slice:0:50)+'...':(new.title) }}</div>
            <p style="align-self: stretch; color: #576071; font-size: 15px; font-family: Lato; font-weight: 400; line-height: 22.50px; word-wrap: break-word" class="card-description line-clamp-3">{{(new.summary.length>80)? (new.summary | slice:0:80)+'...':(new.summary) }}</p>
          </div>
        </div>
      </div>
      <ng-template #titleTemplate>
        <div class="title-content" (click)="openUrlInNewTab(new.url)">{{new.title}} </div>
      </ng-template>
    </div>
    <button nz-button nzType="primary" nzBlock [routerLink]="['/news']" class="view-all-btn">
      View all articles
    </button>
  </div>
</div>
<div *ngIf="!isHomePage">
  <div>
    <div>
      <h2>
        Celeb & Luxury
      </h2>
      <p class="disclaimer">
        Automated posting of these articles to your social media is an additional feature.
        <a [href]="calendlyUrl" target="_blank">Click here </a> to talk to your account manager and set it up.
      </p>
    </div>
    <nz-spin [nzSize]="'large'" *ngIf="loading"></nz-spin>
    <div nz-row nzJustify="start">
      <div *ngFor="let feed of news" class="tip-card">
        <nz-card nzHoverable class="fixed-card-height">
          <nz-card-meta [nzTitle]="titleTemplate"></nz-card-meta>
          <img alt="example" [src]="feed.image_url" class="card-image" />
          <p class="card-description line-clamp-3">{{feed.summary}}</p>
          <button nz-button nzType="primary" class="card-button" (click)="createCustomContent(feed)">
            Post
          </button>
          <a class="article-link" [href]="feed.url" target="_blank">View Article</a>
        </nz-card>
        <ng-template #titleTemplate>
          <div class="title-content" (click)="openUrlInNewTab(feed.url)">{{feed.title}} </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
