import {
  Component, ElementRef, inject,
  OnInit, signal, ViewChild,
} from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Customer } from 'src/app/vos/customer/customer';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ProductsService } from '../../services/products/product-api.service';
import { AnnouncementService } from '../../services/announcement/announcement.service';
import { BreadCrumService } from '../../services/breadcrum/bread-crum.service';
import { GlobalsService } from '../../services/globals/globals.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import {BillingService} from '../../services/billing/billing.service';
import {
  StripeAddressComponent,
  StripeCardComponent,
  StripeElementsDirective,
  StripeFactoryService,
  StripePaymentElementComponent
} from 'ngx-stripe';
import {StripeAddressElementOptions, StripeCardElementOptions, StripeElementsOptions} from '@stripe/stripe-js';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {environment} from '../../../environments/environment';


declare  const Calendly: any;
@Component({
  selector: 'app-marketplace',
  templateUrl: './marketplace.component.html',
  styleUrls: ['./marketplace.component.scss']
})
export class MarketplaceComponent implements OnInit {
  isMobile = false;
  isTablet = false;
  isDesktop = false;
  productSwipe = this.globalsService.getSwiperOptions();
  public innerWidth: any;
  customer: Customer;
  cardType = 'dashboard-card';
  products;
  isVisible = false;
  status;
  showCheckout;
  isCheckoutVisible;
  buyNowProduct;
  currentStep = 0;
  stripe;
  error;
  loading;
  paying = signal(false);
  cardOptions: StripeCardElementOptions;
  elementsOptions: StripeElementsOptions;
  @ViewChild('container', { static: false }) container!: ElementRef;
  @ViewChild(StripePaymentElementComponent)
  paymentElement!: StripePaymentElementComponent;
  @ViewChild(StripeCardComponent) cardElement!: StripeCardComponent;
  @ViewChild('billingAddress') shippingAddress!: StripeAddressComponent;

  private readonly fb = inject(UntypedFormBuilder);

  paymentElementForm = this.fb.group({
    name: ['', [Validators.required]],
    address: ['', [Validators.required]],
    zipcode: ['', [Validators.required]],
    city: ['', [Validators.required]],
  });

  billingAddressOptions: StripeAddressElementOptions = {
    mode: 'billing'
  };



  constructor(
    private authService: AuthenticationService,
    private deviceService: DeviceDetectorService,
    private productsService: ProductsService,
    private breadcrumService: BreadCrumService,
    private globalsService: GlobalsService,
    private billingService: BillingService,
    private modalService: NzModalService,
    private announcementService: AnnouncementService,
    private factoryService: StripeFactoryService
  ) { }
  ngOnInit() {
    this.customer = this.authService.currentCustomerValue;
    this.loadProducts();
    this.breadcrumService.set_breadcrum();
    this.detectDevice();
    this.stripe = this.factoryService.create(environment.stripePublicKey);
  }

  loadProducts(){
    const params: Record<string, any> = {
      'q[abbreviation_in][]': [40, 28, 46, 49, 42, 2, 54, 13, 58],
    };
    this.productsService.productList(`customers/${this.customer?.id}/products`, params).subscribe(res => {
      this.products = res.data;
    });
  }

  detectDevice() {
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktop = this.deviceService.isDesktop();
  }
  showModal(): void {
    this.isVisible = true;

    // Ensure Calendly is initialized after view is available
    setTimeout(() => {
      Calendly.initInlineWidget({
        url: 'https://calendly.com/client-success-call/success-call',
        parentElement: this.container.nativeElement
      });
    }, 0);
  }

  handleOk(): void {
    this.isVisible = false;
    this.isCheckoutVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
    this.isCheckoutVisible = false;
  }
  productClicked(product){
    this.productsService.customerClickOnProduct({}, product.id).subscribe(() => {});
  }

  proceedPayment(){
    this.currentStep += 1;
    // this.collectPayment();
  }


  async collectPayment() {
    this.error = null;
    this.loading = true;
    if (this.paying()) {
      return;
    }
    this.paying.set(true);
    const {name, email, address, zipcode, city} = this.paymentElementForm.getRawValue();

    const result = await this.shippingAddress.getValue();
    const {complete, isNewAddress, value} = result;
    if (!complete){
      this.error = 'Please complete billing address!';
      this.loading = false;
      return;
    }

    this.stripe
      .confirmSetup({
        elements: this.paymentElement.elements,
        confirmParams: {
          payment_method_data: { billing_details: value }
        },
        redirect: 'if_required',
      }).subscribe({
        next: (result) => {
          this.paying.set(false);
          if (result.error) {
            this.loading = false;
            this.paying.set(false);
            // Handle error in card setup confirmation
            this.error = result.error.message;
          } else if (result.setupIntent && result.setupIntent.status === 'succeeded') {
            // Successfully confirmed the card setup, proceed with the subscription
            this.createSubscription(result.setupIntent.payment_method);
          }
        },
        error: (error) => {
          this.loading = false;
          this.paying.set(false);
          this.error = error.message;
        }
      });
  }

  setStripe(){
    this.billingService.checkBillingStatus().subscribe((response: any) => {
      this.status = response.status;
      this.elementsOptions = {
        locale: 'en',
        clientSecret: response.client_secret,
        appearance: {
          theme: 'stripe',
          labels: 'floating',
          variables: {
            colorPrimary: '#673ab7',
          },
        },
      };
    });
  }



  createSubscription(paymentMethod){
    this.billingService.create_customer_and_subscription(
      {product_id: this.buyNowProduct.id, payment_method_id: paymentMethod})
      .subscribe(res => {
        this.loading = false;
        this.currentStep += 1;
        this.loadProducts();
   });
  }

  buyNow(product){
    this.setStripe();
    this.buyNowProduct = product;
    this.isCheckoutVisible = true;
  }

}
