import { Component, OnInit, Input, AfterViewInit, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { Customer } from 'src/app/vos/customer/customer';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { InsightsService } from '../../services/insights/insights.service';
import { ActionCableService, Channel } from 'angular2-actioncable';
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs';
import { ContentsService } from '../../services/contents/contents.service';
import { Content } from 'src/app/vos/content/content';
import { map } from 'rxjs/operators';

import { CustomersService } from 'src/app/services/customers/customers.service';
import {UpgradeDialogComponent} from '../upgrade-dialog/upgrade-dialog.component';
import {NzModalService} from 'ng-zorro-antd/modal';
import moment from 'moment';
import { IModalData } from 'src/app/models/interfaces/modals/modal';
@Component({
  selector: 'app-insights-analytics',
  templateUrl: './insights-analytics.component.html',
  styleUrls: ['./insights-analytics.component.scss'],
})
export class InsightsAnalyticsComponent implements OnInit, AfterViewInit {
  @ViewChild('calendarContainer', { static: false }) calendarContainer: ElementRef;
  @ViewChild('notificationContainer', { static: false }) notificationContainer: ElementRef;
  @ViewChild('additional', { static: false }) additional: ElementRef;
  @ViewChild('tips', { static: false }) tips: ElementRef;

  sliderView = true;

  hideCols = ["created_at", "scheduled_at", "action", "post_status"];
  multi = [];
  calendarHeader = {
    right: 'dayGridMonth,timeGridWeek,timeGridDay',
    center: 'title',
    left: 'prev next today',
  };
  hideCreate = true;
  contents: Content[];
  previewWidth = 400;
  textLimit = 400;
  view: any[] = [800, 300];
  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = "Date";
  showYAxisLabel = true;
  maxTickYAxisTrim = true;
  maxTickYAxis = 3;
  loadingGraph = true;
  yAxisLabel = "Views";
  animations = true;
  facebookDisconnected = false;
  isVisibleInfo = false;
  isConfirmLoading = false;
  rawData: any;
  colorScheme = {
    domain: ["#1D68FB", "#4AFFFE", "#33C0FC"],
  };
  contentPagination = {
    limit: 10,
    total: 100,
    page: 1,
    totalPages: 10,
  };
  @Input()
  customer: Customer = new Customer();
  loading = true;
  selectedValue = "";
  insightsDates;
  subscription: Subscription;
  insights = [
    {
      display_name: "Time Saved",
      unit: "hours",
      key: "time_saved",
      description:
        "The Amount of time saved from all the content creation, ad creation, website creation, and posting content on your account.",
      value: "",
      change: "",
    },
    {
      display_name: "Money Saved",
      unit: "USD",
      key: "money_saved",
      description:
        "The Amount of money saved using Zentap instead of hiring someone at normal design and marketing rates.",
      value: "",
      change: "",
    },
    {
      display_name: "Posts Made",
      unit: "",
      key: "posts_count",
      description: "Here is the number of posts you made in the last duration",
      value: "",
      change: "",
    },
    {
      display_name: "Leads",
      unit: "",
      key: "leads_count",
      description:
        "Here are the number of leads you have generated in the last duration",
      value: "",
      change: "",
    },
  ];
  isVisible = false;
  isfbConnected = true;
  showButton = false;
  @ViewChild("myIdentifier")
  myIdentifier: ElementRef;
  constructor(
    private authService: AuthenticationService,
    private insightsService: InsightsService,
    private cableService: ActionCableService,
    private cdRef: ChangeDetectorRef,
    private customerService: CustomersService,
    private modalService: NzModalService,
    private contentService: ContentsService
  ) {}
  ngOnInit() {
    this.customer = this.authService.currentCustomerValue;
    this.fetchInsightsAnalyticsDates();
    this.fetchContent();
  }
  ngAfterViewInit() {
    if (this.myIdentifier?.nativeElement) {
      this.view = [this.myIdentifier?.nativeElement.offsetWidth, 300];
    }

    const calendarHeight = this.calendarContainer.nativeElement.offsetHeight;
    // const tips = this.tips.nativeElement.offsetHeight;
    this.notificationContainer.nativeElement.style.maxHeight = `${calendarHeight}px`;
    // this.additional.nativeElement.style.minHeight = `${tips}px`;
  }
  checkFacebookPageAccess() {
    this.customerService.checkFBAccess().subscribe(
      (c) => {
        this.isfbConnected = true;
      },
      (err) => {
        this.isfbConnected = false;
        this.showButton = true;
        this.cdRef.detectChanges();
      }
    );
  }
  // Update Current Analytics Date
  updateInsights(event) {
    this.loading = true;
    this.selectedValue = event;
    this.fetchAllInsights();
  }
  fetchInsightsAnalyticsDates() {
    this.insightsService.fetchInsightsDates().subscribe((d) => {
      if (d) {
        this.insightsDates = d;
        this.selectedValue = this.insightsDates.slice(-2)[0];
        this.fetchAllInsights();
      }
    });
  }
  fetchAllInsights() {
    this.loading = true;
    // this.fetchInsightsAnalytics();
    // this.fetchFacebookInsights();
  }

  fetchInsightsAnalytics() {
    this.loading = true;
    const customerId = this.customer?.id;
    const channel: Channel = this.cableService
      .cable(environment.wss_url)
      .channel("InsightsChannel", {
        uid: `${this.authService.currentCustomerValue?.id}_roi`,
      });
    // Subscribe to incoming insights
    channel.connected().subscribe((data) => {
      this.insightsService
        .fetchRoiInsights(this.selectedValue)
        .subscribe((ins) => {});
    });
    channel.received().subscribe((insights) => {
      if (insights && insights.data) {
        this.rawData = insights.data;
        this.loading = false;
        this.showButton = false;
        this.insights.forEach((i) => {
          i.value = insights.data[i.key];
          i.change = insights.data[`${i.key}_change`];
        });

        this.cdRef.detectChanges();
        this.destroySubscription(channel);
      }
    });
  }
  fetchFacebookInsights() {
    const customerId = this.customer?.id;
    const channel2: Channel = this.cableService
      .cable(environment.wss_url)
      .channel("InsightsChannel", {
        uid: this.authService.currentCustomerValue?.id,
      });
    // Subscribe to incoming insights
    channel2.connected().subscribe((data) => {
      this.insightsService.fetchFacebookInsights(this.selectedValue).subscribe(
        (ins) => {},
        (error) => {
          if (error["message"]) {
            this.facebookDisconnected = true;
            this.loadingGraph = false;
          }
        }
      );
    });
    channel2.received().subscribe((insights) => {
      if (insights && insights.data) {
        this.multi = [...[]];
        const pageSeriesArray =
          JSON.parse(
            insights.data.filter(
              (data) => data.display_name === "Page Views"
            )[0].value
          ) || [];
        const videoSeriesArray =
          JSON.parse(
            insights.data.filter(
              (data) => data.display_name === "Video Views"
            )[0].value
          ) || [];
        const reachSeriesArray =
          JSON.parse(
            insights.data.filter((data) => data.display_name === "Reach")[0]
              .value
          ) || [];
        pageSeriesArray?.map((elem) =>
          this.multi.push({
            name: moment(elem.end_time).format("MM/DD/YYYY"),
            series: [],
          })
        );

        this.multi.forEach((i) => {
          i.series.push({
            name: "Page Views",
            value: pageSeriesArray.filter(
              (data) => moment(data.end_time).format("MM/DD/YYYY") === i.name
            )[0].value,
          });
          i.series.push({
            name: "Video Views",
            value: videoSeriesArray.filter(
              (data) => moment(data.end_time).format("MM/DD/YYYY") === i.name
            )[0].value,
          });
          i.series.push({
            name: "Reach",
            value: reachSeriesArray.filter(
              (data) => moment(data.end_time).format("MM/DD/YYYY") === i.name
            )[0].value,
          });
        });
        this.loadingGraph = false;
        this.view = [this.myIdentifier.nativeElement.offsetWidth, 300];
        this.cdRef.detectChanges();
        this.destroySubscription(channel2);
      }
    });
  }

  didPage(pageIndex) {
    this.contentPagination.page = pageIndex;
    this.fetchContent();
  }

  fetchContent(searchQuery?) {
    // this.loading = true;
    let queryParams = `?page=${this.contentPagination.page}`;
    this.getContentData(queryParams);
  }

  getContentData(queryParams) {
    this.contentService
      .list(queryParams)
      .pipe(
        map((res) => {
          this.contentPagination.limit = res["per_page"];
          this.contentPagination.total = res["total_entries"];
          const limit = this.contentPagination.limit;
          const total_items = this.contentPagination.total;
          this.contentPagination.totalPages = Math.ceil(total_items / limit);
          return res.data;
        })
      )
      .subscribe((contents) => {
        if (this.contentPagination.page === 1) {
          this.contents = contents;
        } else {
          this.contents = this.contents.concat(contents);
        }
        this.loading = false;
      });
  }

  destroySubscription(channel) {
    channel.unsubscribe();
  }
  // connectToFacebook() {
  //   this.facebookService.selectPage();
  // }
  openInfo(): void {
    this.isVisible = true;
  }
  closeInfo() {
    this.isVisible = false;
  }
  facebookSupport() {
    const modal = this.modalService.create<UpgradeDialogComponent, IModalData>({
      nzContent: UpgradeDialogComponent,
      nzFooter: null,
      nzWidth: 600,
      nzData: {
        facebookSupport: true,
      },
    });
  }
  onSelect(data): void {
    // console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    // console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onScroll() {
    if (this.contentPagination.total > this.contents.length) {
      this.didPage(this.contentPagination.page + 1);
    }
  }

  onDeactivate(data): void {
    // console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }
  onResize(event) {
    this.view = [this.myIdentifier.nativeElement.offsetWidth, 300];
  }
  showModal(): void {
    this.isVisibleInfo = true;
  }
  handleOk(): void {
    this.isConfirmLoading = true;
    setTimeout(() => {
      this.isVisibleInfo = false;
      this.isConfirmLoading = false;
    }, 3000);
  }
  handleCancel(): void {
    this.isVisibleInfo = false;
  }
  getInsights(key) {
    return this.insights.filter((a) => a.key === key)[0].value;
  }
}
