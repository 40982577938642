<!-- <app-sidebar> -->
<!-- <div class="container"> -->
<ng-container>
  <h1 class="section-title title">
    <i nz-icon nzType="like"></i>
    <!-- Branded Infomercials -->
    Social Media Content
  </h1>

  <ng-container *ngTemplateOutlet="brandTabs"></ng-container>
  <ng-template #contentdata>
  <span>Categories: </span>
  <nz-tag *ngFor="let category of categories" nzMode="checkable" (nzCheckedChange)="handleChange($event, category.name)" [nzChecked]="selectedCategory === category.name">
    {{ category.name.titleize()}}
  </nz-tag>
    <br>
    <br>

    <form nz-form>
      <div nz-row>
        <!-- Posted Status Filter -->
        <div nz-col nzSpan="6">
          <label class="d-block">Posted Status:</label>
          <nz-select [(ngModel)]="filters.postedStatus"
                     name="postedStatus"
                     nzAllowClear
                     nzPlaceHolder="Select Status"
                     style="width: 80%;"
                     (ngModelChange)="applyFilters()">
            <nz-option nzValue="posted" nzLabel="Posted"></nz-option>
            <nz-option nzValue="not_posted" nzLabel="Not Posted"></nz-option>
            <nz-option nzValue="scheduled" nzLabel="Scheduled"></nz-option>
          </nz-select>
        </div>

        <!-- Posted After Filter -->
        <div nz-col nzSpan="6">
          <label class="d-block">Posted After:</label>
          <nz-date-picker [(ngModel)]="filters.postedAfter"
                          name="postedAfter"
                          style="width: 80%;"
                          (ngModelChange)="applyFilters()"></nz-date-picker>
        </div>

        <!-- Posted Before Filter -->
        <div nz-col nzSpan="6">
          <label class="d-block">Posted Before:</label>
          <nz-date-picker [(ngModel)]="filters.postedBefore"
                          name="postedBefore"
                          style="width: 80%;"
                          (ngModelChange)="applyFilters()"></nz-date-picker>
        </div>

        <!-- Sort by Posted Date Filter -->
        <div nz-col nzSpan="6">
          <label class="d-block">Sort by Posted Date:</label>
          <nz-select [(ngModel)]="filters.sortOrder"
                     name="sortOrder"
                     nzAllowClear
                     nzPlaceHolder="Select Order"
                     style="width: 80%;"
                     (ngModelChange)="applyFilters()">
            <nz-option nzValue="asc" nzLabel="Ascending"></nz-option>
            <nz-option nzValue="desc" nzLabel="Descending"></nz-option>
          </nz-select>
        </div>
      </div>
    </form>

    <div nz-col nzSpan="6" style="display: flex; align-items: center;" class="mt-20">
      <button nz-button nzType="primary" type="submit" (click)="clearFilters();">Clear Filters</button>
    </div>




    <nz-divider></nz-divider>
  <nz-spin [nzSize]="'large'" *ngIf="loading; else notloading">
    <app-select-content product="IF" (pagechange)="loadGallery($event)" [resource]="customer" [contents]="content " [pageInfo]="pageInfo"></app-select-content>
  </nz-spin>
  <ng-template #notloading>
    <app-select-content product="IF" (pagechange)="loadGallery($event)" [resource]="customer" [contents]="content " [pageInfo]="pageInfo"></app-select-content>
  </ng-template>
  </ng-template>
  <ng-template #brandTabs>
<!--    <nz-tabset [nzSelectedIndex]="currentTab" (nzSelectedIndexChange)="changeIndex($event);" nzType="card">-->
<!--      <nz-tab *ngFor="let tab of tabs; let i = index" id="{tab+i}" [nzTitle]="tab">-->
<!--        <ng-container *ngTemplateOutlet="contentdata"></ng-container>-->
<!--      </nz-tab>-->
<!--    </nz-tabset>-->
    <ng-container *ngTemplateOutlet="contentdata"></ng-container>
  </ng-template>
</ng-container>


<!-- </div> -->
<!-- </app-sidebar> -->
