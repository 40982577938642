import { Component, OnInit, Input, inject } from '@angular/core';
import * as moment from 'moment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { ContentPreviewDialogComponent } from '../../../../shared/content-preview-dialog/content-preview-dialog.component';
import { ContentsService } from '../../../../services/contents/contents.service';
import { GenericDialogComponent } from '../../../../shared/generic-dialog/generic-dialog.component';
import {PostsService} from '../../../../services/posts/posts.service';
import {Content} from '../../../../vos/content/content';
import { IModalData } from 'src/app/models/interfaces/modals/modal';
import { GenericDialogConfig } from 'src/app/models/interfaces/modals/generic-modal-config';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'content-event-preview-post',
  templateUrl: './event-preview-post.component.html',
  styleUrls: ['./event-preview-post.component.scss']
})
export class EventPreviewPostComponent implements OnInit {
  @Input()
  calendarData;
  @Input() content?: any;
  posts;

  showDelete = false;
  isNoSupportDeletePostOnSocial = ['instagram', 'tiktok'];
  sanitizedContent: SafeHtml;

  readonly #modal = inject(NzModalRef);
  readonly nzModalData: IModalData = inject(NZ_MODAL_DATA);

  cDate = new Date();
  constructor(
    private message: NzMessageService,
    private modalService: NzModalService,
    private contentsService: ContentsService,
    private postsService: PostsService,
    private nzMessageService: NzMessageService,
    private sanitizer: DomSanitizer,
  ) {

  }
  disabledDate = (current: Date): boolean => {
    return differenceInCalendarDays(current, new Date()) > 120;
  };

  handleDate(date) {
    if (differenceInCalendarDays(date, new Date()) > 120) {
      this.message.create('error', 'You cannot select date after 4 months!');
      this.cDate = new Date();
    }
  }
  ngOnInit() {
    this.content = this.nzModalData.content
    if (this.cDate.getUTCDate() > 25) {
      this.cDate = new Date(this.cDate.getFullYear(), this.cDate.getUTCMonth() + 1, 1);
    }
    this.getPosts();
    this.updateSanitizedContent();
  }
  get calnderDataKeys() {
    return Object.keys(this.calendarData || [])
  }


  getMonthData(date: Date): number | null {
    if (date.getMonth() === 8) {
      return 1394;
    }
    return null;
  }

  getFormatedDate(date) {
    return moment(date).format('YYYY-MM-DD');
  }

  showPreview(content, isEdit?) {
    const modal = this.modalService.create<ContentPreviewDialogComponent, IModalData>({
      nzTitle: content.display_name,
      nzContent: ContentPreviewDialogComponent,
      nzData: {
        config: {
          content,
          isEdit,
        },
        showActions: false
      },
      nzFooter: null,
      nzWidth: '50%',
      nzClassName: 'event-preview-modal'
      // nzBodyStyle: {
      //   'height': '65vh',
      //   'overflow': 'scroll'
      // }
    });
    modal.afterClose.subscribe(response => {
    });
  }

  postSocialDelete() {
    this.showDelete = true;
  }

  postDeleted() {
    const modal = this.modalService.create<GenericDialogComponent, GenericDialogConfig>({
      nzContent: GenericDialogComponent,
      nzData: {
        title: 'Confirm',
        message: `This action will delete the post from ${this.content.post.posted_on.toUpperCase()}. Are you sure you want to proceed?\n\nThis action cannot be undone.`,
        buttonLabel: 'Cancel',
        extraButtons: [
          {
            label: 'Yes',
            value: true,
            color: 'warn'
          }
        ]
      },
      nzFooter: null,
      nzWidth: '60%'
    });

    modal.afterClose.subscribe(result => {
      if (result) {
        const postIds = [{ id: this.content.post.id }];
        this.postsService.deletePostSocial(postIds).subscribe(
          (res) => {
            this.nzMessageService.success('Posts deleted successfully!');
            this.onModalClosed();
          },
          (error) => {
            console.error('Error deleting posts', error);
            this.nzMessageService.error('Failed to delete posts. Please try again.');
          }
        );
      }
    });
  }

  onModalClosed(): void {
    this.showDelete = false;
    this.close();
  }

  close(){
    this.modalService.closeAll();
  }

  getPosts(){
    const content = new Content();
    content.id = this.content.content_id;
    this.contentsService.posts(content).subscribe(resp => {
      this.posts = resp;
    });
  }

  checkSocial(social){
    if(this.content.post.posted_on === social){
      return true;
    }
    return false;
  }

  formatDate(dateString: string): string {
    if (!dateString || dateString === null) {
      return '---';
    }
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { weekday: 'short', month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }

  formatTime(timeString: string): string {
    if (!timeString || timeString === null) {
      return '---';
    }
    const date = new Date(timeString);
    const options: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit', hour12: false };
    return date.toLocaleTimeString('en-US', options);
  }

  updateSanitizedContent() {
    const caption =  this.content.content_attributes?.caption

    if (!caption) this.sanitizedContent = '-';
    else this.sanitizedContent = this.sanitizer.bypassSecurityTrustHtml(caption.replace(/\n/g, '<br/>'));
  }

}
