<!-- <div class="container"> -->
  <nz-card [style.width]="width" [nzTitle]="content.style" [style.marginBottom]="verticalMargin"
           [style.marginRight]="horizontalMargin" class="content-card" [nzExtra]="extraTemplate">
    <div class="media-container">
      <div *ngIf="content.status === 'rendering'" class="card-image">
        <img class="internal-content media-item" src="assets/styles/Rendering.gif">
      </div>

      <ng-container *ngIf="content.status !== 'rendering'">
        <ng-container [ngSwitch]="content.media_type">
          <div *ngSwitchCase="'image'" mat-card-image class="card-image" (click)="showPreview()">
            <img [src]="content.url" [alt]="content.caption" class="internal-content media-item" />
          </div>

          <ng-container *ngSwitchCase="'video'">
            <video *ngIf="isPlay && isVideo(content.url)" autoplay class="content-video" controls>
              <source [src]="content.url" />
            </video>

            <div *ngIf="isPlay && !isVideo(content.url)">
              <img [src]="content.url" alt="Image">
            </div>

            <ng-container *ngIf="!isPlay">
              <ng-container *ngIf="(isVideo(content.url) && content.url === content.thumbnail); else thumbnail">
                <video class="content-video" controls>
                  <source [src]="content.url" />
                </video>
              </ng-container>
              <ng-template #thumbnail>
                <div class="video">
                  <img class="content-video media-item" [src]="content.thumbnail" />
                  <a href="javascript: void(0);" (click)="playVideo()" *ngIf="isVideo(content.url)"></a>
                </div>
              </ng-template>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
<!--    <p *ngIf="!editing" class="caption caption-placeholder" (click)="editing = true">-->
<!--      {{ content.caption ? content.caption : caption_editable ? 'Click here to add a caption' : '' }}-->
<!--    </p>-->
<!--    <textarea  (blur)="editing = false" [hidden]="!editing" #captioninput nz-input [(ngModel)]="content.caption" placeholder="Add a caption" nzAutosize></textarea>-->
    <div nz-row nzJustify="space-between">
    <a nz-col type="primary" nz-button *ngIf="content.cta_link" [href]="content.cta_link">Learn More</a>
    <!-- <button nz-col nz-button nzType="default" *ngIf="(this.customer && this.customer.settings && !this.customer.settings.auto_posting && !isMarketingVideo)"
      (click)="postBrand();visible = false" class="post-button">Post</button> -->
    <!-- <button nz-button nzType="primary" *ngIf="!editing && caption_editable" (click)="setEditing(true);visible = false" class="edit-caption-button">Edit Caption</button> -->
    <!-- <button nz-button nzType="primary" *ngIf="editing && caption_editable" (click)="setEditing(false);visible = false" class="save-caption-button">Save Changes</button> -->
    <button nz-col nz-button nzType="primary" *ngIf="isEmailSign || isMarketingFlyer || isMarketingVideo || (isSocialMediaContent && content.media_type == 'video')" (click)="createContent();visible = false" class="create-button">Create</button>
    <button nz-col nz-button nzType="primary" *ngIf="isSocialMediaContent && content.media_type == 'image'" (click)="postInforgraphic();visible = false" class="create-button">Post</button>
      <span style="margin-top: 20px" nz-icon nzType="fire" nzTheme="twotone" *ngIf="isSocialMediaContent && content.media_type == 'video'"></span>
    <nz-spin [nzSize]="'large'" *ngIf="loading"></nz-spin>
    </div>
  </nz-card>
  <ng-template #extraTemplate>
    <i nz-icon nzType="menu" *ngIf="showTopMenu" class="action-menu" nz-popover nzPopoverPlacement="bottomRight" nzPopoverTrigger="click" [(nzPopoverVisible)]="visible"
      [nzPopoverContent]="contentTemplate" nzTheme="outline"></i>
  </ng-template>
  <ng-template #contentTemplate>
    <p class="content-menu-item" (click)="download();visible = false" *ngIf="content.status !== 'rendering' && !content.is_template">Download</p>
    <ng-container *ngIf="!loading && canPost && !caption_editable" [ngSwitch]="content.status">
      <p class="content-menu-item" *ngSwitchCase="'posting'" disabled>Posting...</p>
      <p class="content-menu-item" *ngSwitchCase="'posted'" disabled>Posted</p>
    </ng-container>
    <ng-container *ngIf="!loading && caption_editable">
      <p class="content-menu-item" *ngIf="!editing" (click)="setEditing(true);visible = false">Edit Caption</p>
      <p class="content-menu-item" *ngIf="editing" (click)="setEditing(false);visible = false">Done</p>
      <p class="content-menu-item" *ngIf="!editing && content.is_template" (click)="create();visible = false;">Create</p>
    </ng-container>
    <p class="content-menu-item" (click)="delete();visible = false" *ngIf="showMoreMenu">Delete</p>
  </ng-template>
<!-- </div> -->
