import { Component, OnInit, Input, ViewChild, ElementRef, SimpleChanges } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { UpgradeDialogComponent } from '../upgrade-dialog/upgrade-dialog.component';

import { ConnectLinkedinService } from '../../services/connect-linkedin/connect-linkedin.service';
import { CustomersService } from '../../services/customers/customers.service';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { DownloaderService } from 'src/app/services/downloader.service';
import { ContentsService } from '../../services/contents/contents.service';
import { GenericDialogComponent } from '../generic-dialog/generic-dialog.component';
import { Customer } from '../../vos/customer/customer';
import { ConnectInstagramService } from '../../services/connect-instagram/connect-instagram.service';
import { CustomersFacebookService } from '../../services/customers-facebook/customers-facebook.service';
import { FacebookOnboardingDialogComponent } from '../../shared/facebook-onboarding-dialog/facebook-onboarding-dialog.component';
import {TiktokOnboardingDialogComponent} from '../../shared/tiktok-onboarding-dialog/tiktok-onboarding-dialog.component';
import { CustomersGoogleService } from '../../services/customers-google/customers-google.service';
import { CustomersYoutubeService } from '../../services/customers-youtube/customers-youtube.service';
import { GoogleOnboardingDialogComponent } from '../../shared/google-onboarding-dialog/google-onboarding-dialog.component';
import { GlobalsService } from '../../services/globals/globals.service';
import { ProductsService } from '../../services/products/product-api.service';

import moment from 'moment-timezone';
import {YoutubeOnboardingDialogComponent} from '../youtube-onboarding-dialog/youtube-onboarding-dialog.component';
import {CustomersTiktokService} from '../../services/customers-tiktok/customers-tiktok.service';
import { IModalData } from 'src/app/models/interfaces/modals/modal';
import { GenericDialogConfig } from 'src/app/models/interfaces/modals/generic-modal-config';
@Component({
  selector: 'app-select-social-platform',
  templateUrl: './select-social-platform.component.html',
  styleUrls: ['./select-social-platform.component.scss']
})

export class SelectSocialPlatformComponent implements OnInit {
  @Input() isRssFeed: boolean;
  @Input() coverPhotoCategory: any;
  @Input()
  config: any;
  @Input()
  submitted = false;
  content;
  customer: Customer;
  all = false;
  facebook = false;
  linkedin = false;
  google = false;
  linkedinAutopost = false;

  isFacebookConnected = false;
  isGoogleConnected = false;
  integration;
  linkedinStatus = false;
  linkedinConnected = false;
  manualFacebookIntegrationStatus = false;
  manualFacebookIntegration;
  manualFacebookAutopost = false;
  loading = false;
  status = false;
  PaidTier = false;
  hideFacebook = false;
  hideYoutube = false;
  hidePosting = false;
  isScheduledPost = false;
  isDateLoading = false;
  googleConnectedMydBusinessStatus = false;
  googlePageIntegration;
  instagramIntegration;
  instagramStatus = false;
  instagramAutopost = false;
  instagramConnected = false;
  instagram = false;
  youtubeStatus = false;
  youtubeIntegration;
  youtubeConnected = false;
  youtubeAutopost = false;
  // tiktok
  tiktokAllowed = false;
  tiktokConnected = false;
  tiktokIntegration;
  tiktokSelected = false;
  tiktokAutopost = false;
  tiktokStatus = false;
  socialFeed = false;

  constructor(
    private contentsService: ContentsService,
    private downloader: DownloaderService,
    private modalService: NzModalService,
    private router: Router,
    private message: NzMessageService,
    private authService: AuthenticationService,
    private linkedInService: ConnectLinkedinService,
    private customersService: CustomersService,
    private customersFacebookService: CustomersFacebookService,
    private customersGoogleService: CustomersGoogleService,
    private customersYoutubeService: CustomersYoutubeService,
    private globalsService: GlobalsService,
    private productsService: ProductsService,
    private customersTiktokService: CustomersTiktokService,
    private instagramService: ConnectInstagramService,
    private notification: NzNotificationService,
  ) { }
  ngOnInit() {
    this.checkCustomerConectedFacebookPage();
    this.checkCustomerConectedGoogleBusiness();
    if (this.config.content.last_post && this.config.content.last_post.id &&
      this.config.content.last_post.posted_on) {
      this.config.facebook = this.config.content.last_post.posted_on.indexOf('facebook') > -1;
      this.config.linkedin = this.config.content.last_post.posted_on.indexOf('linkedin') > -1;
      this.config.instagram = this.config.content.last_post.posted_on.indexOf('instagram') > -1;
      this.config.google = this.config.content.last_post.posted_on.indexOf('google_business') > -1;
      this.config.youtube = this.config.content.last_post.posted_on.indexOf('youtube') > -1;
      this.config.tiktok = this.config.content.last_post.posted_on.indexOf('tiktok') > -1;
    }
    this.authService.currentCustomer.subscribe((c) => {
      if (c) {
        this.customer = c;
        this.PaidTier = c?.tier === 'ultra';
        this.config.linkedin = this.PaidTier && this.config.linkedin;
        this.config.instagram = this.PaidTier && this.config.instagram;
        this.all = this.config.facebook && this.linkedin && this.config.instagram && this.config.google
          && this.config.youtube && this.config.tiktok;
      }
    });
    this.checkLinkedin();
    this.socialFeed = this.config.socialFeed;
    this.content = { ...this.config.content };
    if (this.isCarousel) {
      this.config.linkedin = false;
      this.config.google = false;
      // this.config.instagram = false;
    }
    this.fetchCustomerProducts();
    this.fetch_manual_fb_autopost_settings();
    this.fetch_linkedin_autopost_settings();
    this.fetch_instagram_autopost_settings();
    this.fetch_youtube_autopost_settings();
    this.fetch_tiktok_autopost_settings();
    this.updateConfig();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.coverPhotoCategory) {
      this.updateConfig();
    }
  }

  fetchCustomerProducts() {
    this.loading = true;
    const params: Record<string, any> = {
      'per_page': 40,
    };

    this.productsService.productList(
      `customers/${this.customer?.id}/products`, params
    ).subscribe(res => {
      if (res) {
        const data = res.data;
        const googleProduct = data ? data?.find(p => p.abbreviation === 'GMB' && p.locked === false) : null;
        const linkedinProduct = data ? data?.find(p => p.abbreviation === 'LI' && p.locked === false) : null;
        const instagramProduct = data ? data?.find(p => p.abbreviation === 'IG' && p.locked === false) : null;
        const youtubeProduct = data ? data?.find(p => p.abbreviation === 'YT' && p.locked === false) : null;
        const tiktokProduct = data ? data?.find(p => p.abbreviation === 'TT' && p.locked === false) : null;

        if (googleProduct && googleProduct != null) {
          this.googleConnectedMydBusinessStatus = true;
          this.globalsService.setIsGMBAllowed(true);
        }
        if (linkedinProduct && linkedinProduct != null) {
          this.linkedinStatus = true;
        }
        if (instagramProduct && instagramProduct != null) {
          this.instagramStatus = true;
        }
        if (youtubeProduct && youtubeProduct != null) {
          this.youtubeStatus = true;
        }
        if (tiktokProduct && tiktokProduct != null) {
          this.tiktokStatus = true;

        }
        this.loading = false;
        this.refreshAutoSelectPlatforms();
      }
    }, err => {
      this.loading = false;
    })

  }

  refreshAutoSelectPlatforms() {
    this.config.facebook = this.isFacebookConnected;
    this.config.linkedin = this.linkedinStatus && this.integration && (this.linkedinConnected || (this.getLinkedInCode && this.getLinkedInCode.status === 'success'));
    this.config.instagram = this.instagramStatus && this.instagramIntegration && this.instagramConnected;
    this.config.google = this.isGoogleConnected && this.googlePageIntegration;
    this.config.youtube = this.youtubeStatus && this.youtubeIntegration && this.youtubeConnected;
    this.config.tiktok = this.tiktokStatus && this.tiktokIntegration && this.tiktokConnected;
    if (this.config.hideGoogle){
      this.config.google = false;
    }
    // remove youtube for IF content
    if (this.config.content.category === 'IF'){
      this.config.youtube = false;
    }
    this.all = this.config.facebook  && this.config.linkedin && this.config.instagram &&
      this.config.google;
    this.loading = false;
  }

  showFacebook() {
    const modal = this.modalService.create<FacebookOnboardingDialogComponent, IModalData>({
      nzContent: FacebookOnboardingDialogComponent,
      nzData: {
        config: {
          autoConnect: true
        }
      },
      nzFooter: null,
      nzWidth: '50%',
    });
    modal.afterClose.subscribe(response => {
    });
  }

  showGoogle() {
    const modal = this.modalService.create<GoogleOnboardingDialogComponent, IModalData>({
      nzContent: GoogleOnboardingDialogComponent,
      nzData: {
        config: {
          autoConnect: true
        }
      },
      nzFooter: null,
      nzWidth: '50%',
    });
    modal.afterClose.subscribe(response => {
      this.checkCustomerConectedGoogleBusiness();
      // this.fetch_instagram_autopost_settings();
    });
  }

  showYoutube() {
    const modal = this.modalService.create<YoutubeOnboardingDialogComponent, IModalData>({
      nzContent: YoutubeOnboardingDialogComponent,
      nzData: {
        config: {
          autoConnect: true
        }
      },
      nzFooter: null,
      nzWidth: '50%',
    });
    modal.afterClose.subscribe(response => {
      this.fetch_youtube_autopost_settings();
      // this.fetch_instagram_autopost_settings();
    });
  }

  connectInstagram() {
    if (this.isFacebookConnected) {
      this.instagramService.create({ provider: 'instagram' }).subscribe((c) => {
        this.notification.create(
          'success',
          'Connected',
          'Instagram successfully connected!'
        );
        this.fetch_instagram_autopost_settings();
      }, err => {
        // console.log(err)
        // this.message.create('error', 'Error', `Error creating Instagram connection. ${err.error}`);
      });
    } else {
      this.showFacebook();
    }
  }

  connectLinkedIn() {
    this.linkedInService.authorizeLinkedIn();
  }

  connectTiktok() {
    const modal = this.modalService.create<TiktokOnboardingDialogComponent, IModalData>({
      nzContent: TiktokOnboardingDialogComponent,
      nzData: {
        config: {
          autoConnect: true,
          isReAuth: this.tiktokConnected,
        }
      },
      nzFooter: null,
      nzWidth: '50%',
    });
    modal.afterClose.subscribe(response => {
      this.fetch_tiktok_autopost_settings();
    });
  }

  checkLinkedin() {
    localStorage.removeItem('linkedInCode');
    this.customersService.getLinkedin().subscribe((c) => {
      this.linkedinConnected = (c.length > 0);
    });
  }

  checkCustomerConectedFacebookPage() {
    this.customersFacebookService.getCustomerConectedFacebookPage()
      .subscribe(res => {
        if (res) {
          const { user_id_on_provider = null } = res?.body?.data?.length > 0 ? res.body.data[0] : {};
          if (!user_id_on_provider && !this.customer.fb_page_id) {
            this.isFacebookConnected = false;
          } else {
            this.isFacebookConnected = true;
          }
        }
        this.refreshAutoSelectPlatforms();
      });
  }

  checkCustomerConectedGoogleBusiness() {
    this.customersGoogleService.getGoogleUserConnection()
      .subscribe(res => {
        if (res && res?.body?.data?.length > 0) {
          this.googlePageIntegration = res.body.data[0];
        }

        if (res) {
          const { user_id_on_provider = null, meta_data = null } = res?.body?.data?.length > 0 ? res.body.data[0] : {};
          if (!user_id_on_provider && !meta_data?.location_id_on_provider) {
            this.isGoogleConnected = false;
          } else {
            this.isGoogleConnected = true;
          }
        }
        this.refreshAutoSelectPlatforms();
      });
  }

  fetch_manual_fb_autopost_settings() {
    this.customersService.getManualFacebook().subscribe((c) => {
      if (c.length > 0) {
        this.manualFacebookIntegrationStatus = true;
        this.manualFacebookIntegration = c[0];
        this.manualFacebookAutopost = this.manualFacebookIntegration.auto_posting;
        this.refreshAutoSelectPlatforms();
      }
    });
  }

  fetch_instagram_autopost_settings() {
    this.customersService.getInstagram().subscribe((c) => {
      if (c.length > 0) {
        // this.instagramStatus = true;
        this.instagramIntegration = c[0];
        this.instagramConnected = true;
        this.instagramAutopost = this.instagramIntegration.auto_posting;
        this.config.instagram = this.instagramAutopost;
        this.refreshAutoSelectPlatforms();
      }
    });
  }

  fetch_youtube_autopost_settings() {
    this.customersYoutubeService.getYoutubeUserConnection().subscribe((c) => {
      if (c.length > 0) {
        this.youtubeIntegration = c[0];
        this.youtubeConnected = true;
        this.youtubeAutopost = this.youtubeIntegration.auto_posting;
        this.config.youtube = this.youtubeAutopost;
        this.refreshAutoSelectPlatforms();
      }
    });
  }

  fetch_linkedin_autopost_settings() {
    this.customersService.getLinkedin().subscribe((c) => {
      if (c && c.length > 0 && c[0] && c[0].refresh_token_expires_at) {
        const now = new Date(); /* midnight in China on April 13th */
        now.toLocaleString('en-US', { timeZone: 'America/New_York' });
        const refreshExpireAt = new Date(c[0].refresh_token_expires_at);
        refreshExpireAt.toLocaleString('en-US', { timeZone: 'America/New_York' });

        if (now < refreshExpireAt) {
          // this.linkedinStatus = true;
        }
        this.linkedInService.getAutopost().subscribe(int => {
          this.integration = int && int?.data && int?.data.length ? int?.data[0] : null;
          this.linkedinAutopost = this.integration.auto_posting;
          this.refreshAutoSelectPlatforms();
        });
      }
    });
  }

  fetch_tiktok_autopost_settings() {
    this.loading = true;
    this.customersTiktokService.getTiktokUserConnection()
      .subscribe(res => {
        if (res && res?.length > 0) {
          this.tiktokIntegration = res[0];
          this.tiktokConnected = true;
          this.tiktokAutopost = this.tiktokIntegration.auto_posting;
        }
        this.refreshAutoSelectPlatforms();
      });
  }

  changeAll() {
    this.config.facebook = this.all;
    this.config.instagram = this.all;
    this.config.linkedin = this.all;
    this.config.google = this.all;
    this.config.youtube = this.all;
    this.config.tiktok = this.all;
    if (this.isCarousel) {
      this.config.linkedin = false;
      this.config.google = false;
    }
    if (this.config.hideGoogle){
      this.config.google = false;
    }
  }

  // post() {
  //   if (this.facebook && this.config.content.status === 'ready') {
  //     this.postFB();
  //   }
  //   if (this.linkedin && this.config.content.status === 'ready') {
  //     this.postLN();
  //   }
  // }

  postLN() {
    const uid = `${this.content.id}`;
    const request = this.contentsService.createPost({
      'uid': uid,
      'post': {
        'content_id': this.content.id,
        'posted_on': 'linkedin',
        scheduled_for: this.content.scheduled_for
      }
    }, 'posts');

    request.subscribe(c => {
      const caption = this.content.caption;
      this.content = c['content_attributes'];
      this.content.caption = caption;
      this.resetContent(this.config.content.id);
      this.message?.create('success', 'Your linkedin post has been posted Sucessfully');
      this.status = true;
      this.content.status = 'posted';
      this.isScheduledPost = false
    }, (error) => {
      this.message?.remove();
      // this.message.create('error', 'Your linkedin post has been failed');
      this.status = false;
    });
  }

  postFB() {
    const uid = `${this.content.id}`;
    const request = this.contentsService.createPost({
      'uid': uid,
      'post': {
        'content_id': this.content.id,
        'caption': this.content.caption,
        'posted_on': 'facebook',
        scheduled_for: this.content.scheduled_for
      }
    }, 'posts');
    request.subscribe(c => {
      const caption = this.content.caption;
      this.loading = false;
      this.content = c['content_attributes'];
      this.content.caption = caption;

      this.message.create('success', 'Your facebook post has posted Sucessfully');
      this.resetContent(this.config.content.id);
      this.status = true;
      this.isScheduledPost = false
      // }
      this.content.status = 'posted';
      // });
      // this.message.create('success', 'successfully requested your posting, we will notify when its posted!');
      this.status = true;
      // this.posted.emit(c);
    }, (error) => {
      this.message?.remove();
      // this.message.create('error', 'Your facebook post has failed');
      this.status = false;
    });
  }

  upgradePlan() {
    const modal = this.modalService.create({
      nzContent: UpgradeDialogComponent,
      nzFooter: null
    });
  }

  facebookSupport() {
    const modal = this.modalService.create<UpgradeDialogComponent, IModalData>({
      nzContent: UpgradeDialogComponent,
      nzFooter: null,
      nzWidth: 600,
      nzData: {
        facebookSupport: true
      },
    });
  }

  cancelSchedule() {
    const modal = this.modalService.create<GenericDialogComponent, GenericDialogConfig>({
      nzContent: GenericDialogComponent,
      nzData: {
        title: 'Confirm',
        message: `Are you sure you want to cancel your scheduled post?`,
        buttonLabel: 'Cancel',
        extraButtons: [
          {
            label: 'Confirm',
            value: true,
            color: 'warn'
          }
        ]
      },
      nzFooter: null,
      nzWidth: '60%'
    });
    modal.afterClose.subscribe(response => {
      if (response === true) {
        this.loading = true;
        this.contentsService.unschedulPost(this.config.content)
          .subscribe(res => {
            this.contentsService.show(this.config.content.id)
              .subscribe(res => {
                if (res) {
                  this.config.content = res.data;
                  this.loading = false;
                  this.message?.create('success', `Your scheduled post is cancelled`);
                }
              })
          }, e => {
            this.loading = false;
            this.message?.remove();
            this.message?.create('error', 'Error cancelling the scheduled post. Please try again');
          });
      }
    });
  }

  resetContent(id) {
    this.contentsService.show(id)
      .subscribe(res => {
        this.config.content = res?.data;
        this.loading = false;
      })
  }

  download() {
    const content = this.config.content;
    let url = content.url;
    if (url && !url.includes('https')) {
      url = url?.replace('http', 'https');
    }
    this.downloader.save(url, content.filename);
  }

  gotToEdit() {
    this.router.navigate([`listings/${this.config.content.contentable_id}/edit`]);
  }

  isPosted(content) {
    // console.log(content.last_post.scheduled_for)
  }

  updateConfig() {
    if (this.isCoverPhoto) {
      this.hideFacebook = this.coverPhotoCategory !== 'facebook';
      this.hideYoutube = this.coverPhotoCategory !== 'youtube';
    }
  }

  get isCoverPhoto() {
    return this.content?.category === 'SB';
  }

  get getLinkedInCode() {
    const payload = this.linkedInService.getAuthorizationCode();
    if (payload) {
      this.linkedinConnected = (payload && payload.status === 'success');
      // this.cdr.detectChanges();
    }
    return payload;
  }
  get isCarousel(): boolean {
    return this.content?.category === 'DVC';
  }

  get showGMB() {
    return this.globalsService.getIsGMBAllowed();
  }

  get isAdmin() {
    return !!this.authService.decodedUser?.aid;
  }
}
