<div class="title-header">
  <!-- Render the title and button -->
  <ng-container *ngTemplateOutlet="brandTitle"></ng-container>
</div>
<br *ngIf="!hideCreate">

<!-- Add a div to separate the title and the calendar -->
<nz-divider nzType="horizontal" *ngIf="hideCreate"></nz-divider>

<!-- Calendar Content Section -->
<div nz-row>
  <div nz-col nzSpan="24">
    <div class="nz-mb-0 calender-table-sa-ui">

      <!-- Conditional rendering of nz-empty when no content is available -->
      <nz-empty nzNotFoundContent="You haven’t created any content yet" *ngIf="contents && contents.length === 0"
                class="no-content"></nz-empty>

      <!-- Pagination controls -->
      <div nz-row nzType="flex" nzJustify="end" *ngIf="contents && contents.length && currentTab < 3"
           class="pagination-pad">
        <nz-pagination nzShowSizeChanger [nzPageSizeOptions]="pageSizeOptions" [nzPageSize]="contentPagination.limit"
                       [nzPageIndex]="contentPagination.page" [nzTotal]="contentPagination.total"
                       (nzPageIndexChange)="didPage($event)">
        </nz-pagination>
      </div>

      <!-- Loading spinner and calendar -->
      <div *ngIf="loading" class="loading-container">
        <nz-spin [nzSize]="'large'"></nz-spin>
      </div>

      <!-- Calendar component -->
      <full-calendar *ngIf="calendarOptions" [options]="calendarOptions"></full-calendar>
    </div>
  </div>
</div>

<!-- Badges section at the bottom -->
<!--<div nz-row class="top-spacing">-->
<!--  <p>-->
<!--    <strong class="red-bullets">*</strong>-->
<!--    Content posted to the calendar is subject to change without prior notice.-->
<!--  </p>-->
<!--  <p class="left-space">-->
<!--    <nz-badge nzColor="green" nzText="Post created by us."></nz-badge>-->
<!--  </p>-->
<!--  <p class="left-space">-->
<!--    <nz-badge nzColor="pink" nzText="Post created by you."></nz-badge>-->
<!--  </p>-->
<!--</div>-->

<!-- Brand Title Template -->
<ng-template #brandTitle>
  <h1 class="section-title title">
    <img class="ellipsis-vertical" src="../../../assets/icons/calenar.svg">
    Content Calendar
  </h1>
  <button nz-button nzType="primary" *ngIf="!hideCreate" class="create-content-btn" (click)="createCustomContent()">
    <i nz-icon nzType="appstore-add" nzTheme="outline"></i>
    Create a Post
  </button>
</ng-template>
