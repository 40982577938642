import { Component, Input, Output, OnInit, AfterViewChecked, ElementRef, HostListener } from '@angular/core';
import { ViewChild, EventEmitter } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MarketReport } from '../../vos/market-report/market-report';
import { NgForm, ValidationErrors } from '@angular/forms';
import { AbstractControl, FormControl } from '@angular/forms';
import { FormGroup, FormArray } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { MarketReportsService } from '../../services/market-reports/market-reports.service';
import { CustomersService } from '../../services/customers/customers.service';

import { NzInputDirective } from 'ng-zorro-antd/input';
import numeral from 'numeral';

@Component({
  selector: 'app-market-report-data-selector',
  templateUrl: './market-report-data-selector.component.html',
  styleUrls: ['./market-report-data-selector.component.scss']
})

export class MarketReportDataSelectorComponent implements OnInit {
  @Output() selectedDataChange = new EventEmitter<any>();
  @Output() zipDataChange = new EventEmitter<any>();
  @Output() zeroValuesChange = new EventEmitter<any>();

  @Output() propertyTypeChange = new EventEmitter<any>();
  @Input() model = new MarketReport();
  selectedType;
  selectedDataPoint: any;
  dataSnapPoints = [];
  zipData: any;
  zeroValueCounts = 0;

  // selectedData: any;
  error: any;
  editId: string | null;
  dataPoints = [];
  marketDataPoints = [];
  showIcon = false;
  selectedDataMultiplePoints = [];
  nonSelectedDataMultiplePoints = [];

  propertyTypes = [{ key: 'residential', name: 'Residential' },
  { key: 'NonResidential', name: 'Non Residential' },
  { key: 'singleFamily', name: 'Single Family' },
  { key: 'condominium', name: 'Condominium' },
  { key: 'townHouse', name: 'Town House' },
  { key: 'multifamily', name: 'Multifamily' },
  { key: 'apartment', name: 'Apartment' },
  { key: 'all', name: 'All' }];
  loading = true;
  propertyType: any;
  @ViewChild(NzInputDirective, { read: ElementRef }) inputElement: ElementRef;
  @HostListener('window:click', ['$event'])
  handleClick(e: MouseEvent): void {
    if (this.editId && this.inputElement && this.inputElement.nativeElement !== e.target) {
      this.editId = null;
    }
  }
  constructor(
    private marketReportService: MarketReportsService,
    private customerService: CustomersService,
  ) {
    for (let i = 1; i <= 25; i++) {
      this.dataPoints.push({ key: i, value: false });
    }

  }
  ngOnInit() {
    this.propertyType = this.propertyTypes[2].key;
    this.fetchDataPoints();
    this.marketReportService.data(
      this.model.id, 'singleFamily').subscribe(response => {
        this.zipData = response;
         this.zeroValueCounts = this.getZeroValueCount(this.zipData);
        this.zeroValuesChange.emit(this.zeroValueCounts);
        this.fetchMarketDataPoints();
        // this.selectedValue();
      });
  }


  fetchMarketDataPoints() {
    this.customerService.dataPoints()
      .subscribe(res => {
        if (res && res.defaults) {
          this.marketDataPoints = res.defaults;
        }
        this.selectedValue();
      })
  }


  fetchDataPoints() {
    this.marketReportService.dataSnapPoints().subscribe(res => {
      this.dataSnapPoints = res;
    });
  }
  add(event): void {
    // this.change.emit(event);
  }
  formatNumber(unit, value, key, type) {
    let displayValue;

    switch (unit) {
      case '#':
        displayValue = numeral(value).format('0,0');
        break;
      case '$':
        displayValue = numeral(value).format('$0,0');
        break;
      case '%':
        displayValue = `${numeral(value).format('0,0')}%`;
        break;
      case 'sqft':
        displayValue = `${numeral(value).format('0,0')} Sq. Ft.`;
        break;
      case '$/sqft':
        displayValue = `${numeral(value).format('0,0')} Per Sq. Ft.`;
        break;
      case 'days':
        displayValue = `${numeral(value).format('0,0')} Days`;
        break;
      case 'months':
        displayValue = `${numeral(value).format('0,0')} Months`;
        break;
      case 'years':
        displayValue = `${numeral(value).format('0,0')} Years`;
        break;
      default:
        break;
    }
    this.zipData.analysis[key][type] = displayValue;
  }

  startEdit(id: string, event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.editId = id;
  }
  selectValue(data) {
    const index = this.selectedDataMultiplePoints.indexOf(data);
    if (index === -1) {
      this.selectedDataMultiplePoints.push(data);
    } else {
      this.selectedDataMultiplePoints.splice(index, 1);
    }
    // this.selectedDataChange.emit(this.selectedDataMultiplePoints);
    // this.zipDataChange.emit(this.zipData);
    // this.propertyTypeChange.emit(this.propertyType);
    if (this.selectedDataMultiplePoints.length === this.allowedValues()) {
      this.selectedDataChange.emit(this.selectedDataMultiplePoints);
      this.zipDataChange.emit(this.zipData);
      this.propertyTypeChange.emit(this.propertyType);
    } else {
      this.selectedDataChange.emit([]);
    }
  }
  refreshTable(event) {
    this.loading = true;
    this.marketReportService.data(
      this.model.id, event).subscribe(response => {
        this.zipData = response;
        this.loading = false;
        this.selectedDataMultiplePoints = [];
        this.selectedValue();
        this.zipDataChange.emit(this.zipData);
        this.propertyTypeChange.emit(this.propertyType);
      });
  }

  disableValues() {
    if ((this.model && this.model.products && this.model.products.length > 0 && this.model.products[0].abbreviation === 'DVY' ||
      this.model && this.model.products && this.model.products[0].abbreviation === 'DVSY') &&
      this.selectedDataMultiplePoints.length === this.allowedValues()) {
      return true;
    } else if (this.model && this.model.products && this.model.products.length > 0 && (this.model.products[0].abbreviation === 'DV' || this.model.products[0].abbreviation === 'DVC') &&
      this.selectedDataMultiplePoints.length === this.allowedValues()) {
      return true;
    }
  }
  allowedValues() {
    if (this.model && this.model.products && this.model.products.length > 0 && this.model.products[0].abbreviation === 'DVY' ||
      this.model && this.model.products && this.model.products.length > 0 && this.model.products[0].abbreviation === 'DVSY') {
      return 6;
    } else if (this.model && this.model.products && this.model.products.length > 0 && (this.model.products[0].abbreviation === 'DV' || this.model.products[0].abbreviation === 'DVC')) {
      return 4;
    }
  }
  selectedValue() {
    let selectedValues = [];
    let unSelectedValues = [];


    let dataPointKeys = Object.keys(this.marketDataPoints);
    const allowedCount = this.allowedValues();
    // selectedValues = dataPointKeys.filter(key => this.marketDataPoints[key]);
    dataPointKeys.forEach(key => {
      if (this.marketDataPoints[key].selected && selectedValues.length < allowedCount) {
        selectedValues.push(key);
      } else {
        unSelectedValues.push(key);
      }
    });

    this.nonSelectedDataMultiplePoints = unSelectedValues;
    selectedValues.forEach(v => {
      if (this.zipData.analysis[v].previous_raw && this.zipData.analysis[v].current_raw) {
        this.zipData.analysis[v].selected = true;
        this.selectValue(v);
      }
    });


    this.loading = false;
  }


  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }

    if (this.selectedDataMultiplePoints.length === this.allowedValues()) {
      this.selectedDataMultiplePoints.forEach(v => {
        if (this.zipData.analysis[v].previous_raw && this.zipData.analysis[v].current_raw) {
          this.zipData.analysis[v].selected = true;
        } else {
          this.zipData.analysis[v].selected = false;
        }
      });
      this.selectedDataChange.emit(this.selectedDataMultiplePoints);
      this.zipDataChange.emit(this.zipData);
      this.propertyTypeChange.emit(this.propertyType);
    } else {
      this.selectedDataChange.emit([]);
    }
  }
  get limitMessage() {
    return `You already have selected ${this.allowedValues()} data points.`;
  }

  getZeroValueCount(zipData) {
    let count = 0;
    Object.keys(zipData?.analysis).forEach(element => {
      const rawVal = zipData?.analysis[element]?.current_raw;
      if (rawVal=== 0 || rawVal === null || !rawVal ) {
        count++;
      }
    });
    return count;
  }

}
