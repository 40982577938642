<app-sidebar class="app-sidebar">
  <nz-alert
    nzCloseable
    *ngIf="isVisible"
    class="alert-box"
    nzType="warning"
    nzMessage="Warning"
    (nzOnClose)="handleCancel()"
    nzDescription="We advise that you use Google Chrome. Although you can still use your current browser, Google Chrome is the recommended browser to use with the Zentap Dashboard."
    nzShowIcon>
  </nz-alert>
  <nz-alert
    nzType="warning"
    [nzMessage]="youtubeMessage"
    nzShowIcon
    class="mgb-12"
    *ngIf="customer.youtube && !customer.youtube.user_id_on_provider && isShowInHome">
  </nz-alert>
  <router-outlet></router-outlet>
</app-sidebar>

<ng-template #youtubeMessage>
  <span>
    <a href="https://support.google.com/youtube/answer/1646861?hl=en" target="_blank">YouTube Channel </a>not found! Please connect your YouTube account again.
  </span>
</ng-template>
