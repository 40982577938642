import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../../environments/environment';
import { DataResponse } from 'src/app/models/data-response/data-response';
import { Customer } from 'src/app/vos/customer/customer';
import { BehaviorSubject, of } from 'rxjs';
import { map, catchError, mapTo, tap } from 'rxjs/operators';

import { JwtService } from '../jwt/jwt.service';
import { WindowService } from '../window/window.service';
declare const $zopim;

/**
 * Config class to be wired into an injector.
 * @see CoreModule#forRoot
 * @see https://angular.io/guide/dependency-injection#optional-dependencies
 */
export class AuthenticationServiceConfig {
  uri = `${environment.api_url}customers/`;
}

@Injectable()
/**
 * Service class.
 */
export class AuthenticationService {
  private currentCustomerSubject: BehaviorSubject<Customer>;
  public currentCustomer: Observable<Customer>;
  public isOverridden = false;
  /**
   * Path uri.
   * @type {string}
   * @private
   */
  private _uri;
  // = `${environment.api_url}customers/`;

  /**
   * Url to endpoint api.
   * @type {string}
   */
  private basic_endpoint = 'login';
  private magic_endpoint = 'magic_links';
  private activate_trial_endpoint = 'trials';
  private magic_link_validate_endpoint = 'magic_links/validate';
  private facebook_endpoint = 'facebook/user';
  private facebook_signup_endpoint = 'facebook_sign_up';
  private deauth_endpoint = 'logout';
  private refresh_endpoint = 'refresh';
  private check_endpoint = 'check';
  private resend_confirm_endpoint = 'customers/confirmation.json';

  /**
   * Endpoint request headers.
   * @type {HttpHeaders}
   */
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  /**
   * Component constructor and DI injection point.
   * @param {HttpClient} http
   * @param {AuthenticationsServiceConfig} config
   */
  constructor(
    private http: HttpClient,
    private jwtService: JwtService,
    private winService: WindowService,
    @Optional() config: AuthenticationServiceConfig
  ) {
    const savedCustomer = this.jwtService.getSavedCustomer();
    if (savedCustomer && savedCustomer.id) {
      this._uri = `${environment.api_url}customers/${savedCustomer.id}`;
    } else {
      this._uri = `${environment.api_url}customers/`;
    }

    this.currentCustomerSubject = new BehaviorSubject(savedCustomer);
    this.currentCustomer = this.currentCustomerSubject.asObservable();

    if (config) {
      this._uri = config.uri;
    }
    if (this.jwtService.getSavedCustomer()) {
      this.updateCurrentCustomer(this.jwtService.getSavedCustomer());
    }
  }

  public get currentCustomerValue(): Customer {
    if (!this.currentCustomerSubject?.value && this.jwtService.getSavedCustomer()) {
      this.updateCurrentCustomer(this.jwtService.getSavedCustomer());
    } else if (!this.currentCustomerSubject?.value) {
      this.logout();
    }
    return this.currentCustomerSubject?.value;
  }

  logout() {
    this.jwtService.destroyToken();
    this.currentCustomerSubject.next(null);
    this.isOverridden = false;
  }

  overrideLogin(token: string): Observable<Customer> {
    this.logout();
    this.jwtService.saveToken(`Bearer ${token}`);
    this.isOverridden = true;
    return this.refresh();
  }

  checkEmail(email: string): Observable<boolean> {
    return this.http
      .get<HttpResponse<void>>(
        `${environment.api_url}${this.check_endpoint}`,
        { headers: this.headers, observe: 'response', params: { email } }
      )
      .pipe(
        map(resp => {
          return resp.ok;
        })
      );
  }

  resendConfirmation(email: string): Observable<boolean> {
    return this.http
      .post<HttpResponse<void>>(
        `${environment.api_url}${this.resend_confirm_endpoint}`,
        { customer: { email: email } },
        { headers: this.headers, observe: 'response' }
      )
      .pipe(
        map(resp => {
          return resp.ok;
        })
      );
  }
  /**
   * Login a Customer with email + password.
   * @param {string} email of customer.
   * @param {string} password of customer.
   * @returns {Observable<DataResponse<Customer>>}
   */
  login(email: string, password: string): Observable<Customer> {
    this.isOverridden = false;
    return this.http
      .post<DataResponse<Customer>>(
        `${`${environment.api_url}customers/`}${this.basic_endpoint}`,
        JSON.stringify({ email, password }),
        { headers: this.headers, observe: 'response' }
      )
      .pipe(
        map(resp => {
          const authHeader = resp.headers.get('Authorization');
          const customer = new Customer(resp?.body.data);
          if (authHeader) {
            this.jwtService.saveToken(authHeader);
            this.updateCurrentCustomer(customer);
            // this.jwtService.saveCurrentCustomer(customer);
            // this.currentCustomerSubject.next(customer);
          }
          return customer;
        })
      );
  }


  /**
  * Magic Login a Invite with email .
  * @param {string} email of customer.
  * @returns {Observable<DataResponse<any>>}
  */
  magicInvite(email: string): Observable<any> {
    return this.http
      .post<DataResponse<Customer>>(
        `${environment.api_url}api/v1/${this.magic_endpoint}`,
        JSON.stringify({ email }),
        { headers: this.headers, observe: 'response' }
      );
  }

  reactivateTrial(email: string): Observable<any> {
    return this.http
      .post<DataResponse<Customer>>(
        `${environment.api_url}api/v1/${this.activate_trial_endpoint}/reactivate`,
        JSON.stringify({ email }),
        { headers: this.headers, observe: 'response' }
      );
  }

  validateMagicLink(code: string): Observable<any> {
    return this.http
      .post<DataResponse<Customer>>(
        `${environment.api_url}api/v1/${this.magic_link_validate_endpoint}`,
        JSON.stringify({ token: code }),
        { headers: this.headers, observe: 'response' }
      ).pipe(
        map(resp => {
          const authHeader = resp.headers.get('Authorization');
          const customer = new Customer(resp?.body.data);
          if (authHeader) {
            this.jwtService.saveToken(authHeader);
            this.updateCurrentCustomer(customer);
          }
          return customer;
        })
      );
  }

  signup(customer: Customer): Observable<Customer> {
    this.isOverridden = false;
    return this.http
      .post<DataResponse<Customer>>(
        `${this._uri}`,
        JSON.stringify({ customer }),
        { headers: this.headers, observe: 'response' }
      )
      .pipe(
        map(resp => {
          const c = new Customer(resp?.body.data);
          const authHeader = resp.headers.get('Authorization');
          if (authHeader) {
            this.jwtService.saveToken(authHeader);
            this.updateCurrentCustomer(c);
          }
          return c;
        })
      );
  }
  confirmCustomer(token: string): Observable<Customer> {
    return this.http
      .get<DataResponse<Customer>>(
        `${this._uri}confirmation.json?confirmation_token=${token}`,
        { headers: this.headers, observe: 'response' }
      )
      .pipe(
        map(resp => {
          const authHeader = resp.headers.get('Authorization');
          const customer = new Customer(resp?.body.data);
          if (authHeader) {
            this.jwtService.saveToken(authHeader);
            this.updateCurrentCustomer(customer);
          }
          return customer;
        })
      );
  }
  /**
   * Login a Customer with facebook access token.
   * @param {string} access_token of customer.
   * @returns {Observable<DataResponse<Customer>>}
   */
  loginFacebook(access_token: string, isSignup = false): Observable<Customer> {
    this.isOverridden = false;
    let url = this._uri.indexOf(`${this.currentCustomerValue.id}`) === -1
      ? `${this._uri}${this.currentCustomerValue.id}/facebook/user`
      : `${this._uri}/facebook/user`;
    return this.http
      .post<DataResponse<Customer>>(
        url,
        JSON.stringify({ facebook_user: { code: access_token } }),
        { headers: this.headers, observe: 'response' }
      )
      .pipe(
        map(resp => {
          const authHeader = resp.headers.get('Authorization');
          const customer = new Customer(resp?.body.data);
          if (authHeader) {
            this.jwtService.saveToken(authHeader);
            this.updateCurrentCustomer(customer);
            // this.jwtService.saveCurrentCustomer(customer);
            // this.currentCustomerSubject.next(customer);
          }
          return customer;
        })
      );
  }

  updateCurrentCustomer(customer: Customer): void {
    if (!(customer instanceof Customer)) {
      customer = new Customer(customer);
    }
    this.currentCustomerSubject.next(customer);
    this.jwtService.saveCurrentCustomer(customer);

    // this.winService.nativeWindow.zESettings = {
    //   webWidget: {
    //     chat: {
    //       tags: [ customer.tier ]
    //     }
    //   }
    // };

  }

  requestResetPassword(email: string): Observable<boolean> {
    const url = `${environment.api_url}customers/password`;
    return this.http
      .post<any>(url, JSON.stringify({ customer: { email } }), {
        headers: this.headers,
        observe: 'response'
      })
      .pipe(
        map(resp => {
          return resp.ok;
        })
      );
  }

  resetPassword(info: {
    password: string;
    password_confirmation: string;
    reset_password_token: string;
  }): Observable<any> {
    const url = `${environment.api_url}customers/password`;
    return this.http.put<any>(url, JSON.stringify({ customer: info }), {
      headers: this.headers,
      observe: 'response'
    });
  }

  resetFacebook(
    access_token: string,
    reset_password_token: string
  ): Observable<Customer> {
    return this.http
      .put<DataResponse<Customer>>(
        `${this._uri}${this.facebook_endpoint}`,
        JSON.stringify({ access_token, reset_password_token }),
        { headers: this.headers, observe: 'response' }
      )
      .pipe(
        map(
          resp => {
            const authHeader = resp.headers.get('Authorization');
            const customer = new Customer(resp?.body.data);
            if (authHeader) {
              this.jwtService.saveToken(authHeader);
              this.updateCurrentCustomer(customer);
              // this.jwtService.saveCurrentCustomer(customer);
              // this.currentCustomerSubject.next(customer);
            }
            return customer;
          },
          error => {
            console.log(error);
          }
        )
      );
  }

  refresh(): Observable<Customer> {
    if (!this.jwtService.getToken()) {
      return of(null);
    }
    return this.http
      .get<DataResponse<Customer>>(`${environment.api_url}customers/${this.refresh_endpoint}`, {
        headers: this.headers,
        observe: 'response'
      })
      .pipe(
        map(resp => {
          const authHeader = resp.headers.get('Authorization');
          const customer = new Customer(resp?.body.data);
          if (customer) {
            this.updateCurrentCustomer(customer);
            this.jwtService.saveToken(authHeader);
            // this.jwtService.saveCurrentCustomer(customer);
            // this.currentCustomerSubject.next(customer);
          }
          return customer;
        }),
        catchError(e => {
          if (e instanceof HttpErrorResponse && e.status === 401) {
            this.logout();
          }
          return of(null);
        })
      );
  }

  emailAuthentication() {
    let url = this._uri.indexOf(`${this.currentCustomerValue.id}`) === -1
      ? `${this._uri}${this.currentCustomerValue.id}/email_sender/auth.json`
      : `${this._uri}/email_sender/auth.json`;
    return this.http.post<any>(url,
      JSON.stringify({}),
      // { headers: { Authorization: `${this.jwtService.getToken()}`, 'Content-Type': 'application/json' } }
    )
      .pipe(tap((resp) => {
        this.jwtService.saveEmailToken(resp.auth_token);
      }));
  }

  createEmailAuthentication() {
    let url = this._uri.indexOf(`${this.currentCustomerValue.id}`) === -1
      ? `${this._uri}${this.currentCustomerValue.id}/email_sender.json`
      : `${this._uri}/email_sender.json`;
    return this.http.post<any>(url,
      {}
    ).pipe(tap((resp) => {
    }));
  }

  getEmailAuthentication() {
    let url = this._uri.indexOf(`${this.currentCustomerValue.id}`) === -1
      ? `${this._uri}${this.currentCustomerValue.id}/email_sender.json`
      : `${this._uri}/email_sender.json`;
    // if (window.location.hostname === 'localhost') {
    //   url = environment.email_api_url + 'email_sender';
    // }
    return this.http.get<any>(url,
      // { headers: { Authorization: `${this.jwtService.getToken()}`, 'Content-Type': 'application/json' } }
    ).pipe(tap((resp) => {
    }));
  }

  /**
   * Destroys a single Authentication object.
   * @returns {Observable<void>}
   */
  destroy(): Observable<void> {
    const url = `${this._uri}${this.deauth_endpoint}`;
    return this.http.delete<void>(url, { headers: this.headers });
  }

  get decodedUser() {
    return this.jwtService.getCurrentDecodedJwt();
  }

}
