import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadCrum } from '../../shared/bread-crum/bread-crum.component';

@Injectable({
  providedIn: 'root',
})
export class BreadCrumService {

  public breadcrumsList: Array<BreadCrum>;
  constructor(private router: Router, private route: ActivatedRoute) {
    this.breadcrumsList = new Array<BreadCrum>();
  }
  get_breadcrum(): BreadCrum[] {
    return this.breadcrumsList;
  }
  public set_breadcrum() {
    const router_url = this.router.url.split('?')[0];
    const nonUrls = ['banner_videos', 'branded_infomercials', 'social_media_content', 'commercial_listing_videos', 'congrats_videos',
      'open_house_videos', 'cover_photos', 'testimonial_videos', 'testimonial_flyers'];
    let urlParts = router_url.split('/').filter(up => up);

    if (urlParts.length === 0) {
      this.breadcrumsList = [{ name: "Home", url: "/" }];
    } else {
      this.breadcrumsList = [{ name: 'Home', url: '/' }]
    }

    let url = '';
    for (let i = 0; i < urlParts.length; i++) {
      const part = urlParts[i];
      url += `/${part}`;

      if (part === 'new') {
        if ( i > 2) {
          this.breadcrumsList[this.breadcrumsList.length - 1].url = url;
        }
        continue;
      } else if (this.isNumberOrStringNumber(part) && urlParts[0] === "testimonials") {
        continue;
      }
      if (nonUrls.indexOf(part) === -1 || i > 2 || nonUrls.includes(part)) {
        this.breadcrumsList.push({ name: this.titleize(part), url });
      }
    }

    return this.breadcrumsList
  }

  private titleize(str: string): string {
    return str.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
  }
  public push_breadcrum(breadcrum, tempBreadcrum?) {
    this.set_breadcrum();
    this.breadcrumsList.push(tempBreadcrum);
    this.breadcrumsList.push(breadcrum);
  }

  public set_breadcrumbList(list) {
    this.breadcrumsList = list;
  }

  public replaceItem(fromName, toName, url?) {
    let index = this.breadcrumsList?.findIndex(b => b.name === fromName);
    if (index > -1) {
      this.breadcrumsList[index].name = toName;
      this.breadcrumsList[index].url = url ? url : this.breadcrumsList[index].url;
    }
  }
  public removeItem(fromName) {
    let index = this.breadcrumsList?.findIndex(b => b.name === fromName);
    if (index > -1) {
      this.breadcrumsList.splice(index, 1);
    }
  }

  isNumberOrStringNumber(value: any) {
    return (typeof value === 'number' && !isNaN(value)) ||
          (typeof value === 'string' && !isNaN(parseFloat(value)) && !isNaN(parseFloat(value)));
  }
}
