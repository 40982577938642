import { Component, OnInit } from '@angular/core';
import { MarketReportsService } from 'src/app/services/market-reports/market-reports.service';
import { MarketReport } from 'src/app/vos/market-report/market-report';
import { BreadCrumService } from '../../services/breadcrum/bread-crum.service';
import { Router } from '@angular/router';
import { PaginationService } from 'src/app/services/pagination/pagination.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { GenericDialogComponent } from '../../shared/generic-dialog/generic-dialog.component';
import { MarketReportFormModalComponent } from './market-report-form-modal/market-report-form-modal.component';
import { IModalData } from 'src/app/models/interfaces/modals/modal';
import { GenericDialogConfig } from 'src/app/models/interfaces/modals/generic-modal-config';

@Component({
  selector: 'app-marketreports',
  templateUrl: './market-reports.component.html',
  styleUrls: ['./market-reports.component.scss']
})
export class MarketReportsComponent implements OnInit {
  marketreports: MarketReport[] = [];
  loading = false;
  maxmarketreports = 10;
  disableNew = false;
  sortings = ['region_name'];
  searchText: string;
  search = false;
  mapOfSort: { [key: string]: string | null } = {
    created_at: null,
    address: null,
    region_name: null
  };
  selectedSort = {
    by: 'region_name',
    order: 'asc'
  };
  pageSizeOptions = [10];
  ReportsPagination = {
    limit: 10,
    total: 10,
    page: 1,
    totalPages: 10
  };
  public searchModelChanged: Subject<string> = new Subject<string>();
  public searchModelChangeSubscription: Subscription;
  constructor(
    private marketreportService: MarketReportsService,
    private breadcrumservice: BreadCrumService,
    private router: Router,
    private paginationService: PaginationService,
    private modalService: NzModalService,
    public message: NzMessageService,
  ) {
    this.searchModelChangeSubscription = this.searchModelChanged
      .pipe(
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe(newText => {
        this.searchText = newText;
        // this.listingPagination.page = 1;
        if (newText) {
          this.search = true;
          this.fetchMarketReports(newText);
        } else {
          this.fetchMarketReports();
        }
      });
  }

  ngOnInit() {
    this.loading = true;
    this.fetchMarketReports();
    this.breadcrumservice.set_breadcrum();
  }
  fetchMarketReports(text?, sort?) {
    this.loading = true;
    const pageNo = this.paginationService.getCurrentPage();
    let query = `?page=${pageNo}`;
    if (text) {
      query = `${query}&q[region_name_cont]=${text}`;
    }
    if (sort) {
      query = `${query}&${sort}`;
    }
    this.marketreportService.list(query).subscribe(response => {
      if (response) {
        this.loading = false;
        this.ReportsPagination.page = pageNo;
        this.ReportsPagination.limit = response['per_page'];
        this.ReportsPagination.total = response['total_entries'];
        const limit = this.ReportsPagination.limit;
        const total_items = this.ReportsPagination.total;
        this.ReportsPagination.totalPages = Math.ceil(total_items / limit);
        this.marketreports = response.data;
        this.removeAddMarketReportCard(response);
      }
    });
  }
  didPage(pageIndex) {
    this.ReportsPagination.page = pageIndex;
    this.loading = true;
    this.paginationService.setCurrentPage(this.ReportsPagination);
    this.fetchMarketReports();
  }
  removeAddMarketReportCard(reports) {
    if (reports.total_entries < this.maxmarketreports) {
      this.disableNew = true;
    }
  }
  sort(sort: { key: string; value: string }): void {
    for (const key in this.mapOfSort) {
      this.mapOfSort[key] = key === sort.key ? sort.value : null;
    }
    const valuesMap = {
      descend: 'desc',
      ascend: 'asc'
    };
    this.fetchMarketReports(null, `q[s]=${sort.key} ${valuesMap[sort.value]}`);
  }

  viewDetail(id) {
    this.router.navigateByUrl(`my_markets/${id}`);
  }

  editListing(id) {
    this.router.navigateByUrl(`/my_markets/${id}/edit`);
  }

  createNew() {
    this.router.navigateByUrl('my_markets/new');
  }
  createMarketReport() {
    const modal = this.modalService.create<MarketReportFormModalComponent, IModalData>({
      nzContent: MarketReportFormModalComponent,
      nzData: {},
      nzFooter: null
    });
    modal.afterClose.subscribe(response => {
      this.fetchMarketReports();
    });
  }

  editReport(marketReport: MarketReport) {
    const modal = this.modalService.create<MarketReportFormModalComponent, IModalData>({
      nzContent: MarketReportFormModalComponent,
      nzData: {
        marketReport: marketReport
      },
      nzFooter: null,
    });
    modal.afterClose.subscribe(updatedReport => {
      if (updatedReport) {
        const index = this.marketreports.findIndex(report => report.id === updatedReport.id);
        if (index !== -1) {
          this.marketreports[index] = updatedReport;
        }
      }
    });
  }

  deleteReport(marketReport) {
    const modal = this.modalService.create<GenericDialogComponent, GenericDialogConfig>({
      nzContent: GenericDialogComponent,
      nzData: {
        title: 'Confirm',
        message: 'Are you sure you want to delete this market report?',
        buttonLabel: 'Cancel',
        extraButtons: [
          {
            label: 'Confirm',
            value: true,
            color: 'warn'
          }
        ]
      },
      nzFooter: null
    });
    modal.afterClose.subscribe(response => {
      if (response === true) {
        this.loading = true;
        this.marketreportService.destroy(marketReport).subscribe(r => {
          this.fetchMarketReports();
        }, e => {
          this.loading = false;
          this.message?.remove();
          // this.message?.create('error', 'Error deleting this market report. Please try again');
        });
      }
    });
  }
  updateMarketReport(event, market) {
    event === 1 ? market.primary = true : market.primary = false;
    this.loading = true;
    this.marketreportService.update(market).subscribe(s => {
      this.fetchMarketReports();
    }, error => { this.fetchMarketReports(); });
  }
}
