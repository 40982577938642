<div class="container">
  <div class="title-header" *ngTemplateOutlet="TestimonialContent"></div>
  <form #testimonialForm="ngForm" nz-form>

    <nz-steps [nzCurrent]="current" class="content-stepper">
      <nz-step nzTitle="Select"></nz-step>
      <nz-step nzTitle="Customize"></nz-step>
      <nz-step nzTitle="Done"></nz-step>
    </nz-steps>


    <div class="steps-content" *ngIf="current === 0">
      <h2 class="type-title">Testimonial {{testimonialContentType}}</h2>
      <div nz-row nzType="flex" nzJustify="end">
        <!-- <button nz-button nzType="primary" class="right-spacing" (click)="back()">Back</button> -->
        <button nz-button nzType="primary" (click)="next();" class="next-button" [disabled]="!valid">
          Save and Continue
        </button>
      </div>
      <div nz-row nzType="flex" nzAlign="middle" class="mg-t-20">
        <div class="image-card-wrapper" nz-col nzSpan="10" [nzMd]="10" [nzSm]="11" [nzXs]="24">
          <div class="label-text"><span>{{requiredFields.includes('familyphoto') ? ' *' : ''}}</span> Add Attestant's
            Photo:</div>
          <span class="label-text-sm">A photo of a person providing the testimonial.</span>
          <app-image-handler [imageable_type]="'attestant_photo'" [content]="content" [config]="attestantPhotoConfig"
            (imageObject)="setFamilyImage($event)">
          </app-image-handler>
          <!-- <app-image-handler [imageable_type]="'attestant_photo'" [content]="content" [config]="attestantPhotoConfig"
            (imageObject)="setFamilyImage($event)">
          </app-image-handler> -->
        </div>
        <div nz-col nzSpan="10" [nzMd]="10" [nzSm]="12" [nzXs]="24">
          <nz-form-item class="right-spacing">
            <nz-form-label class="text-left label-text" [nzSm]="24" [nzXs]="24" for="zoning"
              [nzRequired]="requiredFields.includes('FamilyName')">Attestant's Name
            </nz-form-label>
            <nz-form-control [nzSm]="24" [nzXs]="24">
              <input nz-input type="text" [(ngModel)]="testimonial.FamilyName" class="form-control" id="FamilyName"
                name="FamilyName" [required]="requiredFields.includes('FamilyName')" maxlength="70">
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label class="text-left label-text" [nzSm]="24" [nzXs]="24" for="description"
              [nzRequired]="requiredFields.includes('Message')">Message</nz-form-label>
            <nz-form-label [nzSm]="24" [nzXs]="24" class="sub-text">Everything written in the box below will be wrapped
              in "quotations"</nz-form-label>
            <nz-form-control [nzSm]="24" [nzXs]="24" class="textarea-wrapper">
              <textarea nz-input type="text" class="form-control" id="Message" [(ngModel)]="testimonial.Message"
                name="Message" [required]="requiredFields.includes('Message')" maxLength="250"
                (ngModelChange)="valueChange($event)"></textarea>
              <span class="remaning">{{textLength ? textLength : 0}} / 250</span>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

    </div>
  </form>
  <div class="steps-content" *ngIf="current === 1">
<!--    <ng-container [ngTemplateOutlet]="actions"></ng-container>-->
    <app-content-form [submitted]="submitted" [isTestimonial]="yes" [socialConfig]="socialConfig" *ngIf="styles"
      #contentForm2 [model]="content" [showSchedule]="showSchedule" [styles]="styles"
      (hashTagLimit)="hashTagLimit($event)">
    </app-content-form>
    <div class="end-actions">
      <ng-container [ngTemplateOutlet]="actions"></ng-container>
    </div>
  </div>
</div>

<ng-template #actions>
  <div nz-row nzJustify="space-around" class="top-actions">
    <div nz-col nzSpan="8">
      <button nz-button nzSpan="12" class="back-btn" (click)="previous()">
        Back</button>
    </div>
    <div nz-col nzSpan="4" *ngIf="showSchedule" nzOffset="8" class="text-right">
      <button nz-button nzSpan="12" (click)="showSchedule = false;content.scheduled_for = null">
        Cancel Schedule</button>
    </div>
    <div *ngIf="showSchedule" nz-col nzSpan="4">
      <button nz-button nzSpan="12" class="fl-r" nzType="primary" color="primary" (click)="finalSubmit()">Schedule
        Post</button>
    </div>
    <div *ngIf="!showSchedule" nz-col nzSpan="8" nzOffset="8">
      <button nz-button nz-dropdown nzType="primary" [disabled]="ishashTagLimit" [nzDropdownMenu]="menu4" class="fl-r">
        Create
        <i nz-icon nzType="down"></i>
      </button>
      <nz-dropdown-menu #menu4="nzDropdownMenu">
        <ul nz-menu *ngIf="!ishashTagLimit">
          <li nz-menu-item (click)="render()">
            <i nz-icon nzType="sketch"></i>
            Render
          </li>
          <li nz-menu-item (click)="showSchedule = true" [nzDisabled]="customer.render_only">
            <i nz-icon nzType="field-time" nzTheme="outline"></i>
            Schedule
          </li>
          <li nz-menu-item (click)="finalSubmit()" [nzDisabled]="customer.render_only">
            <i nz-icon nzType="share-alt" [ngStyle]="{'color': content.status === 'ready' ? '#0170f7' : '#9d9a9a'}"
              class="down-icon"></i>
            Post Now
          </li>
        </ul>
      </nz-dropdown-menu>
    </div>
  </div>
</ng-template>

<ng-template #TestimonialContent>
  <h1 class="section-title title">
    <!-- <i nz-icon nzType="layout"></i> -->
    Testimonial {{testimonialContentType}}s
  </h1>
</ng-template>
