<div class="container">
  <div class="header">
    <div class="header-title">
      {{ content.display_name || content.content_attributes.display_name }}
    </div>
    <div class="header-subtitle">
      <div class="social-icon">
        <span class="icon-wrapper" *ngIf="checkSocial('facebook')">
          <img class="box-icon" src=".././../../assets/icons/facebook.png" alt="" />
          <span class="social-name">Facebook</span>
        </span>
        <span class="icon-wrapper" *ngIf="checkSocial('linkedin')">
          <img class="box-icon" src=".././../../assets/icons/linkedin.png" alt="" />
          <span class="social-name">Linkedin</span>
        </span>
        <span class="icon-wrapper" *ngIf="checkSocial('instagram')">
          <img class="box-icon" src=".././../../assets/icons/instagram.png" alt="" />
          <span class="social-name">Instagram</span>
        </span>
        <span class="icon-wrapper" *ngIf="checkSocial('google')">
          <img class="yt-box-icon" src=".././../../assets/icons/google.png" alt="" />
          <span class="social-name">Google</span>
        </span>
        <span class="icon-wrapper" *ngIf="checkSocial('youtube')">
          <img class="box-icon" src=".././../../assets/icons/youtube.png" alt="" />
          <span class="social-name">Youtube</span>
        </span>
        <span class="icon-wrapper" *ngIf="checkSocial('tiktok')">
          <img class="box-icon" src=".././../../assets/icons/tiktok.png" alt="" />
          <span class="social-name">Tiktok</span>
        </span>
      </div>
      <div class="subtitle-time">
        <span class="subtitle-time-icon">
          <span nz-icon nzType="calendar" nzTheme="outline" style="font-size: 24px;"></span>
        </span>
        <span class="subtitle-time-text">
          {{ content.created_at | date: 'EEE, MMM d' }}
        </span>
      </div>
    </div>
  </div>

  <div class="content-preview">
    <div class="content-preview-image">
      <div *ngIf="content.content_attributes.status !== 'rendering'" (click)="showPreview(content.content_attributes)"
        class="image-wrapper">
        <!-- Image Content -->
        <img *ngIf="content.content_attributes.media_type === 'image'" [src]="content.content_attributes.url"
          [alt]="content.content_attributes.caption" loading="lazy" />

        <!-- Carousel Content -->
        <img *ngIf="content.content_attributes.media_type === 'carousel'" [src]="content.content_attributes.fileURLs[0]"
          [alt]="content.content_attributes.caption" loading="lazy" />

        <!-- Video Content -->
        <video *ngIf="content.content_attributes.media_type === 'video'" autoplay loop [muted]="true" controls
          playsinline>
          <source [src]="content.content_attributes.url" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>

    <div class="content-preview-details">
      <div class="status">
        <span class="title">Status:</span>
        <div class="status-container">
          <span nz-icon nzType="check-circle" nzTheme="fill"></span>
          <span>Successfully</span>
        </div>
      </div>

      <div class="schedule">
        <span class="title">Scheduled:</span>
        <div style="display: flex; flex-wrap: wrap;gap:10px">
          <div class="datetime-container">
            <span nz-icon nzType="calendar" nzTheme="outline"></span>
            <span>{{ formatDate(content.post.scheduled_for) || '-' }}</span>
          </div>
          <div class="datetime-container">
            <span nz-icon nzType="clock-circle" nzTheme="outline"></span>
            <span>{{formatTime(content.post.scheduled_for) || '-'}}</span>
          </div>
        </div>
      </div>

      <div class="posted">
        <span class="title">Posted:</span>
        <div style="display: flex; flex-wrap: wrap; gap:10px">
          <div class="datetime-container">
            <span nz-icon nzType="calendar" nzTheme="outline"></span>
            <span>{{formatDate(content.post.posted_at) || '-'}}</span>
          </div>
          <div class="datetime-container">
            <span nz-icon nzType="clock-circle" nzTheme="outline"></span>
            <span>{{formatTime(content.post.posted_at) || '-'}}</span>
          </div>
        </div>
      </div>

      <div class="description">
        <span class="title">Description:</span>
        <div class="text">
          <span [innerHTML]="sanitizedContent">
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="footer">
    <div class="button">
      <button nz-tooltip
        [nzTooltipTitle]="isNoSupportDeletePostOnSocial.includes(content.post.posted_on) ? 'No support' : ''"
        nzTooltipPlacement="topLeft" nz-button nzType="primary" class="btnDelete" (click)="postDeleted()"
        [disabled]="isNoSupportDeletePostOnSocial.includes(content.post.posted_on)">
        <span nz-icon nzType="delete"></span>
        Delete
      </button>
      <button nz-button nzType="primary" class="btnConfirm" (click)="close()">
        <span nz-icon nzType="check-circle"></span>
        Confirm
      </button>
    </div>
  </div>
</div>