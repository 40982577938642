import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AppGuard } from './app.guard';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { TermsComponent } from './components/terms/terms.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { MagicLoginComponent } from './components/magic-login/magic-login.component';
import { MagicLoginLinkComponent } from './components/magic-login-link/magic-login-link.component';
import { LoginGuard } from './components/login/login.guard';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { OnboardingGuard } from './components/registration-form/onboarding.guard';
import { TypeResolverService } from './services/type-resolver.service';
import { CreateContentComponent } from './components/create-content/create-content.component';
import { SelectCreateContentComponent } from './components/select-create-content/select-create-content.component';

import { RoiReportsComponent } from './shared/roi-reports/roi-reports.component';
import { ContactsListComponent } from './components/contacts-list/contacts-list.component';
import {GoogleConsentAuthComponent} from './components/google-consent-auth/google-consent-auth.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import {ContentPreviewDialogComponent} from './shared/content-preview-dialog/content-preview-dialog.component';
import { ContentPostComponent } from './shared/content-post/content-post.component';
import {AudiencesComponent} from './components/audiences/audiences.component';
import {MarketplaceComponent} from './components/marketplace/marketplace.component';
import {CrmComponent} from './components/crm/crm.component';
import {ReactivateTrialComponent} from './components/reactivate-trial/reactivate-trial.component';

/**
 * Main app routing. Includes the initial empty redirect.
 * @type {[{path: string; redirectTo: string; pathMatch: string}]}
 */
const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'google/consent',
    component: GoogleConsentAuthComponent
  },
  {
    path: 'login/magic',
    component: MagicLoginComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'login/magic_links',
    component: MagicLoginLinkComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'reactivate_trial',
    component: ReactivateTrialComponent,
    canActivate: [LoginGuard]
  },
  { path: 'logout', canActivate: [LoginGuard], component: LoginComponent, data: { user_logout: true } },
  {
    path: 'forgot_password',
    loadChildren: () => import('./modules/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
  },
  {
    path: 'agents/:id',
    loadChildren: () => import('./modules/business-profile/business-profile.module').then(m => m.BusinessProfileModule)
  },
  {
    path: 'agent_listing/:id',
    component: LandingPageComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'reset_password',
    loadChildren: () => import('./modules/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./modules/account-register/account-register.module').then(m => m.AccountRegisterModule)
  },
  {
    path: 'confirmation',
    loadChildren: () => import('./modules/confirmation/confirmation.module').then(m => m.ConfirmationModule)
  },
  {
    path: 'registration',
    canActivate: [AppGuard],
    // canActivate: [OnboardingGuard],
    loadChildren: () => import('./modules/registration/registration.module').then(m => m.RegistrationModule)
  },
  {
    path: '',
    canActivate: [AppGuard],
    component: HomeComponent,
    children: [
      {
        path: '',
        component: DashboardComponent,
        canActivate: [AppGuard],
      },
      {
        path: 'terms',
        component: TermsComponent,
      },
      {
        path: 'terms-conditions',
        component: TermsComponent,
      },
      {
        path: 'create_content',
        component: CreateContentComponent,
        canActivate: [AppGuard],
      },
      {
        path: 'crm',
        component: CrmComponent,
        canActivate: [AppGuard],
      },
      {
        path: 'create_content/listings',
        component: SelectCreateContentComponent,
        canActivate: [AppGuard],
      },
      {
        path: 'create_content/marketing',
        component: SelectCreateContentComponent,
        canActivate: [AppGuard],

      },
      {
        path: 'content/:id/post',
        component: ContentPostComponent,
        canActivate: [AppGuard],

      },
      {
        path: 'create_content/informative',
        canActivate: [AppGuard],
        component: SelectCreateContentComponent,
      },
      // {
      //   path: 'make_content',
      //   loadChildren: () => import('./modules/content_type/content_type.module').then(m => m.ContentTypeModule)
      // },
      // lazyload websites module
      {
        path: 'websites',
        canActivate: [AppGuard],
        loadChildren: () => import('./modules/websites/websites.module').then(m => m.WebsitesModule)
      },
      {
        path: 'marketplace',
        canActivate: [AppGuard],
        component: MarketplaceComponent,
      },
      {
        path: 'tips',
        canActivate: [AppGuard],
        loadChildren: () => import('./modules/marketing-tips/marketing-tips.module').then(m => m.MarketingTipsModule)
      },
      {
        path: 'news',
        canActivate: [AppGuard],
        loadChildren: () => import('./modules/news/news.module').then(m => m.NewsModule)
      },
      {
        path: 'templates',
        canActivate: [AppGuard],
        loadChildren: () => import('./modules/templates/templates.module').then(m => m.TemplatesModule)
      },
      {
        path: 'campaigns',
        canActivate: [AppGuard],
        loadChildren: () => import('./modules/campaigns/campaigns.module').then(m => m.CampaignsModule)
      },
      {
        path: 'leads',
        canActivate: [AppGuard],
        loadChildren: () => import('./modules/leads/leads.module').then(m => m.LeadsModule)
      },
      {
        path: 'audience',
        canActivate: [AppGuard],
        component: AudiencesComponent
      },
      {
        path: 'team/team_marketing_videos/new',
        canActivate: [AppGuard],
        loadChildren: () => import('./modules/market-video/market-video.module').then(m => m.MarketVideoModule)
      },
      {
        path: 'team',
        canActivate: [AppGuard],
        loadChildren: () => import('./modules/team/team.module').then(m => m.TeamModule)
      },
      {
        path: 'account',
        canActivate: [AppGuard],
        loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule)
      },
      // {
      //   path: 'email-tool-analytics',
      //   loadChildren: () => import('./modules/t').then(m => m.EmailToolAnalyticsModule)
      // },
      {
        path: 'notifications',
        canActivate: [AppGuard],
        loadChildren: () => import('./modules/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'email_campaigns',
        canActivate: [AppGuard],
        loadChildren: () => import('./modules/email/email.module').then(m => m.EmailModule)
      },
      {
        path: 'contacts/list',
        canActivate: [AppGuard],
        component: ContactsListComponent
      },
      {
        path: 'contacts',
        canActivate: [AppGuard],
        loadChildren: () => import('./modules/contacts/contacts.module').then(m => m.ContactsModule)
      },
      {
        path: 'conversations',
        canActivate: [AppGuard],
        loadChildren: () => import('./modules/conversations/conversations.module').then(m => m.ConversationsModule)
      },
      {
        path: 'create_content/:content_type/testimonial_videos/:id',
        canActivate: [AppGuard],
        loadChildren: () => import('./modules/testimonial/testimonial.module').then(m => m.TestimonialModule)
      },
      {
        path: 'create_content/:content_type/testimonial_flyers/:id',
        canActivate: [AppGuard],
        loadChildren: () => import('./modules/testimonial/testimonial.module').then(m => m.TestimonialModule)
      },
      {
        path: 'create_content/:content_type/announcement_video/new',
        canActivate: [AppGuard],
        loadChildren: () => import('./modules/announcement/announcement.module').then(m => m.AnnouncementModule)
      },
      {
        path: 'testimonial_videos',
        canActivate: [AppGuard],
        loadChildren: () => import('./modules/testimonial/testimonial.module').then(m => m.TestimonialModule)
      },
      {
        path: 'testimonial_flyers',
        canActivate: [AppGuard],
        loadChildren: () => import('./modules/testimonial/testimonial.module').then(m => m.TestimonialModule)
      },
      {
        path: 'profile',
        canActivate: [AppGuard],
        loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule)
      },
      {
        path: 'celeb_luxury',
        canActivate: [AppGuard],
        loadChildren: () => import('./modules/celeb-luxury/celeb-luxury.module').then(m => m.CelebLuxuryModule)
      },
      {
        path: 'content',
        canActivate: [AppGuard],
        loadChildren: () => import('./modules/content/content.module').then(m => m.ContentModule)
      },
      {
        path: 'create_content/:content_type/social_media_content/new',
        loadChildren: () => import('./modules/infographics/infographics.module').then(m => m.InfographicsModule)
      },
      // lazy listings ads module
      {
        path: 'listings',
        canActivate: [AppGuard],
        loadChildren: () => import('./modules/listings/listings.module').then(m => m.ListingsModule)
      },
      // lazy market_reports ads module
      {
        path: 'my_markets',
        loadChildren: () => import('./modules/market-reports/market-reports.module').then(m => m.MarketReportsModule)
      },
      {
        path: 'testimonials',
        loadChildren: () => import('./modules/testimonies/testimonies.module').then(m => m.TestimoniesModule)
      },
      {
        path: 'markets',
        canActivate: [AppGuard],
        loadChildren: () => import('./modules/market-reports/market-reports.module').then(m => m.MarketReportsModule)
      },
      {
        path: 'welcome',
        canActivate: [AppGuard],
        loadChildren: () => import('./modules/welcome-package/welcome-package.module').then(m => m.WelcomePackageModule)
      },
      {
        path: 'settings',
        canActivate: [AppGuard],
        loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule)
      },
      {
        path: ':type/new',
        canActivate: [AppGuard],
        loadChildren: () => import('./modules/content-request/content-request.module').then(m => m.ContentRequestModule)
      },
      {
        path: 'create_content/:content_type/:name',
        canActivate: [AppGuard],
        loadChildren: () => import('./modules/select-resource/select-resource.module').then(m => m.SelectResourceModule)
      },
      {
        path: ':type/:content_type/marketing_videos/new',
        canActivate: [AppGuard],
        loadChildren: () => import('./modules/market-video/market-video.module').then(m => m.MarketVideoModule)
      },
      {
        path: ':type/:content_type/email_signature/new',
        canActivate: [AppGuard],
        loadChildren: () => import('./modules/email-signature/email-signature.module').then(m => m.MarketVideoModule)
      },
      {
        path: ':type/:content_type/marketing_flyer/new',
        canActivate: [AppGuard],
        loadChildren: () => import('./modules/market-video/market-video.module').then(m => m.MarketVideoModule)
      },
      {
        path: ':type/:content_type/marketing_flyers/new',
        canActivate: [AppGuard],
        loadChildren: () => import('./modules/market-video/market-video.module').then(m => m.MarketVideoModule)
      },
      {
        path: ':type/marketing_videos/new',
        canActivate: [AppGuard],
        loadChildren: () => import('./modules/market-video/market-video.module').then(m => m.MarketVideoModule)
      },
      {
        path: ':type/marketing_flyers/new',
        canActivate: [AppGuard],
        loadChildren: () => import('./modules/market-video/market-video.module').then(m => m.MarketVideoModule)
      },
      {
        path: 'marketing_videos',
        canActivate: [AppGuard],
        loadChildren: () => import('./modules/market-video/market-video.module').then(m => m.MarketVideoModule)
      },

      {
        path: 'create_content/:content_type/:form/new',
        canActivate: [AppGuard],
        loadChildren: () => import('./modules/content-request/content-request.module').then(m => m.ContentRequestModule)
      },
      {
        path: 'linkedin',
        canActivate: [AppGuard],
        loadChildren: () => import('./modules/linkedin/linkedin.module').then(m => m.LinkedinModule)
      },
      {
        path: 'google',
        canActivate: [AppGuard],
        loadChildren: () => import('./modules/google/google.module').then(m => m.GoogleModule)
      },
      {
        path: 'facebook',
        canActivate: [AppGuard],
        loadChildren: () => import('./modules/facebook/facebook.module').then(m => m.FacebookModule)
      },
      {
        path: 'youtube',
        canActivate: [AppGuard],
        loadChildren: () => import('./modules/youtube/youtube.module').then(m => m.YoutubeModule)
      },
      {
        path: 'reels',
        canActivate: [AppGuard],
        loadChildren: () => import('./modules/reels/reels.module').then(m => m.ReelsModule)
      },
      {
        path: 'tiktok',
        canActivate: [AppGuard],
        loadChildren: () => import('./modules/tiktok/tiktok.module').then(m => m.TiktokModule)
      },
      {
        path: 'create_content/:content_type/:form/:id/new',
        canActivate: [AppGuard],
        loadChildren: () => import('./modules/content-request/content-request.module').then(m => m.ContentRequestModule),
        resolve: {
          data: TypeResolverService
        }
      },
      {
        path: 'roi_reports',
        canActivate: [AppGuard],
        component: RoiReportsComponent
      },
      {
        path: '**',
        component: NotFoundComponent
      }
    ]
  }];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: !environment.production })],
  exports: [RouterModule],
  providers: [AppGuard]
})

export class AppRoutingModule {
}
