
<div class="is_desktop">
  <div nz-col>
    <div class="custom-card p15">
      <div class="title-header">
        <h1 class="section-title title">
          <img class="ellipsis-vertical" src="../../../assets/icons/Frame.svg">
          Featured
        </h1>
      </div>
      <nz-divider nzType="horizontal"></nz-divider>
      <div class="text-center">
        <ng-container [ngTemplateOutlet]="creationCards"></ng-container>
      </div>
    </div>
  </div>

</div>

<!--    CREATION CARD HEADING & TEMPLATE   -->

<ng-template #creationCards>
  <div nz-row >
    <div nz-col nzSpan="6" *ngFor="let product of products">
<!--      <article class="card" (click)="productClicked(product)">-->
      <article class="card">
        <header class="card-header">
          <img  src="{{product.image}}" class="product-logo" />
          <span class="price-tag">{{product.price}}</span>
        </header>
        <h2 class="product-title">{{product.name | humanize }}</h2>
        <p class="product-description">
          {{product.description}}
        </p>
        <footer class="card-footer">
          <button class="buy-now-button" *ngIf="product.locked" (click)="buyNow(product)">
            <span class="button-text">Buy Now</span>
          </button>
          <button class="connected pull-left" *ngIf="!product.locked">
            <span class="button-text">Connected</span>
          </button>
          <button class="schedule-call-btn" (click)="showModal();" *ngIf="product.locked" >
            <span class="btn-text">Schedule a call</span>
          </button>
        </footer>
      </article>
    </div>
  </div>
</ng-template>

<nz-modal [(nzVisible)]="isCheckoutVisible" nzTitle="Checkout" [nzFooter]='null' (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
  <ng-container *nzModalContent>
    <div nz-row>
      <div nz-col nzSpan="24">

        <nz-steps [nzCurrent]="currentStep">
          <nz-step nzTitle="Review Product"></nz-step>
          <nz-step nzTitle="Checkout"></nz-step>
          <nz-step nzTitle="Done"></nz-step>
        </nz-steps>
        <div class="steps-content">
          <br>
          <br>
          <div class="social-connect-container" *ngIf="currentStep == 0">
            <div class="social-headings">
              <header class="card-header">
                <img  src="{{buyNowProduct.image}}" class="product-logo" />
                <span class="price-tag">${{buyNowProduct.price}}</span>
              </header>
              <h2 class="product-title">{{buyNowProduct.name | humanize }}</h2>
              <p class="product-description">
                {{buyNowProduct.description}}
              </p>
            </div>
            <div class="social-selection">
              <button nz-button nzType="primary" (click)="proceedPayment()">Proceed to Payment</button>
            </div>
          </div>

          <div *ngIf="currentStep == 1">
            <div [formGroup]="paymentElementForm">
              <ngx-stripe-address #billingAddress [options]="billingAddressOptions" />

              <ngx-stripe-elements
                [stripe]="stripe"
                [elementsOptions]="elementsOptions" *ngIf="elementsOptions && elementsOptions.clientSecret">
                <ngx-stripe-payment />
              </ngx-stripe-elements>
            </div>
            <br>
            <nz-alert nzType="error" *ngIf="this.error" [nzMessage]="this.error"></nz-alert>
            <nz-divider></nz-divider>
            <div row class="margin-subscribe">
              <button nz-button nzType="primary" (click)="collectPayment()" [nzLoading]="this.loading">Subscribe</button>
            </div>

          </div>

          <div *ngIf="currentStep == 2">

            <nz-result
              nzStatus="success"
              nzTitle="Payment Successful!"  [nzIcon]="customIconTemplate">
              <ng-template #customIconTemplate>
                <img  src="../../../assets/icons/payment.svg" class="product-logo" />
              </ng-template>
              <div nz-result-extra>
                The payment has been done successfully.Thanks for being there with us.
                <br>
                <br>
                <button nz-button nzType="primary" (click)="handleCancel()">Close</button>
              </div>
            </nz-result>

          </div>

        </div>

      </div>

    </div>
  </ng-container>
</nz-modal>

<nz-modal [(nzVisible)]="isVisible" nzTitle="Buy Additional Service" nzWidth="60%" [nzFooter]='null' (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
  <ng-container *nzModalContent>
    <div nz-row>
      <div nz-col nzSpan="24">
        <div class="social-connect-container">
          <div class="social-headings">
            <p>Schedule a time for your Account Manager to call and upgrade your plan for you!</p>
          </div>
          <div>
            <div #container class="calendly-inline-widget" style="min-width:320px;height:700px;" data-auto-load="false"></div>
          </div>
        </div>
      </div>

    </div>
  </ng-container>
</nz-modal>
