import {ChangeDetectionStrategy, Component, OnInit, Input, ElementRef, ViewChild, AfterViewInit, inject} from '@angular/core';
import {NZ_MODAL_DATA, NzModalRef} from 'ng-zorro-antd/modal';
import { IModalData } from 'src/app/models/interfaces/modals/modal';
import {PostsService} from '../../services/posts/posts.service';
import {NzNotificationService} from 'ng-zorro-antd/notification';
declare  const Calendly: any;
@Component({
  selector: 'app-post-edit-dialog',
  templateUrl: './post-edit-dialog.component.html',
  styleUrls: [ './post-edit-dialog.component.scss' ],
  changeDetection: ChangeDetectionStrategy.Default
})

export class PostEditDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('caption', { static: false }) captionInput: ElementRef;
  @Input()
  data;
  hashtags;
  isHashtagLimit;
  showEmojiPicker = false;
  set = 'twitter';
  readonly #modal = inject(NzModalRef);
  readonly nzModalData: IModalData = inject(NZ_MODAL_DATA);
  constructor(
    private modal: NzModalRef,
    private postsService: PostsService,
    private notification: NzNotificationService,
  ) {}

  ngAfterViewInit(): void {
  }
  ngOnInit() {
    this.data = this.nzModalData;
    if (this.data.caption){
      this.hashtags = this.data.caption.match(/#\S+/g) || [];
    }
  }
  removeDialog() {
    this.modal.close();

  }
  handleSave() {
    this.postsService.update({caption: this.data.caption, id: this.data.id}, false).subscribe(resp=>{
      this.notification.create(
        'success',
        '',
        'Caption updated successfully!'
      );
      this.modal.close();
    });

  }
  onInput(value: string): void {
    this.hashtags = value.match(/#\S+/g) || [];
    if (this.hashtags.length > 30) {
      this.isHashtagLimit = true;
    } else {
      this.isHashtagLimit = false;
    }
  }
  handlePreventDefault(evt){
    evt = (evt) ? evt : window.event;
    if (this.hashtags.length >= 30 && evt.key === '#'){
      evt.preventDefault();
    }
  }
  toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
  }
  addEmoji(event) {
    const { caption = '' } = this.data;
    const text = `${caption}${event.emoji.native}`;
    this.data.caption = text;
    this.captionInput.nativeElement.focus();
    this.captionInput.nativeElement.click();
    this.showEmojiPicker = false;
  }
}
