import { Component, OnInit } from '@angular/core';
import {BreadCrumService} from '../../services/breadcrum/bread-crum.service';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { LeadsService } from '../../services/leads/leads.service';
import { Customer } from '../../vos/customer/customer';
import { AudiencesService } from '../../services/audiences/audiences.service';
import {NzTabChangeEvent} from 'ng-zorro-antd/tabs';

@Component({
  selector: 'app-audiences',
  templateUrl: './audiences.component.html',
})
export class AudiencesComponent implements OnInit {
  selectedIndex: number = 0; // default to the first tab
  constructor(
    private breadcrumService: BreadCrumService,
    private route: ActivatedRoute

    // private audienceService: AudiencesService
  ) { }

  ngOnInit() {
    this.breadcrumService.set_breadcrum();
    this.breadcrumService.set_breadcrum();

    // Check fragment and update selected tab
    this.route.fragment.subscribe(fragment => {
      if (fragment === 'leads') {
        this.selectedIndex = 2; // Set to "Leads" tab
      } else if (fragment === 'contacts') {
        this.selectedIndex = 1; // Set to "Contacts" tab
      } else {
        this.selectedIndex = 0; // Default to "All" tab
      }
    });
  }

  onTabChange(event): void {
    // Optional: Update the fragment based on the selected tab
    const tabMap = ['all', 'contacts', 'leads'];
    window.location.hash = tabMap[event]; // Update URL fragment
  }
}
