<!-- <app-sidebar> -->
<div class="container">
  <div>
    <div nz-row>
      <div nz-col nzSpan="6">
        <div class="search push-right">
          <nz-input-group [nzSuffix]="suffixIconSearch">
            <input type="text" nz-input placeholder="Search Leads" [(ngModel)]="searchText" (ngModelChange)='searchModelChanged.next($event)' />
          </nz-input-group>
          <ng-template #suffixIconSearch>
            <i nz-icon nzType="search"></i>
          </ng-template>
        </div>
      </div>
      <div nz-col nzSpan="6">
        <div class="leads-actions">
          <button nz-button nzType="primary" (click)="exportLeads();" class="push-right">Export Leads</button>
          <button class="left-spacing" nz-button nzType="primary" *ngIf="selectedExportLeads && selectedExportLeads.length" (click)="exportSelected();" class="push-right">Export Selected Leads ({{selectedExportLeads.length}})</button>
        </div>
      </div>
    </div>
<!--  <nz-spin [nzSize]="'large'">-->
    <ng-container [ngTemplateOutlet]="leadslisting"></ng-container>
<!--  </nz-spin>-->
<!--  <ng-template #notloading>-->
<!--  <ng-container [ngTemplateOutlet]="leadslisting"></ng-container>-->
<!--  <nz-empty nzNotFoundContent="You haven’t created any lead yet" *ngIf="leadsDataSource && !leadsDataSource.length" class="no-content"></nz-empty>-->
<!--  </ng-template>-->
  <div nz-row nzType="flex" nzJustify="end" style="margin-top: 30px;" *ngIf="!loading">
    <nz-pagination [nzPageIndex]="page" [nzTotal]="pageInfo.total_entries" nzPageSize="10" [nzShowTotal]="rangeTemplate" (nzPageIndexChange)="didPage($event)"></nz-pagination>
    <ng-template #rangeTemplate let-range="range" let-total>
      {{ range[0] }}-{{ range[1] }} of {{ total }} items
    </ng-template>
  </div>
  <ng-template #leadslisting>
    <nz-table nzNoResult="" nzTemplateMode>
      <thead (nzSortOrderChange)="sort($event)">
        <tr class="ads-hd-text-style">
          <th [nzChecked]="checked" [nzIndeterminate]="indeterminate" (nzCheckedChange)="onAllChecked($event)"></th>
          <th class="text-center">Conversation</th>
          <th class="text-center" nzShowSort nzColumnKey="first_name" [nzSortFn]="mapOfSort.first_name">First Name</th>
          <th class="text-center" nzShowSort nzColumnKey="last_name" [nzSortFn]="mapOfSort.last_name">Last Name</th>
          <th class="text-center" nzShowSort nzColumnKey="phone" [nzSortFn]="mapOfSort.phone">Phone</th>
          <th class="text-center" nzShowSort nzColumnKey="email" [nzSortFn]="mapOfSort.email">Email</th>
          <th class="text-center" nzShowSort nzColumnKey="source" [nzSortFn]="mapOfSort.source">Source</th>
          <!-- <th>Type</th>
          <th>Timeline</th>
          <th>Address</th> -->
          <th class="text-center" nzShowSort nzColumnKey="created_at" [nzSortFn]="mapOfSort.created_at">Created on</th>
          <th class="text-center">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let lead of leadsDataSource">
          <td
          [nzChecked]="setOfCheckedId.has(lead.id)"
          (nzCheckedChange)="onItemChecked(lead.id, $event)"></td>
          <td class="text-center">
            <button nz-button nzType="primary" nzShape="circle" (click)="createConversation(lead.id);"><span nz-icon nzType="wechat"></span></button>
          </td>
          <td class="ads-text-style text-center">
            {{lead.first_name}}
          </td>
          <td class="ads-text-style text-center">
            {{lead.last_name}}
          </td>
          <td class="ads-text-style text-center">
            {{lead.phone}}
          </td>
          <td class="ads-text-style text-center">
            {{lead.email}}
          </td>
          <td class="ads-text-style text-center">
            {{ lead.source ? lead.source.capitalize() : ''}}
          </td>

          <!-- <td class="ads-text-style">
            {{ lead.lead_type ? lead.lead_type.capitalize() : ''}}
          </td>
          <td class="ads-text-style">
            {{ lead.transaction_in ? lead.transaction_in.capitalize() : '' }}
          </td>
          <td class="ads-text-style">
            {{ lead.address ? lead.address.capitalizeAll() : '' }}
          </td> -->
          <td class="text-center">
            {{lead.created_at | date : "MM-dd-y"}}
          </td>
          <td class="text-center">
            <i (click)="deleteReport(lead)" nz-icon nzType="delete" nzTheme="twotone" class="delete-contact"></i>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </ng-template>
  </div>
</div>
<!-- </app-sidebar> -->
