<div class="container">
  <ng-container *ngTemplateOutlet="listin"></ng-container>
  <div class="empty-listings" *ngIf="!search && testimonies && !testimonies.length && !loading">
    <img src="../../../assets/images/listings-empty.png" />
    <!-- <h2 class="create-title mar-zero">Promote your Market Reports with Style!</h2>
    <p class="sub-title">After selecting what you want to make, pick your market report, and customize the content
      before it gets generated for you.
    </p> -->
  </div>
</div>
<ng-template #listin>
  <!-- <div class="search" *ngIf="testimonies">
    <nz-input-group [nzSuffix]="suffixIconSearch">
      <input type="text" nz-input placeholder="input search text" [ngModel]="searchText"
        (ngModelChange)='searchModelChanged.next($event)' />
    </nz-input-group>
    <ng-template #suffixIconSearch>
      <i nz-icon nzType="search"></i>
    </ng-template>
  </div> -->
  <nz-table [nzLoading]="loading" nzNoResult="No Market Report found!" nzTemplateMode>
    <thead (nzSortOrderChange)="sort($event)">
    <tr>
      <th nzShowSort nzColumnKey="attestant_name" [(nzSortFn)]="mapOfSort.region_name">
        Attestant's Name</th>
      <th>Copy
        <!-- <span nz-tooltip nzTooltipTitle="This is the market the will be used for automatic posts" class="float-icon">
          <i nz-icon nzType="info-circle" nzTheme="outline" class="info-icon"></i>
        </span> -->
      </th>
      <th nzShowSort nzColumnKey="created_at" [(nzSortFn)]="mapOfSort.created_at">Created At</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let testimon of testimonies" class="cursor-pointer" (click)="selectedTestimonial(testimon)">
      <td>
        <nz-avatar nzSize="large" nzIcon="user" [nzSrc]="testimon?.image?.original"></nz-avatar>
        <a class="user-name" style="padding-left: 5px">  {{testimon.attestant_name}}</a>
      </td>

      <td>
        {{testimon.copy}}
      </td>

      <td>{{ testimon.created_at | date : "MM-dd-y"}}</td>
    </tr>
    </tbody>
  </nz-table>
  <br>
  <br>
  <div fxLayout="row" fxLayoutAlign="end start" class="create-cont">
    <nz-pagination class="pagination-bottom" *ngIf="testimonies && testimonies.length"
                   [nzPageIndex]="ReportsPagination.page" [nzTotal]="ReportsPagination.total" (nzPageIndexChange)="didPage($event)">
    </nz-pagination>
  </div>
</ng-template>
