import {
  Component,
  OnInit
} from '@angular/core';
import { NotificationService } from '../../services/notification/notification.service';
import {AuthenticationService} from '../../services/authentication/authentication.service';
import { Router, NavigationEnd } from '@angular/router'; 
import { filter } from 'rxjs/operators';
import { GlobalsService } from 'src/app/services/globals/globals.service';
import { ProductsService } from 'src/app/services/products/product-api.service';
import { CardButtonConfig } from 'src/app/models';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  loading = false;
  isVisible:boolean = false;
  isShowInHome = false;
  products: CardButtonConfig[];
  customer;
  constructor(
    private notificationService: NotificationService,
    private authService: AuthenticationService,
    private router: Router,
    private globalsService: GlobalsService, 
    private productsService: ProductsService,
  ) { }
  ngOnInit() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    if (userAgent?.indexOf('chrome') === -1 && !userAgent?.match('CriOS')) {
      this.showModal();
    }
    this.authService.currentCustomer.subscribe((c) => {
      if (c && c.settings.show_price === undefined) {
        c.settings.show_price = true;
      }
      if (c) {
        this.customer = c;
      }
    });
    const params: Record<string, any> = {
      per_page: 40,
    };

    this.productsService.productList(`customers/${this.customer?.id}/products`, params).subscribe(
      (res) => {
        if (res) {
          this.products = res.data;
          const googleProduct = res.data ? res.data?.find((p) => p.abbreviation === 'GMB' && p.locked === false) : null;
    
          if (googleProduct && googleProduct != null) {
            this.globalsService.setIsGMBAllowed(true);
          } else {
            this.globalsService.setIsGMBAllowed(false);
          }
        }
      }
    );
    
    this.showInHome();
  }

  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    this.isVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
  }
  showInHome(){
    if(this.router.url === '/') {
      this.isShowInHome = true;
    }
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      if (event.url === '/') {
        this.isShowInHome = true;
      }
      else if (event.url !== '/') {
        this.isShowInHome = false;
      }
    });
  }

  get showGMB() {
    return this.globalsService.getIsGMBAllowed();
  }
}
