import moment from 'moment';
import { Resource } from '../resource/resource';
import {Customer} from '../customer/customer';

export class Content extends Resource {
  // }
  /**
   * id
   */
  id?: number;

  contentable_to_render: any;

  /**
   * caption
   */
  caption: string;

  /**
   * url
   */
  url: string;

  /**
   * status
   */
  status: string;

  /**
   * posted_at
   */
  posted_at?: moment.Moment;

  posted_on?: any;

  /**
   * customer_id
   */
  customer_id: number;

  /**
   * created_at
   */
  created_at?: moment.Moment;

  /**
   * updated_at
   */
  updated_at?: moment.Moment;

  scheduled_for?: any;
  last_post?:any;
  /**
   * category
   */
  category: string;

  /**
   * style
   */
  style?: string;

  /**
   * bg_color
   */
  bg_color?: string;

  /**
   * text_color
   */
  text_color?: string;
  selected?: boolean;

  /**
   * Content Reference Type
   */
  contentable_type: string;

  /**
   * Id of the referenced contentable object
   */
  contentable_id: number;

  media_type: string;

  thumbnail?: string;

  cta_link?: string;

  ad_id?: string;
  sheet_id?: string;
  headline?: string;
  description?: string;
  ad_set_id?: string;
  ad_creative_id?: string;
  is_template: boolean;
  title?: string;
  contentable?: any;
  display_name?: string;
  extra_attributes: any;
  platforms?: any;
  is_style: any;
  ratio?: string;
  aspect_ratio: string;
  styleEffectiveName?:string;
  customer_content_calendar_style_id?:string;
  get display_type(): string {
    switch (this.category) {
      case 'LV':
      case 'CG':
      case 'OH':
      case 'DV':
      case 'MK':
      case 'VB':
        return 'video';
      case 'LF':
      case 'IF':
        return 'image';
      default:
        return 'unknown';
    }
  }
  get fileext(): string {
    if (!this.url) {
      return '';
    }
    const urlEls = this.url.split('.');
    return urlEls[ urlEls.length - 1 ];
  }
  get filename(): string {
    return `${this.category}_${this.contentable_type}_${moment().format('MMDDYYYY')}.${this.fileext}`;
  }
  constructor(vals?) {
    super(vals);
    const customerDetails = window?.localStorage?.getItem('customer');
    const custDetails = JSON.parse(customerDetails);
    const customer = new Customer(custDetails);
    this.bg_color = customer.settings['bg_color'];
    this.text_color = customer.settings['text_color'];
  }

}
