<div nz-row>
  <div class="edit-form-container">
    <div nz-col nzSpan="24">
      <span nz-typography>Description/Caption: </span>
      <h5 class="section-description">This is the text that will be included in your post, should you choose to
        post
        this video or flyer.</h5>
      <nz-form-item appearance="fill" nz-col [nzSm]="24" [nzXs]="24" [nzMd]="24" class="caption-item top-spacing">
        <nz-form-control [nzSm]="24" [nzXs]="24" class="margin-zero">
                  <textarea #caption nz-input class="form-control caption-input-area" id="caption"
                            [(ngModel)]="data.caption" name="caption" required maxlength="2200"
                            (ngModelChange)="onInput($event)" (keypress)="handlePreventDefault($event)" [nzAutosize]="{ minRows: 3, maxRows: 4 }"></textarea>
        </nz-form-control>
        <span class="remaning">{{data.caption?.length ? data.caption?.length : 0}} / 2200</span>
        <div style="color: red;" *ngIf="data.caption?.length >= 2200">
          Caption must not exceed 2200 characters.
        </div>
        <div style="color: red;" *ngIf="isHashtagLimit">
          Hashtag Limit Exceeded: You've reached the maximum allowed number of hashtags. Limit: 30 hashtags
        </div>
        <button nz-dropdown nz-button nzTrigger="click" class="align-right" nzType="text" nzBlock
                [(nzVisible)]="showEmojiPicker" [nzDropdownMenu]="menu" nzPlacement="bottomRight"
                (click)="toggleEmojiPicker()">
          😀
        </button>
        <div class="hashtag">
          <span># {{ hashtags?.length ? hashtags?.length : 0}} / 30</span>
        </div>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <emoji-mart class="emoji-mart" set="{{set}}" isNative="false" (emojiSelect)="addEmoji($event);" title="Pick your emoji…">
          </emoji-mart>
        </nz-dropdown-menu>
      </nz-form-item>
<!--      <div>-->
<!--        If you are planning to post to Google, please do not write out phone numbers in your captions. Google will reject the post.-->
<!--      </div>-->
    </div>
  </div>
<!--  <nz-divider></nz-divider>-->
</div>
<div>
  <div nz-row nzType="flex" nzJustify="space-between" class="actions">
    <button nz-button nzType="default" class="action-btn-primary" (click)="removeDialog();">Close</button>
    <button nz-button nzType="primary" class="action-btn-primary" (click)="handleSave()">Update</button>
  </div>
</div>
