import { BrowserModule } from '@angular/platform-browser';
declare module '@angular/core' {
  interface ModuleWithProviders<T = any> {
    ngModule: Type<T>;
    providers?: (Provider | EnvironmentProviders)[];
  }
}
import { FullCalendarModule } from "@fullcalendar/angular";

import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core'
import { NZ_ICONS } from 'ng-zorro-antd/icon';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { DemoNgZorroAntdModule } from './ng-zorro-antd.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PdfViewerModule } from 'ng2-pdf-viewer'; // <- import PdfViewerModule

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { MarketReportFormModalComponent } from './components/market-reports/market-report-form-modal/market-report-form-modal.component';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpTokenInterceptor } from './services/jwt/http.token.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { JwtService } from 'src/app/services/jwt/jwt.service';
import { CustomersService } from './services/customers/customers.service';
import { CustomersFacebookService } from './services/customers-facebook/customers-facebook.service';
import { CustomersGoogleService } from './services/customers-google/customers-google.service';
import { CustomersYoutubeService } from './services/customers-youtube/customers-youtube.service';
import { CustomersTiktokService } from './services/customers-tiktok/customers-tiktok.service';

import { GlobalErrorHandler } from './global-error-handler';

import { ListingsService } from './services/listings/listings.service';
import { ContentsService } from './services/contents/contents.service';
import { CmaService } from './services/cma/cma.service';
import { InsightsService } from './services/insights/insights.service';
import { SubscriptionService } from './services/subscriptions/subscriptions.service';
import { MarketReportsService } from './services/market-reports/market-reports.service';
import { TestimonyService } from './services/testimonies/testimonies.service';

import { NotificationService } from './services/notification/notification.service';
import { AdsService } from './services/ads/ads.service';
import { StylesService } from './services/styles/styles.service';
import { TeamMembersService } from './services/team-members/team-members.service';
import { QrcodeService } from './services/qrcode/qrcode.service';
import { SociallinksService } from './services/social-links/social-links.service';
import { PixelModule } from 'ngx-multi-pixel';
import {SocialLinksDialogComponent} from './shared/social-links-dialog/social-links-dialog.component';
import { NgxStripeModule } from 'ngx-stripe';


// email services

import { EmailCampaignService } from './services/email-campaign/email-campaign.service';
import { EmailStatsService } from './services/email-stats/email-stats.service';



import { EmailContactListService } from './services/email-contact-list/email-contact-list.service';
import { NpsScoreService } from './services/nps-score/nps-score.service';
import { ReferralsService } from './services/referrals/referrals.service';
import { EmailDomainService } from './services/email-domain/email-domain.service';
import { EmailTemplateService } from './services/email-template/email-template.service';
import { EmailUserService } from './services/email-user/email-user.service';
import { EmailSenderIdentityService } from './services/email-sender-identity/email-sender-identity.service';

import { ActionCableService } from 'angular2-actioncable';

import { CardButtonModule } from './modules/card-button/card-button.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { NumberInputFormatterPipe } from './pipes/formatting/number-input-formatter.pipe';
import { DurationPipe } from './pipes/time/duration.pipe';
import { ScrollDispatcher, ScrollingModule } from '@angular/cdk/scrolling';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { WindowService } from './services/window/window.service';

// Import Bugsnag and the Angular integration
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'

// import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
// import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { AppGuard } from './app.guard';
import { LoginGuard } from './components/login/login.guard';
import { OnboardingGuard } from './components/registration-form/onboarding.guard';
import { ContentPreviewDialogComponent } from './shared/content-preview-dialog/content-preview-dialog.component';
import { AnnouncementPreviewDialogComponent } from './shared/announcement-preview-dialog/announcement-preview-dialog.component';
import { FacebookOnboardingDialogComponent } from './shared/facebook-onboarding-dialog/facebook-onboarding-dialog.component';
import { LinkedinOnboardingDialogComponent } from './shared/linkedin-onboarding-dialog/linkedin-onboarding-dialog.component';
import { NpsScoreDialogComponent } from './shared/nps-score-dialog/nps-score-dialog.component';
import { YoutubeOnboardingDialogComponent } from './shared/youtube-onboarding-dialog/youtube-onboarding-dialog.component';
import { TiktokOnboardingDialogComponent } from './shared/tiktok-onboarding-dialog/tiktok-onboarding-dialog.component';
import { ContentPreviewCarouselComponent } from './shared/content-preview-carousel/content-preview-carousel.component';
import { PdfViewerDialogComponent } from './shared/pdf-viewer-dialog/pdf-viewer-dialog.component';
// import { GoogleOnboardingDialogComponent } from './shared/google-onboarding-dialog/google-onboarding-dialog.component';

import { ImageCropperDialogComponent } from './shared/image-cropper-dialog/image-cropper-dialog.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { ContactsUploaderComponent } from './shared/contacts-uploader/contacts-uploader.component';

import { NotificationTabComponent } from './shared/notification-tab/notification-tab.component';
import { DashboardNotificationComponent } from './components/dashboard-notification/dashboard-notification.component';

import { BreadCrumComponent } from './shared/bread-crum/bread-crum.component';
import { InsightsAnalyticsComponent } from './shared/insights-analytics/insights-analytics.component';

import { HomeComponent } from './components/home/home.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { TermsComponent } from './components/terms/terms.component';
import { GenericDialogComponent } from './shared/generic-dialog/generic-dialog.component';
import { NumberFormatterDirective } from './pipes/formatting/number-formatter.directive';
import { UsericonComponent } from './shared/usericon/usericon.component';
import { UpgradeDialogComponent } from './shared/upgrade-dialog/upgrade-dialog.component';
import { ListingImageGalleryComponent } from './shared/listing-image-gallery/listing-image-gallery.component';
import { FacebookStepsComponent } from './components/facebook-steps/facebook-steps.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { SocialPostDialogComponent } from './shared/social-post-dialog/social-post-dialog.component';
import { ContentReceiptDialogComponent } from './shared/content-receipt-dialog/content-receipt-dialog.component';
import { SharedModule } from './shared/shared.module';
import { LoginComponent } from './components/login/login.component';
import { MagicLoginComponent } from './components/magic-login/magic-login.component';
import { MagicLoginLinkComponent } from './components/magic-login-link/magic-login-link.component';
import { ContentApprovalModalComponent } from './shared/content-approval-modal/content-approval-modal.component';
import { CreateContentComponent } from './components/create-content/create-content.component';
import { SelectCreateContentComponent } from './components/select-create-content/select-create-content.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';

import { RoiReportsComponent } from './shared/roi-reports/roi-reports.component';
import { ContactsListComponent } from './components/contacts-list/contacts-list.component';

import * as AllIcons from '@ant-design/icons-angular/icons';
import { IconDefinition } from '@ant-design/icons-angular';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction';
import {AccountMembersService} from './services/account-members/account-members.service'; // a plugin
import { LandingPageService } from './services/landing-page/landing-page.service';
import { Type } from '@angular/core';
import { Provider } from '@angular/core';
import { EnvironmentProviders } from '@angular/core';
// configure Bugsnag
// Bugsnag.start({
//   apiKey: environment.bugSnagApiKey,
//   enabledReleaseStages: ['production'],
//   enabledErrorTypes: {
//     unhandledExceptions: false,
//     unhandledRejections: true
//   }
// })


// create a factory which will return the Bugsnag error handler
export function errorHandlerFactory() {
  return new BugsnagErrorHandler()
}

const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key])
// @ts-ignore
@NgModule({
  declarations: [
    // AppComponent,
    // SidebarComponent,
    // MarketReportFormModalComponent,
    // BreadCrumComponent,
    // InsightsAnalyticsComponent,
    // DashboardComponent,
    // TermsComponent,
    // GenericDialogComponent,
    // FacebookStepsComponent,
    // NumberFormatterDirective,
    // NotFoundComponent,
    // UsericonComponent,
    // ContentPreviewDialogComponent,
    // ContentPreviewCarouselComponent,
    // AnnouncementPreviewDialogComponent,
    // FacebookOnboardingDialogComponent,
    // LinkedinOnboardingDialogComponent,
    // NpsScoreDialogComponent,
    // SocialLinksDialogComponent,
    // YoutubeOnboardingDialogComponent,
    // TiktokOnboardingDialogComponent,
    // PdfViewerDialogComponent,
    // GoogleOnboardingDialogComponent,
    // ContactsUploaderComponent,
    // SocialPostDialogComponent,
    // HomeComponent,
    // UpgradeDialogComponent,
    // ListingImageGalleryComponent,
    // ContentReceiptDialogComponent,
    // LoginComponent,
    // MagicLoginLinkComponent,
    // MagicLoginComponent,
    // DurationPipe,
    // ContentApprovalModalComponent,
    // NotificationTabComponent,
    // DashboardNotificationComponent,
    // CreateContentComponent,
    // SelectCreateContentComponent,
    // RoiReportsComponent,
    // ContactsListComponent,
    // LandingPageComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    FullCalendarModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DemoNgZorroAntdModule,
    ColorPickerModule,
    ScrollingModule,
    InfiniteScrollModule,
    CardButtonModule,
    PdfViewerModule,
    NgxStripeModule.forRoot('pk_test_51Q3HkEIPfVxUKEVtJ1lH5uefiC4nQfms37srvIhmMt9D8eXr8FWNlT1s185eD09L5JwLDVI50QgqSwRdZFlT9l6u008Sfbew75'),
    PixelModule.forRoot({ enabled: environment.production, pixelId: ['148384942529359'] }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA] ,
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    // { provide: ErrorHandler, useFactory: errorHandlerFactory, useClass: GlobalErrorHandler },
    AuthenticationService,
    JwtService,
    NumberInputFormatterPipe,
    DurationPipe,
    ScrollDispatcher,
    AppGuard,
    OnboardingGuard,
    LoginGuard,
    WindowService,
    CustomersService,
    CustomersFacebookService,
    CustomersGoogleService,
    CustomersYoutubeService,
    CustomersTiktokService,
    SubscriptionService,
    NotificationService,
    ActionCableService,
    ListingsService,
    ContentsService,
    InsightsService,
    CmaService,
    MarketReportsService,
    TestimonyService,
    AdsService,
    StylesService,
    EmailCampaignService,
    EmailStatsService,
    EmailContactListService,
    NpsScoreService,
    ReferralsService,
    EmailDomainService,
    EmailTemplateService,
    EmailUserService,
    EmailSenderIdentityService,
    TeamMembersService,
    AccountMembersService,
    QrcodeService,
    SociallinksService,
    LandingPageService,
    // { provide: DateAdapter, useClass: MomentDateAdapter },
    // { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: NZ_I18N, useValue: en_US }, { provide: NZ_ICONS, useValue: icons },
    {
      provide: 'FULL_CALENDAR_OPTIONS',
      useValue: {
        plugins: [ dayGridPlugin, interactionPlugin ]
      }
    }
  ],
  bootstrap: [AppComponent]
})
/**
 * Main app module. Import your submodules here.
 */
export class AppModule {
  constructor() {
    // Add an icon to the library for convenient access in other components
    // config.fallbackIcon = faBan;
  }
}
