<div class="container">
  <div class="title-header" *ngTemplateOutlet="TestimonialContent"></div>
  <form #testimonialForm="ngForm" nz-form>


    <div class="steps-content">
      <!-- <h2 class="type-title">Testimony</h2> -->

      <div nz-row nzType="flex" class="mg-t-20">
        <div class="image-card-wrapper" nz-col nzSpan="10" [nzMd]="10" [nzSm]="11" [nzXs]="24">
          <div class="label-text"><span>{{requiredFields.includes('familyphoto') ? ' *' : ''}}</span> Add Attestant's
            Photo:</div>
          <span class="label-text-sm">A photo of a person providing the testimonial.</span>
          <app-image-handler [imageable_type]="'attestant_photo'" [content]="content" [config]="attestantPhotoConfig"
            (imageObject)="setFamilyImage($event)">
          </app-image-handler>
        </div>
        <div nz-col nzSpan="10" [nzMd]="10" [nzSm]="12" [nzXs]="24">
          <nz-form-item>
            <nz-form-label class="text-left label-text" [nzSm]="24" [nzXs]="24" for="zoning"
              [nzRequired]="requiredFields.includes('FamilyName')">Attestant's Name
            </nz-form-label>
            <nz-form-control [nzSm]="24" [nzXs]="24">
              <input nz-input type="text" [(ngModel)]="testimony.attestant_name" class="form-control" id="attestant_name"
                name="attestant_name" [required]="requiredFields.includes('attestant_name')" maxlength="70">
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label class="text-left label-text" [nzSm]="24" [nzXs]="24" for="description"
              [nzRequired]="requiredFields.includes('Message')">Message</nz-form-label>
            <nz-form-label [nzSm]="24" [nzXs]="24" class="sub-text">Everything written in the box below will be wrapped
              in "quotations".</nz-form-label>
            <nz-form-control [nzSm]="24" [nzXs]="24" class="textarea-wrapper">
              <textarea nz-input type="text" class="form-control" id="copy" [(ngModel)]="testimony.copy"
                name="copy" [required]="requiredFields.includes('copy')" maxLength="250"
                (ngModelChange)="valueChange($event)"></textarea>
              <span class="remaning">{{textLength ? textLength : 0}} / 250</span>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-row nzType="flex" nzJustify="end">
        <!-- <button nz-button nzType="primary" class="right-spacing" (click)="back()">Back</button> -->
        <button nz-button nzType="primary" class="next-button" (click)="saveData()" [disabled]="!valid">
          Save
        </button>
      </div>
    </div>
  </form>

</div>


<ng-template #TestimonialContent>
  <h1 class="section-title title">
    <!-- <i nz-icon nzType="layout"></i> -->
    Testimonial
  </h1>
</ng-template>
