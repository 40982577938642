<!-- <app-sidebar> -->
<div *ngIf="sliderView == false">
  <!-- <span>Video Type: </span>
    <nz-tag *ngFor="let filter of contentFiltersAvailable" nzMode="checkable" [nzChecked]="contentFilters.indexOf(filter) > -1"
            (nzCheckedChange)="toggleFilter($event, filter)">
      {{filter.capitalize()}}
    </nz-tag> -->
  <!-- <nz-divider></nz-divider> -->
  <nz-spin [nzSize]="'large'" *ngIf="loading"></nz-spin>
  <div nz-row nzGutter="16" nzJustify="start">
    <div *ngFor="let tip of marketingTips" class="tip-card" nz-col nzSpan="4" nzXs="24" nzSm="12" nzMd="8" nzLg="4" nzXl="4">
      <nz-card nzHoverable  [nzCover]="coverTemplate" (click)="openPdf(tip.url)" >
        <nz-card-meta [nzTitle]="titleTemplate" [nzDescription]="(tip.description.length>100)? (tip.description | slice:0:100)+'...':(tip.description) "></nz-card-meta>
      </nz-card>
      <ng-template #titleTemplate>
        <p class="tip-date">{{tip.created_at | date : "MMMM d, y"}}</p>
        <div class="title-content">{{tip.name}}</div>
      </ng-template>
      <ng-template #coverTemplate>
        <img #imageThumbnail alt="example" [src]="tip.thumbnail" />
      </ng-template>
    </div>
  </div>

  <div class="pagination" nz-row nzJustify="center" *ngIf="pageInfo">
    <nz-pagination [nzPageIndex]="page" [nzTotal]="pageInfo.total_entries" [nzShowTotal]="rangeTemplate"
      (nzPageIndexChange)="didPage($event)"></nz-pagination>
    <ng-template #rangeTemplate let-range="range" let-total>
      {{ range[0] }}-{{ range[1] }} of {{ total }} items
    </ng-template>
  </div>
</div>

<div *ngIf="sliderView == true">
  <div class="title-header">
    <h1 class="section-title title">
      <img class="ellipsis-vertical" src="../../../assets/icons/tips.svg">
      Marketing Tips
    </h1>
    <button nz-button nzType="primary" [routerLink]="['/tips']" class="view-all-btn pull-right">
      View All
    </button>
  </div>
  <nz-divider nzType="horizontal"></nz-divider>
  <div nz-row nzGutter="16" nzJustify="start">
    <div *ngFor="let tip of marketingTips | slice:0:3" class="tip-card" nz-col nzSpan="4" nzXs="24" nzSm="12" nzMd="8" nzLg="4" nzXl="4">
      <nz-card nzHoverable  [nzCover]="coverTemplate" (click)="openPdf(tip.url)">
        <nz-card-meta [nzTitle]="titleTemplate" [nzDescription]="(tip.description.length>100)? (tip.description | slice:0:100)+'...':(tip.description) "></nz-card-meta>
      </nz-card>
      <ng-template #titleTemplate>
        <p class="tip-date">{{tip.created_at | date : "MMMM d, y"}}</p>
        <div class="title-content">{{tip.name}}</div>
      </ng-template>
      <ng-template #coverTemplate>
          <img alt="example" [src]="tip.thumbnail" />
      </ng-template>
    </div>
  </div>
</div>
