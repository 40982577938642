import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  ValidationErrors,

} from '@angular/forms';
import { GlobalsService } from '../../services/globals/globals.service';
import { AiTemplateService } from '../../services/ai-template/ai-template.service'
import { NzModalService } from 'ng-zorro-antd/modal';
import { ListingDialogComponent } from 'src/app/shared/listing-dialog/listing-dialog.component';
import { AutomatedCampaignsDialogComponent } from 'src/app/shared/automated-campaigns-dialog/automated-campaigns-dialog.component';
import { ListingsService } from 'src/app/services/listings/listings.service';
import { Listing } from 'src/app/vos/listing/listing';
import { CampaignService } from 'src/app/services/campaigns/campaign.service';
import { ActivatedRoute, Router } from '@angular/router';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import {AuthenticationService} from '../../services/authentication/authentication.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import moment from 'moment-timezone';
import { Customer } from 'src/app/vos/customer/customer';
import { IModalData } from 'src/app/models/interfaces/modals/modal';
import {SmsTemplatesService} from '../../services/sms-templates/sms-templates.service';
import {TestimonialsDialogComponent} from '../../shared/testimonials-dialog/testimonials-dialog.component';
import {Testimony} from '../../vos/testimony/testimony';

@Component({
  selector: 'app-campaign-templates',
  templateUrl: './campaign-templates.component.html',
  styleUrls: ['./campaign-templates.component.scss']
})
export class CampaignTemplatesComponent implements OnInit {
  listings: Listing[] = [];
  templates = [];
  @Output() responseChange = new EventEmitter<boolean>();
  @Output() handleResponse = new EventEmitter<any>();
  @Output() retry = new EventEmitter<any>();
  @Input() valid = false;
  @Output() errors = new EventEmitter<ValidationErrors>();
  hasError: { [key: string]: boolean } = {};
  @Input() requiredFields = [
    'campaign_name',
    'start_date',
  ];
  @Input() showFields = [
    'template',
    'campaign_name',
    'start_date',
    'start_time',
  ];

  selectedTemplate: any;
  selectedTemplateName: any;
  response: string;
  loading = false;
  selectedListing: Listing;
  selectedTestiomonial =  new Testimony();
  startDateTime: String;
  start_time: Date;
  errorMsg: String;
  customer: Customer;
  selectedAddress: any;
  oh_start_time: Date;
  oh_end_time: Date;
  isAutomatedCampaign: Boolean;

  @Input() limitShow = false;
  @Input()  model: any = {
    template: null,
    campaign_name: '',
    start_date: '',
    response: '',
    oh_start_date: '',
  };

  disabledHours(): number[] {
    if (this.model && this.model.start_date) {
      return this.model.start_date && new Date(this.model.start_date).getDate() - new Date().getDate() < 1
      ? this.range(1, new Date().getHours() + 12) : [];
    }
  }
  disabledDate = (current: Date): boolean => {
    return current && differenceInCalendarDays(current, new Date()) < 0;
  };


  range(start: number, end: number): number[] {
    const result: number[] = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  constructor(
    private globalsService: GlobalsService,
    private modalService: NzModalService,
    private listingService: ListingsService,
    private campaignService: CampaignService,
    private router: Router,
    private route: ActivatedRoute,
    private message: NzMessageService,
    private authService: AuthenticationService,
    private smsTemplatesService: SmsTemplatesService,
    ) {
    this.templates = this.globalsService.getListingTemplates();
  }


  ngOnInit(): void {
    this.getTemplates();
    this.customer = this.authService.currentCustomerValue;
    const campaignId = this.route.snapshot.paramMap.get('id');
    if (campaignId) {
      this.campaignService.get_sms_campaign(campaignId).subscribe(res => {
        this.model.campaign_name = res.name;
        this.model.response = res.template;
        this.model.template = res.template_type;
        this.selectedTemplate = res.template_type;
        this.isAutomatedCampaign = res.is_automated_campaign;
        const startDate = moment(res.start_date_time, moment.ISO_8601);
        this.model.start_date = startDate.format('MM-DD-YYYY');

        const parsedTime = moment(res.start_date_time, moment.ISO_8601);
        this.start_time = parsedTime.toDate();
        if (this.start_time && this.model.start_date) {
          this.model.start_date = new Date(this.model.start_date);
          this.start_time.setDate(this.model.start_date.getDate());
          this.start_time.setMonth(this.model.start_date.getMonth());
          this.start_time.setFullYear(this.model.start_date.getFullYear());
          const tempDate = moment(this.start_time)
          this.startDateTime = `${tempDate.format('yyyy-MM-DD')} ${tempDate.format('HH:mm:ssZ')}`;
        }
        this.handleFocusChange();
      });
    }
    this.fetchListings();
  }
  getTemplates(){
    this.smsTemplatesService.list_all().subscribe(response => {
      this.templates = response;
    });
  }

  fetchListings() {
    let query = ''
    this.listingService.list(query).subscribe(response => {
      if (response) {
        this.listings = response.data;
      }
    });
  }

  changeTime(current: Date): void  {
    this.hasError.start_date = false;
    this.hasError.start_time = false;
    if(this.model.start_date == undefined) {
      this.model.start_date = '';
      return
    }
    this.model.start_date = new Date(this.model.start_date);
    if (this.model.start_date.getDate() - new Date().getDate() < 1 &&
      this.model.start_date.getMonth() <= new Date().getMonth() &&
      this.model.start_date.getFullYear() <= new Date().getFullYear() &&
      current && this.start_time &&
      current.getTime() < new Date().getTime()) {
      this.start_time = new Date();
      this.message.create('error', 'Please select time in future');
    } else {
      if(this.start_time) this.start_time = new Date(this.start_time);
    }
    if (this.start_time && this.model.start_date) {
      this.start_time.setDate(this.model.start_date.getDate());
      this.start_time.setMonth(this.model.start_date.getMonth());
      this.start_time.setFullYear(this.model.start_date.getFullYear());
      const tempDate = moment(this.start_time)
      this.startDateTime = `${tempDate.format('yyyy-MM-DD')} ${tempDate.format('HH:mm:ssZ')}`;
    }
    this.handleFocusChange();
  }

  handleFocusChange(): void {
    const campaignPayload = {
      name: this.model.campaign_name,
      start_date_time: this.startDateTime,
      template: this.model.response,
      template_type: this.selectedTemplate,
      is_automated_campaign: this.isAutomatedCampaign
    }
    if(this.model.campaign_name && this.model.start_date && this.start_time && this.model.response){
      this.responseChange.emit(false);
    }
    this.handleResponse.emit(campaignPayload);
  }

  changeTemplate($event) {
     this.selectedTemplate = this.templates.filter(template => template.display_name === $event)[0];
     this.model.response = null;
     this.selectedTemplateName = this.selectedTemplate.display_name;

     if (this.selectedTemplateName === 'New Listing (Residential)' ||
    this.selectedTemplateName === 'Open House' || this.selectedTemplateName ===
    'Recent Sale of Agent' || this.selectedTemplateName === 'Recent Sale of Another Agent' && this.selectedListing) {
       this.renderTemplate();
     } else {
       this.renderTemplate();
     }
     // this.getResponse();
  }
  renderTemplate(){
    let body;
    if (this.selectedTemplateName === 'New Review Spotlight'){
      const testimonial_id =  this.selectedTestiomonial ? this.selectedTestiomonial.id : null;
      body = { testimonial_id };
      if (!testimonial_id) {
        return;
      }
    }else{
      const listing_id =  this.selectedListing ? this.selectedListing.id : null;
      body = { listing_id };
    }
    this.smsTemplatesService.renderTemplate(this.selectedTemplate.id, body).subscribe(res => {
      this.model.response = res.text;
    });
  }

  selectListing(){
    const modal = this.modalService.create<ListingDialogComponent, IModalData>({
      nzTitle: 'Select Listing',
      nzContent: ListingDialogComponent,
      nzData: {
        listings: this.listings
      },
      nzFooter: null,
      nzWidth: '50%',
      nzCentered: true
    });
    modal.afterClose.subscribe(resp => {
      this.selectedListing = resp;
      this.selectedAddress = this.selectedListing.address;
      // this.setResponse();
      if (this.model.oh_start_date) { this.changeOhTime(new Date()); }
      this.getResponse();
      this.renderTemplate();
    });
  }

  selectTestimonials(){
    const modal = this.modalService.create<TestimonialsDialogComponent, IModalData>({
      nzTitle: 'Select Testimonial',
      nzContent: TestimonialsDialogComponent,
      nzData: {},
      nzFooter: null,
      nzWidth: '50%',
      nzCentered: true
    });
    modal.afterClose.subscribe(resp => {
      if(resp){
        this.selectedTestiomonial = resp;
        this.getResponse();
        this.renderTemplate();
      }
    });
  }

  setResponse(){
    if(this.selectedListing){
      if(this.selectedTemplate === 'New Listing (Residential)') {
        this.model.response = `Hi {{contact name}}! \n\nCheck out this new property at ${this.selectedListing.address}. It is a ${this.selectedListing.beds}-bed  ${this.selectedListing.baths}-bath,  ${this.selectedListing.listing_type} built in  ${this.selectedListing.year_build} Its list price is $ ${this.selectedListing.price}. Let me know if you want additional information on this stunning property! \n\n${this.customer.display_name} with  ${this.customer.brokerage}.`
      }
      else if(this.selectedTemplate == 'Recent Sale of Agent'){
        this.model.response = `Hi {{contact name}}! \n\nJust closed on a stunning property in  ${this.selectedListing.city}. I can help you find your dream home, or sell your property too! \n\n${this.customer.display_name} with  ${this.customer.brokerage}.`
      }
      else if(this.selectedTemplate == 'Recent Sale of Another Agent'){
        this.model.response = `Hi {{contact name}}! \n\nDid you see that this property in your area ${this.selectedListing.city} just sold? Let’s help you find your dream home, or sell your property too! \n\n${this.customer.display_name} with  ${this.customer.brokerage}.`
      }
      else if(this.selectedTemplate == 'Open House'){
        this.model.response = `Hi {{contact name}}! ${this.customer.display_name} here with ${this.customer.brokerage}. \n\nI am hosting an Open House that you may be interested in. Here’s where and when: ${this.selectedListing.address} on ${this.selectedListing.oh_from_date ? moment(this.selectedListing.oh_from_date).format('MM/DD/YYYY') : ''} from ${this.selectedListing.oh_start_time ? moment(this.selectedListing.oh_start_time, 'HH:mm').format('hh:mm A') : ''} to ${this.selectedListing.oh_end_time ? moment(this.selectedListing.oh_end_time, 'HH:mm').format('hh:mm A') : ''} \n\nLet me know if you plan to stop by!`;
      }
    }
  }

  resetOhDateTime() {
    this.model.oh_start_date = null;
    this.oh_start_time = null;
    this.oh_end_time = null;
  }

  changeOhTime(current: Date) {
    let tempStartTime: any;
    let tempEndTime: any;
    const tempDate = moment(this.model.oh_start_date);

    if (this.oh_start_time) {
      tempStartTime = moment(this.oh_start_time);
    }

    if (this.oh_end_time) {
      tempEndTime = moment(this.oh_end_time);
    }

    if (this.model.oh_start_date || this.oh_start_time || this.oh_end_time) {
      const formattedDate = tempDate.format('MM/DD/YYYY');
      const formattedStartTime = tempStartTime ? tempStartTime.format('hh:mm A') : this.selectedListing.oh_start_time ? moment(this.selectedListing.oh_start_time, 'HH:mm').format('hh:mm A') : '';
      const formattedEndTime = tempEndTime ? tempEndTime.format('hh:mm A') : this.selectedListing.oh_end_time ? moment(this.selectedListing.oh_end_time, 'HH:mm').format('hh:mm A') : '';

      this.model.response = `Hi {{contact name}}!  ${this.customer.display_name} here with ${this.customer.brokerage}. \n\nI am hosting an Open House that you may be interested in. Here’s where and when: ${this.selectedListing.address} on ${formattedDate} from ${formattedStartTime} to ${formattedEndTime} \n\nLet me know if you plan to stop by!`;
      this.getResponse();
    }
  }


  changeNme(event: any){
    this.hasError.campaign_name = false;
  }

  isTimePresent(){
    if(!this.start_time){
      this.hasError['start_time'] = true;
      return false;
    }else{
      return true;
    }
  }

  isDatePresent(){
    if(!this.model.start_date){
      this.hasError['start_date'] = true;
      return false;
    }else{
      return true;
    }
  }

  isNamePresent(){
    if(!this.model.campaign_name){
      this.hasError['campaign_name'] = true;
      return false;
    }else{
      return true;
    }
  }

  OpenDialog(value){
    if(value){
      const modal = this.modalService.create<AutomatedCampaignsDialogComponent, IModalData>({
        nzContent: AutomatedCampaignsDialogComponent,
        nzData: {
          template: this.selectedTemplate
        },
        nzFooter: null,
        nzWidth: '25%',
        nzCentered: true
      });
      modal.afterClose.subscribe(resp => {
        if(resp){
          modal.close();
        }else{
          this.isAutomatedCampaign = false;
        }
      });
      this.handleFocusChange();
    }
  }


  areRequiredFieldsPresent(): boolean {
    this.isNamePresent();
    this.isDatePresent();
    this.isTimePresent();

    return !this.isNamePresent() || !this.isDatePresent() || !this.isTimePresent()
  }

  getResponse(): void {
    if (this.areRequiredFieldsPresent()) {
      this.message?.create('error', 'You have invalid fields.');
      return;
    }else{
      if (this.model.response || this.selectedListing){
        this.responseChange.emit(false);
        const campaignPayload = {
          name: this.model.campaign_name,
          start_date_time: this.startDateTime,
          template: this.model.response,
          template_type: this.selectedTemplate.display_name,
          is_automated_campaign: this.isAutomatedCampaign
        };
        this.handleResponse.emit(campaignPayload);
      }
    }
  }

}
