import { Component, OnInit, Input, inject } from '@angular/core';
import moment from 'moment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { ContentPreviewDialogComponent } from '../../../../shared/content-preview-dialog/content-preview-dialog.component';
import { ContentsService } from '../../../../services/contents/contents.service';
import { GenericDialogComponent } from '../../../../shared/generic-dialog/generic-dialog.component';
import {PostsService} from '../../../../services/posts/posts.service';
import { IModalData } from 'src/app/models/interfaces/modals/modal';
import { GenericDialogConfig } from 'src/app/models/interfaces/modals/generic-modal-config';
import {Content} from '../../../../vos/content/content';
import { EventPreviewPostComponent } from '../event-preview-post/event-preview-post.component';
import { lastValueFrom } from 'rxjs';
import {PostEditDialogComponent} from '../../../../shared/post-edit-dialog/post-edit-dialog.component';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'content-event-preview-content',
  templateUrl: './event-preview-content.component.html',
  styleUrls: ['./event-preview-content.component.scss']
})
export class EventPreviewContentComponent implements OnInit {
  @Input()
  calendarData;
  @Input() content?: any;
  posts;

  showDelete = false;
  isNoSupportDeletePostOnSocial = ['instagram', 'tiktok'];
  sanitizedContent: SafeHtml;

  readonly #modal = inject(NzModalRef);
  readonly nzModalData: IModalData = inject(NZ_MODAL_DATA);

  cDate = new Date();
  constructor(
    private message: NzMessageService,
    private modalService: NzModalService,
    private contentsService: ContentsService,
    private sanitizer: DomSanitizer,
  ) {

  }
  disabledDate = (current: Date): boolean => {
    return differenceInCalendarDays(current, new Date()) > 120;
  };

  handleDate(date) {
    if (differenceInCalendarDays(date, new Date()) > 120) {
      this.message.create('error', 'You cannot select date after 4 months!');
      this.cDate = new Date();
    }
  }
  ngOnInit() {
    this.content = this.nzModalData.content
    console.log('getPostsgetPostsgetPostsgetPostsgetPostsgetPosts',this.content);
    if (this.cDate.getUTCDate() > 25) {
      this.cDate = new Date(this.cDate.getFullYear(), this.cDate.getUTCMonth() + 1, 1);
    }
    this.getPosts();
    this.updateSanitizedContent();
  }

  get calnderDataKeys() {
    return Object.keys(this.calendarData || [])
  }

  getMonthData(date: Date): number | null {
    if (date.getMonth() === 8) {
      return 1394;
    }
    return null;
  }

  getFormatedDate(date) {
    return moment(date).format('YYYY-MM-DD');
  }

  showPreview(content, isEdit?) {
    const modal = this.modalService.create<ContentPreviewDialogComponent, IModalData>({
      nzTitle: content.display_name,
      nzContent: ContentPreviewDialogComponent,
      nzData: {
        config: {
          content,
          isEdit,
        },
        showActions: false,
      },
      nzFooter: null,
      nzWidth: '50%',
      // nzBodyStyle: {
      //   'height': '65vh',
      //   'overflow': 'scroll'
      // }
    });
    modal.afterClose.subscribe(response => {
    });
  }

  contentDeleted(content) {
    const modal = this.modalService.create<GenericDialogComponent, GenericDialogConfig>({
      nzContent: GenericDialogComponent,
      nzData: {
        title: 'Confirm',
        message: `Are you sure you want to delete content?\nThis action will only remove it from our platform. The posts already shared on social media will remain visible on those platforms unless you delete them there separately.\nThis cannot be undone.`,
        buttonLabel: 'Cancel',
        extraButtons: [
          {
            label: 'Confirm',
            value: true,
            color: 'warn'
          }
        ]
      },
      nzFooter: null,
      nzWidth: '60%'
    });
    modal.afterClose.subscribe(response => {
      if (response === true) {
        const content_delete = content.content_attributes;
        this.contentsService.destroy(content_delete)
          .subscribe(res => {
            this.message.create('success', `Content has been successfully deleted.`);
            this.#modal.close({ action: 'deleted' });
            setTimeout(() => {
              this.modalService.closeAll();
            }, 100);
          }, e => {
            this.message?.remove();
            this.message?.create('error', 'Error deleting the content. Please try again');
          });
      }
    });
  }

  postSocialDelete() {
    this.showDelete = true;
  }

  onPostsDeleted() {
    this.getPosts(); 
  }

  onModalClosed(): void {
    this.showDelete = false;
    this.close();
  }

  close(){
    this.modalService.closeAll();
  }

  getPosts(){
    const content = new Content();
    content.id = this.content.content_id;
    this.contentsService.posts(content).subscribe(resp => {
      this.posts = resp;
    });
  }

  openModal(social){
    this.content.post = this.posts.filter(p => p.id === social)[0];
    const modal = this.modalService.create<EventPreviewPostComponent, IModalData>({
      nzContent: EventPreviewPostComponent,
      nzData: {
        content: this.content,
      },
      nzFooter: null,
      nzWrapClassName: 'event-preview-modal'
    })
  }

  openEditCaptionModal(data){
    const modal = this.modalService.create({
      nzContent: PostEditDialogComponent,
      nzData: data,
      nzFooter: null,
      nzWidth: '50%'
    });
    modal.afterClose.subscribe(response => {

    });
  }

  updateSanitizedContent() {
    const caption =  this.content.content_attributes?.caption

    if (!caption) this.sanitizedContent = '-';
    else this.sanitizedContent = this.sanitizer.bypassSecurityTrustHtml(caption.replace(/\n/g, '<br/>'));
  }
}
