<div class="loading-container" *ngIf="loadingProduct; else dataLoaded">
  <nz-spin nzSize="large"></nz-spin>
</div>

<ng-template #dataLoaded>
  <div nz-row *ngIf="conversationProductAvailable" class="hide-on-mobile" style="padding-top: 10px">
    <div nz-col nzSpan="6">
      <div class="search push-right">
  <!--      <nz-input-group nzSize="default">-->
  <!--        <input nzSize="default"  class="full-width"-->
  <!--                   nzShowSearch nzAllowClear nzPlaceHolder="Search by name and number"-->
  <!--                   [ngModel]="searchPhone" (nzOnSearch)="searchModelChanged.next($event)"/>-->
  <!--      </nz-input-group>-->

        <nz-input-group [nzSuffix]="suffixIconSearch">
          <input type="text" nz-input placeholder="input search text" [ngModel]="searchPhone"
                (ngModelChange)='searchModelChanged.next($event)' />
        </nz-input-group>

        <ng-template #suffixIconSearch>
          <i nz-icon nzType="search"></i>
        </ng-template>
      </div>
    </div>

    <div nz-col nzSpan="18" >
  <!--    <button nz-button nzType="primary" class="push-right" (click)="searchModelChanged.next(searchPhone)" >Search</button>-->
      <button nz-button nzType="primary" class="push-right"
              (click)="clearSearch()">Clear</button>
    </div>
  </div>
  <div nz-row *ngIf="conversationProductAvailable">
      <div nz-col nzSpan="5" nzXs="24" nzSm="24" nzMd="5" nzLg="5" nzXl="5" style="background: white" [ngClass]="bodyClass">
  <!--      <h2 class="create-title mar-zero" style="margin: 5px">Messages </h2>-->
        <nz-tabset>
          <nz-tab nzTitle='All' (nzClick)="conversationList('', this.searchPhoneQuery)">
            <ng-container *ngTemplateOutlet="conversations"></ng-container>
          </nz-tab>
          <nz-tab nzTitle='Unread' (nzClick)="conversationList('unread', this.searchPhoneQuery)">
            <ng-container *ngTemplateOutlet="conversations"></ng-container>
          </nz-tab>
          <nz-tab nzTitle='Read' (nzClick)="conversationList('read', this.searchPhoneQuery)">
            <ng-container *ngTemplateOutlet="conversations"></ng-container>
          </nz-tab>
        </nz-tabset>
      </div>

      <div nz-col nzSpan="15" nzXs="24" nzSm="24" nzMd="15" nzLg="15" nzXl="15" class="max-hieght-messages-div" [ngClass]="!selectedConversation? 'hide-on-mobile': '' ">
        <div>
          <div *ngIf="selectedConversation" >

            <h3 class="create-title padding-20"> <span nz-icon nzType="arrow-left" nzTheme="outline" (click)="back()"  *ngIf="this.isSmallScreen"></span> {{selectedConversation.fullName}}({{selectedConversation.phone}})</h3>


          </div>

          <div class="mesgs">
            <div class="msg_history" *ngIf="selectedConversation && selectedConversation.messages" #scrollMe [scrollTop]="scrollMe.scrollHeight">
              <div *ngFor="let message of selectedConversation.messages">
                <div class="incoming_msg" *ngIf="message.webhook_type == 'InboundMessage'">
                  <div class="incoming_msg_img"> <nz-avatar nzSize="large" nzIcon="user"></nz-avatar> </div>
                  <div class="received_msg">
                    <div class="received_withd_msg">
                      <p>{{message.body}}</p>
                      <span class="time_date">{{toTimZone(message.date_added)}}</span></div>
                  </div>
                </div>
                <div class="outgoing_msg" *ngIf="message.webhook_type == 'OutboundMessage'">
                  <div class="sent_msg">
                    <p>{{message.body}}</p>
                    <span class="time_date">{{toTimZone(message.date_added)}}</span> </div>
                </div>
              </div>

            </div>
            <div class="type_msg">
              <div class="input_msg_write">
                <textarea rows="4" nz-input [(ngModel)]="newMessage"></textarea>

                <div style="margin-top: 10px" class="pull-right">
                  <button nz-button nzType="default" style="margin-right: 5px"(click)="clearMessage()">Clear</button>
                  <button nz-button nzType="primary" style="background: #00C461" (click)="sendMessage()" [disabled]="!selectedConversation">
                    <span nz-icon nzType="send" nzTheme="outline"></span>
                    Send
                  </button>
                </div>


                <!--            [(ngModel)]="inputValue"-->

              </div>
            </div>
          </div>
        </div>
      </div>

      <div nz-col [nzSpan]="4" style="background: white" class="text-center  hide-on-mobile">
    <!--    <input nz-input formControlName="title"  [(ngModel)]="member.title"/> -->


        <div class="padding-top text-center" *ngIf="selectedConversation">
          <div class="contact_img"> <nz-avatar nzSize="large" nzIcon="user"></nz-avatar> </div>

          <nz-space nzDirection="vertical">
          <nz-input-group [nzPrefix]="prefixTemplateName">
            <input type="text" nz-input placeholder="Name" [(ngModel)]="selectedConversation.fullName"/>
          </nz-input-group>
          <ng-template #prefixTemplateName><span nz-icon nzType="user" ></span></ng-template>

          <nz-input-group [nzPrefix]="prefixTemplateUser">
            <input type="text" nz-input placeholder="Phone" [(ngModel)]="selectedConversation.phone"/>
          </nz-input-group>
          <ng-template #prefixTemplateUser><span nz-icon nzType="phone" ></span></ng-template>

          <nz-input-group [nzPrefix]="prefixTemplateEmail">
            <input type="text" nz-input placeholder="Email" [(ngModel)]="selectedConversation.email"/>
          </nz-input-group>
          <ng-template #prefixTemplateEmail><span nz-icon nzType="inbox"></span></ng-template>

  <!--        <nz-input-group [nzPrefix]="prefixTemplateTags">-->
  <!--          <input type="text" nz-input placeholder="Add Tags" />-->
  <!--        </nz-input-group>-->
          <ng-template #prefixTemplateTags><span nz-icon nzType="tag"></span></ng-template>
        </nz-space>


  <!--        <div class="contact-buttons">-->
  <!--          <button nz-button nzType="primary">-->
  <!--            <span nz-icon nzType="save" nzTheme="outline"></span>-->
  <!--            Update Contact-->
  <!--          </button>-->
  <!--        </div>-->
        </div>

      </div>


    <ng-template #conversations>
      <nz-list class="demo-loadmore-list" [nzLoading]="initLoading" nzItemLayout="vertical" style="overflow-y: auto;">
        <nz-list-item *ngFor="let item of list" (click)="onClickConversation(item)" class="list-item" [ngClass]="{'selected-conversation' : item == selectedConversation}">
          <ng-container *ngIf="!item.loading">
            <nz-list-item-meta>
              <nz-list-item-meta-avatar>
                <nz-avatar nzSize="large" nzIcon="user"></nz-avatar>
              </nz-list-item-meta-avatar>

              <nz-list-item-meta-title>
                <div class="title">
                  <p class="message-padding">{{ item.fullName }}  <span class="pull-right date"></span></p>
                  <p class="message-padding">{{ display_conversation(item) }} </p>
                </div>
              </nz-list-item-meta-title>
            </nz-list-item-meta>
          </ng-container>
          <nz-skeleton
            *ngIf="item.loading"
            [nzAvatar]="true"
            [nzActive]="true"
            [nzTitle]="false"
            [nzLoading]="true"
          ></nz-skeleton>
        </nz-list-item>
        <nz-list-item *ngIf="!initLoading">
          <ng-container>
            <button nz-col nz-button nzType="primary"  style="margin: 20px 20px 30px 100px" class="create-button" *ngIf="list.length < total_entries" (click)="conversationPageLoad()">Load more..</button>
          </ng-container>
        </nz-list-item>

        <nz-skeleton *ngIf="initLoading" [nzAvatar]="true" [nzActive]="true" [nzTitle]="true" [nzLoading]="true">
        </nz-skeleton>
        <nz-skeleton *ngIf="initLoading" [nzAvatar]="true" [nzActive]="true" [nzTitle]="true" [nzLoading]="true">
        </nz-skeleton>
        <nz-skeleton *ngIf="initLoading" [nzAvatar]="true" [nzActive]="true" [nzTitle]="true" [nzLoading]="true">
        </nz-skeleton>
        <nz-skeleton *ngIf="initLoading" [nzAvatar]="true" [nzActive]="true" [nzTitle]="true" [nzLoading]="true">
        </nz-skeleton>

        <!--          <div class="loadmore" nz-list-load-more>-->
        <!--            <button nz-button *ngIf="!loadingMore" (click)="onLoadMore()">loading more</button>-->
        <!--          </div>-->
      </nz-list>
    </ng-template>

  </div>
</ng-template>
<div nz-row *ngIf="!conversationProductAvailable">
  <div nz-col nzSpan="24">
    <div class="social-connect-container">
      <div class="social-headings" *ngIf="!ghlNotConnected">
        <h2>Upgrade Your Plan</h2>
        <p>Schedule a time for your Account Manager to call and upgrade your plan for you!</p>
      </div>
      <div class="social-headings" *ngIf="ghlNotConnected">
        <h2>Please Connect your GHL account!</h2>
        <p>Schedule a time with your Account Manager to Connect your GHL account!</p>
      </div>
      <div class="social-selection">
        <div #container class="calendly-inline-widget" style="min-width:320px;height:700px;" data-auto-load="false"></div>
      </div>
    </div>
  </div>

</div>
