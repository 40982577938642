import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { Product, ProductStyle } from '../../vos/product/product';
import { HttpClient } from '@angular/common/http';
import { DataResponse } from '../../models/data-response/data-response';
import { map } from 'rxjs/operators';
import { Resource } from 'src/app/vos/resource/resource';
import { Tier } from 'src/app/vos/tier/tier';
import { CustomerResourceService } from '../customer-resource.service';
import { AuthenticationService } from '../authentication/authentication.service';
import {T} from '@fullcalendar/core/internal-common';

@Injectable({
  providedIn: 'root'
})
export class ProductsService extends CustomerResourceService<Product> {
  private productsSubject: BehaviorSubject<Product[]> = new BehaviorSubject<Product[]>([]);
  public get products(): Product[] {
    return this.productsSubject?.value;
  }

  /**
   * Path uri.
   * @type {string}
   * @private
   */

  /**
   * Url to endpoint api.
   * @type {string}
   */

  /**
   * Endpoint request headers.
   * @type {HttpHeaders}
   */

  /**
   * Component constructor and DI injection point.
   * @param {HttpClient} http
   *
   */
  constructor(protected http: HttpClient, authService: AuthenticationService,
    private router: Router,
  ) {
    super(Product, http, authService);
  }

  customerClickOnProduct<T>(object: any, id: number): Observable<DataResponse<T>> {
    return this.http.post<DataResponse<T>>(
      `${this._uri}/products/${id}/handle_customer_product_click.json`,
      object,
      { headers: this.headers }
    );
  }

  public productList(url: string, parameters?: Record<string, any>): Observable<any> {
    if (url.indexOf('undefined') > -1) {
      this.router.navigate(['login']);
      return of({});
    }
    return super.listFrom(url, parameters).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  public product(name: string): Product {
    return this.products?.find((prod) => prod.name === name);
  }

  public styles(product: Product, resource: Resource, page?, query?): Observable<any> {
    page = page || 1;

    let tagQuery = '';
    if (resource.tags) {
      tagQuery = resource.tags.split(',').map((tagName) => {
        if (tagName) {
          return `tags_names[]=${tagName}`;
        } else {
          return '';
        }
      }).join('&');
    }


    return this.http.get<DataResponse<ProductStyle[]>>(
      `${this._uri}products/${product.id}/styles.json?${tagQuery}&page=${page}&${query ? query : ''}`).pipe(map((resp) => {
        return resp;
      }));
  }

  public tiers(): Observable<Tier[]> {
    return this.http.get<DataResponse<Tier[]>>(`${this._uri}products/tiers.json`).pipe(map((resp) => {
      if (resp) {
        return resp.data.map((tier) => new Tier(tier));
      }
    }));

  }

}
