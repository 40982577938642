import { Component, OnInit, Input, inject } from '@angular/core';
import {NZ_MODAL_DATA, NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import { IModalData } from 'src/app/models/interfaces/modals/modal';
import {TestimonyService} from '../../services/testimonies/testimonies.service';
import {BreadCrumService} from '../../services/breadcrum/bread-crum.service';
import {Router} from '@angular/router';
import {PaginationService} from '../../services/pagination/pagination.service';
import {NzMessageService} from 'ng-zorro-antd/message';
import {Subject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Testimony} from '../../vos/testimony/testimony';
import {GenericDialogConfig} from '../../models/interfaces/modals/generic-modal-config';
import {GenericDialogComponent} from '../generic-dialog/generic-dialog.component';

@Component({
  selector: 'app-testimonial-dialog',
  templateUrl: './testimonials-dialog.component.html',
  styleUrls: ['./testimonials-dialog.component.scss']
})
export class TestimonialsDialogComponent implements OnInit {
  testimonies: Testimony[]  = [];
  loading = false;
  maxmarketreports = 5;
  disableNew = false;
  sortings = ['attestant_name'];
  searchText: string;
  search = false;
  mapOfSort: { [key: string]: string | null } = {
    created_at: null,
    address: null,
    region_name: null
  };
  selectedSort = {
    by: 'attestant_name',
    order: 'asc'
  };
  pageSizeOptions = [10];
  ReportsPagination = {
    limit: 10,
    total: 10,
    page: 1,
    totalPages: 10
  };
  public searchModelChanged: Subject<string> = new Subject<string>();
  public searchModelChangeSubscription: Subscription;
  constructor(
    private testimonyService: TestimonyService,
    private breadcrumservice: BreadCrumService,
    private router: Router,
    private paginationService: PaginationService,
    private modalService: NzModalService,
    public message: NzMessageService,
    private modal: NzModalRef
  ) {
    this.searchModelChangeSubscription = this.searchModelChanged
      .pipe(
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe(newText => {
        this.searchText = newText;
        // this.listingPagination.page = 1;
        if (newText) {
          this.search = true;
          this.fetchTestimonies(newText);
        } else {
          this.fetchTestimonies();
        }
      });
  }

  ngOnInit() {
    this.loading = true;
    this.fetchTestimonies();
    this.breadcrumservice.set_breadcrum();
  }
  fetchTestimonies(text?, sort?) {
    this.loading = true;
    const pageNo = this.paginationService.getCurrentPage();
    let query = `?page=${pageNo}`;
    if (text) {
      query = `${query}&q[attestant_name]=${text}`;
    }
    if (sort) {
      query = `${query}&${sort}`;
    }
    this.testimonyService.list(query).subscribe(response => {
      if (response) {
        this.loading = false;
        this.ReportsPagination.page = pageNo;
        this.ReportsPagination.limit = response['per_page'];
        this.ReportsPagination.total = response['total_entries'];
        const limit = this.ReportsPagination.limit;
        const total_items = this.ReportsPagination.total;
        this.ReportsPagination.totalPages = Math.ceil(total_items / limit);
        this.testimonies = response.data;
        // this.removeAddMarketReportCard(response);
      }
      this.loading = false;
    });
  }
  didPage(pageIndex) {
    this.ReportsPagination.page = pageIndex;
    this.loading = true;
    this.paginationService.setCurrentPage(this.ReportsPagination);
    this.fetchTestimonies();
  }
  removeAddMarketReportCard(reports) {
    if (reports.total_entries < this.maxmarketreports) {
      this.disableNew = true;
    }
  }
  sort(sort: { key: string; value: string }): void {
    for (const key in this.mapOfSort) {
      this.mapOfSort[key] = key === sort.key ? sort.value : null;
    }
    const valuesMap = {
      descend: 'desc',
      ascend: 'asc'
    };
    this.fetchTestimonies(null, `q[s]=${sort.key} ${valuesMap[sort.value]}`);
  }

  viewDetail(id) {
    this.router.navigateByUrl(`testimonials/${id}/edit`);
  }

  editListing(id) {
    this.router.navigateByUrl(`testimonials/${id}/edit`);
  }

  createNew() {
    this.router.navigateByUrl('testimonials/new');
  }

  deleteTestimony(testimony) {
    const modal = this.modalService.create<GenericDialogComponent, GenericDialogConfig>({
      nzContent: GenericDialogComponent,
      nzData: {
        title: 'Confirm',
        message: 'Are you sure you want to delete this testimony?',
        buttonLabel: 'Cancel',
        extraButtons: [
          {
            label: 'Confirm',
            value: true,
            color: 'warn'
          }
        ]
      },
      nzFooter: null
    });
    modal.afterClose.subscribe(response => {
      if (response === true) {
        this.loading = true;
        this.testimonyService.destroy(testimony).subscribe(r => {
          this.fetchTestimonies();
        }, e => {
          this.loading = false;
          this.message?.remove();
          this.message?.create('error', 'Error deleting this testimony. Please try again');
        });
      }
    });
  }
  selectedTestimonial(testimonial) {
    this.modal.close(testimonial);
  }

}
