<form #customerForm="ngForm" nz-form>
  <h1 class="section-title section-title-small" *ngIf="showFields.includes('first_name')">Account Details</h1>
  <h5 class="section-description" *ngIf="showFields.includes('first_name')">Your personal account details that we use to
    communicate with you.
    This will not be seen by others.
  </h5>
  <div class="customer-form" nz-row nzType="flex" nzJustify="space-between" *ngIf="model">
    <nz-form-item class="gutter-row" nz-col nzSpan="11" *ngIf="showFields.includes('first_name')">
      <nz-form-label nzFor="first_name" nzRequired>First Name
      </nz-form-label>
      <nz-form-control nzErrorTip="Please input your first name!">
        <nz-input-group nzPrefixIcon="user">
          <input type="text" nz-input id="first_name" [(ngModel)]="model.first_name" name="first_name"
            [required]="requiredFields.includes('first_name')" placeholder="First Name" />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item class="gutter-row" nz-col nzSpan="11" nzOffset="2" *ngIf="showFields.includes('last_name')">
      <nz-form-label nzFor="last_name" nzRequired>Last Name
      </nz-form-label>
      <nz-form-control nzErrorTip="Please input your last name!">
        <nz-input-group nzPrefixIcon="user">
          <input type="text" nz-input id="last_name" [(ngModel)]="model.last_name" name="last_name"
            [required]="requiredFields.includes('last_name')" placeholder="Last Name" />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
  </div>
  <div class="customer-form" nz-row nzType="flex" nzJustify="space-between" *ngIf="model">


    <nz-form-item class="gutter-row" nz-col nzSpan="11" *ngIf="showFields.includes('email')">
      <nz-form-label nzFor="email" nzRequired>Email
      </nz-form-label>
      <nz-form-control nzErrorTip="Please input your email!">
        <nz-input-group nzPrefixIcon="mail">
          <input type="text" nz-input id="email" [(ngModel)]="model.email" name="email"
            [required]="requiredFields.includes('email')" placeholder="E-mail" />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item class="gutter-row" nz-col nzSpan="11" nzOffset="2" *ngIf="showFields.includes('primary_phone')">
      <nz-form-label nzRequired for="primary_phone" nzFor="primary_phone">Phone Number</nz-form-label>
      <nz-form-control nzErrorTip="Please input your phone!">
        <nz-input-group nzPrefixIcon="phone">
          <input nz-input type="tel" id="primary_phone" [(ngModel)]="model.primary_phone" name="primary_phone"
            [required]="requiredFields.includes('primary_phone')">
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
  </div>
  <div class="customer-form" nz-row nzType="flex" nzJustify="space-between" *ngIf="model">

    <nz-form-item class="gutter-row" nz-col nzSpan="7" *ngIf="showFields.includes('primary_city')">
      <nz-form-label nzFor="primary_city">City</nz-form-label>
      <nz-form-control>
        <input nz-input type="text" id="primary_city" [(ngModel)]="model.primary_city" name="primary_city"
          [required]="requiredFields.includes('primary_city')">
      </nz-form-control>
    </nz-form-item>
    <nz-form-item class="gutter-row" nz-col nzSpan="7" nzOffset="1" *ngIf="showFields.includes('primary_state')">
      <nz-form-label for="primary_state" nzFor="primary_state">State</nz-form-label>
      <nz-form-control>
        <input nz-input type="text" maxlength="2" id="primary_state" [(ngModel)]="model.primary_state"
          name="primary_state" [required]="requiredFields.includes('primary_state')">
      </nz-form-control>
    </nz-form-item>
    <nz-form-item class="gutter-row" nz-col nzSpan="7" nzOffset="1" *ngIf="showFields.includes('primary_zip')">
      <nz-form-label for="primary_state" nzFor="primary_zip">Zip</nz-form-label>
      <nz-form-control>
        <input nz-input type="tel" maxlength="5" class="form-control" id="primary_zip" [(ngModel)]="model.primary_zip"
          name="primary_zip" [required]="requiredFields.includes('primary_zip')">
      </nz-form-control>
    </nz-form-item>
  </div>
  <nz-divider *ngIf="showFields.includes('first_name')" [style.margin-bottom]="'30px'"></nz-divider>
  <h1 class="section-title section-title-small" *ngIf="!limitShow">Realtor Details</h1>
  <h5 class="section-description" *ngIf="!limitShow">Information about you including your brokerage and license number.
  </h5>
  <div class="customer-form-realtor" nz-row nzType="flex" nzJustify="space-between">
    <nz-form-item class="gutter-row" nz-col [nzSpan]="realtorSpan"
      *ngIf="!limitShow || showFields.includes('brokerage')">
      <nz-form-label nzFor="brokerage" nzRequired>Brokerage
      </nz-form-label>
      <nz-form-control nzErrorTip="Please input your brokerage!">
        <input placeholder="Brokerage" [required]="requiredFields.includes('brokerage')" nz-input
          [(ngModel)]="model.brokerage" name="brokerage" (ngModelChange)="changeBrokerage()" [nzAutocomplete]="auto" />
        <nz-autocomplete #auto>
          <nz-auto-option *ngFor="let brokerage of brokerageList" [nzValue]="brokerage.title">{{brokerage.title}}
          </nz-auto-option>
        </nz-autocomplete>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item class="gutter-row" nz-col [nzSpan]="realtorSpan" nzOffset="2"
      *ngIf="!limitShow || showFields.includes('license_number')">
      <nz-form-label nzFor="license_number">Realtor License Number
      </nz-form-label>
      <nz-form-control nzErrorTip="Please input your License Number!">
        <input type="text" nz-input type="text" id="license_number" [(ngModel)]="model.license_number"
          name="license_number" [required]="requiredFields.includes('license_number')"
          placeholder="Realtor License Number" />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item class="gutter-row" nzSpan="7" nzOffset="1" *ngIf="!limitShow || showFields.includes('credential')">
      <nz-form-label class="text-left" [nzSm]="24" [nzXs]="24" nzFor="credential">Designations
      </nz-form-label>
      <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please input your designations!">
        <nz-select id="credential" [(ngModel)]="model.credential" name="credential"
          [required]="requiredFields.includes('credential')" nzMode="tags" nzPlaceHolder="Select credential">
          <nz-option *ngFor="let cred of credentials" [nzLabel]="cred" [nzValue]="cred"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </div>
  <nz-divider *ngIf="!limitShow" [style.margin-bottom]="'30px'"></nz-divider>

  <div class="display-details-section">
    <h1 class="section-title section-title-small" *ngIf="!limitShow">Display Details</h1>
    <h5 class="section-description" *ngIf="!limitShow">This is the information that will be displayed accross all your
      content (videos, flyers, etc.)
    </h5>
    <div *ngIf="showFields.includes('images')">
      <h3 *ngIf="!limitShow">Add/Edit Headshot and Logo</h3>
      <div nz-row nzType="flex" nzJustify="start">
        <div class="image-card-wrapper" nz-col nzSpan="6">
          <span [class.invalid]="!imgaesValid('image') && highlightInvalid">Headshot{{requiredFields.includes('images') ?
            ' *' : ''}}</span>
          <app-image-handler [content]="model" [imageable_type]="'headshot'" [config]="headshotConfig"
            (imageObject)="setHeadshot($event)"></app-image-handler>
        </div>
        <div class="image-card-wrapper" nzSpan="6">
          <span [class.invalid]="!imgaesValid('logo') && highlightInvalid">Logo{{requiredFields.includes('images') ? ' *'
            : ''}}
          </span>
          <app-image-handler *ngIf="!loading" [content]="model" [imageable_type]="'logo'" [config]="logoConfig"
            (imageObject)="setLogo($event)"></app-image-handler>
        </div>
        <div class="image-card-wrapper" nzSpan="6">
          <span style="margin-left: 100px">Enable Headshot with Transparent Background <nz-switch [(ngModel)]="model.settings['background_headhshot_enabled']"
                                                                             (ngModelChange)="toggleSetting()" [ngModelOptions]="{standalone: true}"></nz-switch></span> <br>
            <img *ngIf="!loading" [src]="model.no_bg_headshot" disabled="" width="160px" height="160px" style="margin-left: 100px;margin-top: 40px">
        </div>
      </div>
    </div>
    <div class="customer-form-realtor" nz-row nzType="flex" nzJustify="space-between">
      <nz-form-item class="gutter-row" nz-col nzSpan="7" *ngIf="!limitShow || showFields.includes('display_name')">
        <nz-form-label nzFor="display_name" nzRequired>Display Name</nz-form-label>
        <nz-form-control>
          <input nz-input type="text" id="display_name" [(ngModel)]="model.display_name" name="display_name"
            [required]="requiredFields.includes('display_name')">
        </nz-form-control>
      </nz-form-item>
      <nz-form-item nz-col nzSpan="7" nzOffset="1" *ngIf="!limitShow || showFields.includes('preferred_title')">
        <nz-form-label nzFor="preferred_title" nzRequired>Title</nz-form-label>
        <nz-form-control>
          <input placeholder="Enter your title" nz-input [(ngModel)]="model.preferred_title"
            (input)="onChangetitle($event.target?.value)" id="preferred_title" name="preferred_title"
            [nzAutocomplete]="auto" [required]="requiredFields.includes('preferred_title')" />
          <nz-autocomplete [nzDataSource]="filteredOptions" #auto> </nz-autocomplete>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item nz-col nzSpan="7" nzOffset="1" *ngIf="!limitShow || showFields.includes('display_email')">
        <nz-form-label nzFor="display_email" nzRequired>Display Email
        </nz-form-label>
        <nz-form-control nzErrorTip="Please input your display email!">
          <input nz-input type="text" class="form-control" id="display_email" [(ngModel)]="model.display_email"
            name="display_email" [required]="requiredFields.includes('display_email')" placeholder="Display Email" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item nz-col nzSpan="7" nzOffset="1" *ngIf="!limitShow || showFields.includes('display_phone')">
        <nz-form-label nzFor="display_phone" nzRequired>Display Phone
        </nz-form-label>
        <nz-form-control nzErrorTip="Please input your display phone!">
          <input nz-input type="tel" class="form-control" id="display_phone" [(ngModel)]="model.display_phone"
            name="display_phone" [required]="requiredFields.includes('display_phone')" placeholder="Display Phone" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item nz-col nzSpan="7" nzOffset="1" *ngIf="!limitShow || showFields.includes('office_phone')">
        <nz-form-label nzFor="office_phone">Office Phone
        </nz-form-label>
        <nz-form-control>
          <input nz-input type="tel" id="office_phone" [(ngModel)]="model.office_phone" name="office_phone"
            [required]="requiredFields.includes('office_phone')" placeholder="Office Phone">
        </nz-form-control>
      </nz-form-item>
      <nz-form-item nz-col nzSpan="7" nzOffset="1" *ngIf="!limitShow || showFields.includes('website_url')">
        <nz-form-label nzFor="website_url">Website
        </nz-form-label>
        <nz-form-control>
          <input nz-input type="text" id="website_url" [(ngModel)]="model.website_url" name="website_url"
            [required]="requiredFields.includes('website_url')" placeholder="Website">
        </nz-form-control>
      </nz-form-item>
      </div>
      <div nz-row nzType="flex" nzJustify="start">
      <nz-form-item nz-col nzSpan="7" nzOffset="1"  class="primary_region" *ngIf="!limitShow || showFields.includes('primary_region')">
        <nz-form-label nzFor="primary_region" nzRequired>Primary Region
        </nz-form-label>
        <nz-form-control>
          <input nz-input type="text" id="primary_region" [(ngModel)]="model.primary_region" name="primary_region"
                 [required]="requiredFields.includes('primary_region')" placeholder="Primary Region" maxlength="30">
        </nz-form-control>
      </nz-form-item>

      <nz-form-item nz-col nzSpan="7" nzOffset="1" class="region-des"  *ngIf="!limitShow">
        <nz-form-label nzFor="primary_region" nzRequired>Region Description
        </nz-form-label>
        <nz-form-control>
          <nz-select
            [(ngModel)]="model.region.id"
            nzAllowClear="true"
            [ngModelOptions]="{ standalone: true }"
            [ngClass]="model?.region?.id ? '' : 'error-input'">
            <nz-option *ngFor="let region of regionList" [nzValue]="region.id" [nzLabel]="region.name"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <nz-divider *ngIf="!limitShow" [style.margin-bottom]="'30px'"></nz-divider>
  <div id="brand-colors-section">
    <h1 class="section-title section-title-small" *ngIf="!limitShow">Brand Colors</h1>
    <h5 class="section-description" *ngIf="!limitShow">These colors will be used to brand your content..
    </h5>
    <div nz-row nzGutter="16" *ngIf="!limitShow && model && model?.settings">
      <div nz-col class="gutter-row" nzSpan="12">
        <div class="gutter-box">
          <span class="input-title">Background Color</span>
          <input class="color-picker-input" [value]="model.settings.bg_color" [style.background]="model.settings.bg_color"
            [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'" [(colorPicker)]="model.settings.bg_color" />

          <nz-form-item appearance="fill" class="form-field color-field">
            <!-- <nz-form-label for="bg_color">Background</nz-form-label> -->

            <nz-form-control>
              <input nz-input type="text" id="bg_color" pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$"
                [(ngModel)]="model.settings.bg_color" name="bg_color" />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div nz-col class="gutter-row" nzSpan="12">
        <div class="gutter-box">
          <span class="input-title">Text Color</span>
          <input class="color-picker-input" [value]="model.settings.text_color"
            [style.background]="model.settings.text_color" [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'"
            [(colorPicker)]="model.settings.text_color" />
          <nz-form-item appearance="fill" class="form-field color-field">
            <!-- <nz-form-label for="text_color">Text Color</nz-form-label> -->
            <nz-form-control>
              <input nz-input type="text" pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$" id="text_color"
                [(ngModel)]="model.settings.text_color" name="text_color" />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>
  </div>

  <nz-divider [style.margin-bottom]="'30px'"
    *ngIf="!(showFields.includes('banner_images') && showFields.length === 1) && !limitShow">
  </nz-divider>

  <!-- <nz-divider *ngIf="!limitShow" [style.margin-bottom]="'30px'"></nz-divider> -->

  <div nz-row nzJustify="space-between" *ngIf="showFields.includes('banner_images')" >
    <div nz-col >
      <h1 class="section-title section-title-small" [class.invalid]="!imgaesValid('banner_images')
      && highlightInvalid">
      Banner Images
    </h1>
    </div>
    <!-- <div nz-col >
      <button nz-button nzType="primary" nzGhost (click)="openGallery()" class="space-right">Upload Images<i nz-icon nzType="upload"></i></button>
      <button nz-button nzType="primary" (click)="openGallery(1)" nzGhost>Gallery <i nz-icon nzType="eye"></i></button>
    </div> -->
  </div>
  <div *ngIf="showFields.includes('banner_images') && model">
    <h4 class="section-description" [class.invalid]="!imgaesValid('banner_images') && highlightInvalid">
      These are the images we will use for your banner content.{{requiredFields.includes('banner_images') ? ' (5
      required)' : ''}}
    </h4>
    <app-images-reorder #imageReorder [images]="model.images_attributes" [content]="model" [imageable_type]="'Customer'">
    </app-images-reorder>
  </div>
</form>
