<div class="left-spacing margin-bottom">
  <span nz-typography style="font-size: 16px; font-weight: 500;">Select to share your content.</span>
  <!-- <div *ngIf="submitted && !config.facebook && !config.linkedin" class="ant-form-item-explain ant-form-item-explain-error">
    <div class="ng-trigger ng-trigger-helpMotion">Please select platform!</div>
  </div> -->

  <ul class="social-selection">
    <li *ngIf="!isCoverPhoto">
      <label class="fw-600" nz-checkbox
        [nzDisabled]="!customer.fb_page_id || !linkedinConnected || !instagramConnected || !youtubeConnected"
        [(ngModel)]="all" (change)="changeAll()">Select All</label>
    </li>

    <!-- Facebook Integration -->
    <li *ngIf="!hideFacebook">
      <label nz-checkbox [(ngModel)]="config.facebook" [nzDisabled]="!isFacebookConnected">
        <img class="box-image" src=".././../../assets/icons/facebook.png" alt="" />
      </label>
      <span class="fw-600">Facebook</span>
      <button *ngIf="manualFacebookIntegrationStatus" nz-button nzType="primary" class="mgbtm width-auto" disabled>Managed By Zentap</button>
      <button nz-button nzType="default" class="social-btns"
        *ngIf="!manualFacebookIntegrationStatus && !isFacebookConnected && !manualFacebookIntegration"
        (click)="showFacebook()">
        Connect
      </button>
      <button nz-button nzType="default" class="social-btns"
        *ngIf="!manualFacebookIntegrationStatus && isFacebookConnected && !manualFacebookIntegration"
        (click)="showFacebook()">
        Refresh
      </button>
      <button nz-button nzType="default" class="btn-connected social-btns"
        *ngIf="isFacebookConnected && !manualFacebookIntegrationStatus && manualFacebookIntegration">Connected!</button>
    </li>

    <!-- LinkedIn Integration -->
    <li *ngIf="!hidePosting && !isCarousel && !isCoverPhoto">
      <label nz-checkbox [(ngModel)]="config.linkedin" [nzDisabled]="!linkedinConnected">
        <img class="box-image" src=".././../../assets/icons/linkedin.png" alt="" />
      </label>
      <span class="fw-600">LinkedIn</span>
      <div class="spiner-center loading-overlay" *ngIf="loading">
        <nz-spin nzSimple [nzSize]="'small'"></nz-spin>
      </div>
      <button nz-button nzType="default" class="social-btns" (click)="connectLinkedIn()"
        *ngIf="linkedinStatus && !(linkedinConnected || (getLinkedInCode && getLinkedInCode.status === 'success')) && !integration">
        Connect
      </button>
      <button nz-button nzType="default" class="social-btns" (click)="connectLinkedIn()"
        *ngIf="linkedinStatus && integration && !(linkedinConnected || (getLinkedInCode && getLinkedInCode.status === 'success'))">Refresh
      </button>
      <button nz-button nzType="default" class="btn-connected social-btns right-spacing"
        *ngIf="linkedinStatus && integration && (linkedinConnected || (getLinkedInCode && getLinkedInCode.status === 'success'))">Connected!
      </button>
      <button nz-button nzType="default" (click)="upgradePlan()" class="btn-upgrade social-btns right-spacing"
        *ngIf="!linkedinStatus">Upgrade
      </button>
    </li>

    <!-- Instagram Integration -->
    <li *ngIf="!hidePosting && !socialFeed && !isCoverPhoto">
      <label nz-checkbox [(ngModel)]="config.instagram" [nzDisabled]="!instagramConnected">
        <img class="box-image" src=".././../../assets/icons/instagram.png" alt="" />
      </label>
      <span class="fw-600">Instagram</span>
      <!-- Instagram Loading Spinner -->
      <div class="spiner-center loading-overlay" *ngIf="loading">
        <nz-spin nzSimple [nzSize]="'small'"></nz-spin>
      </div>
      <!-- Instagram Connect / Refresh / Connected / Upgrade -->
      <button nz-button nzType="default" class="social-btns" (click)="connectInstagram()"
        *ngIf="instagramStatus && !instagramConnected">Connect
      </button>
      <button nz-button nzType="default" class="social-btns" (click)="connectInstagram()"
        *ngIf="instagramStatus && instagramIntegration && !instagramConnected">Refresh
      </button>
      <button nz-button nzType="default" class="btn-connected social-btns right-spacing"
        *ngIf="instagramStatus && instagramIntegration && (instagramConnected)">Connected!
      </button>
      <button nz-button nzType="default" (click)="upgradePlan()" class="btn-upgrade social-btns right-spacing"
        *ngIf="!instagramStatus">Upgrade
      </button>
    </li>

    <!-- Google Integration -->
    <li *ngIf="!hidePosting && !isCarousel && !config?.hideGoogle && showGMB && !socialFeed && !isCoverPhoto">
      <label nz-checkbox [(ngModel)]="config.google" [nzDisabled]="!isGoogleConnected">
        <img class="box-image" src=".././../../assets/icons/google.png" alt="" />
      </label>
      <span class="fw-600">Google</span>
      <!-- Google Connect / Refresh / Connected / Upgrade -->
      <button nz-button nzType="default" *ngIf="!isGoogleConnected && isAdmin && !googlePageIntegration"
        (click)="showGoogle()">Connect
      </button>
      <button nz-button nzType="default" *ngIf="!isGoogleConnected && isAdmin && googlePageIntegration"
        (click)="showGoogle()">Refresh
      </button>
      <button nz-button nzType="default" *ngIf="isGoogleConnected && googlePageIntegration"
        class="btn-connected">Connected!
      </button>
      <button nz-button nzType="default" (click)="upgradePlan()" class="btn-upgrade social-btns right-spacing"
        *ngIf="!googlePageIntegration && !isGoogleConnected">Upgrade
      </button>
      <!-- Google Warning Alert -->
      <nz-alert *ngIf="config.google" class="google-warning" nzType="warning" nzMessage="Warning"
        nzDescription="Please be aware that including your website link, email, or phone number in the caption of a Google Business post will likely result in that post not going through."
        nzShowIcon></nz-alert>
    </li>

    <!-- YouTube Integration -->
    <li *ngIf="!hidePosting && !isCarousel && !socialFeed && !hideYoutube">
      <label nz-checkbox [(ngModel)]="config.youtube" [nzDisabled]="!youtubeConnected">
        <img class="box-image" src=".././../../assets/icons/youtube.png" alt="" />
      </label>
      <span class="fw-600">YouTube</span>
      <!-- YouTube Loading Spinner -->
      <div class="spiner-center loading-overlay" *ngIf="loading">
        <nz-spin nzSimple [nzSize]="'small'"></nz-spin>
      </div>
      <!-- YouTube Connect / Connected / Upgrade -->
      <button nz-button nzType="default" class="social-btns" (click)="showYoutube()"
        *ngIf="!youtubeConnected && youtubeStatus">Connect
      </button>
      <button nz-button nzType="default" class="btn-connected social-btns right-spacing"
        *ngIf="(youtubeConnected)">Connected!
      </button>
      <button nz-button nzType="default" (click)="upgradePlan()" class="btn-upgrade social-btns right-spacing"
        *ngIf="!youtubeStatus">Upgrade
      </button>
    </li>

    <!-- TikTok Integration -->
    <li *ngIf="!isRssFeed && !isCoverPhoto">
      <label nz-checkbox [(ngModel)]="config.tiktok" [nzDisabled]="!tiktokConnected">
        <img class="box-image" src=".././../../assets/icons/tiktok.png" alt="" />
      </label>
      <span class="fw-600">Tiktok</span>
      <!-- TikTok Creator Info -->
      <span class="fw-600" *ngIf="tiktokIntegration && tiktokIntegration.tiktok_creator_info['data']">
        : <span style="font-size: 14px; color: black; font-weight: 500;">
          {{tiktokIntegration.tiktok_creator_info["data"]["creator_nickname"]}}
        </span>
      </span>
      <!-- TikTok Loading Spinner -->
      <div class="spiner-center loading-overlay" *ngIf="loading">
        <nz-spin nzSimple [nzSize]="'small'"></nz-spin>
      </div>
      <!-- TikTok Connect / Refresh / Connected / Upgrade -->
      <button nz-button nzType="default" class="social-btns" (click)="connectTiktok()" *ngIf="tiktokStatus && !tiktokConnected">
        Connect
      </button>
      <button nz-button nzType="default" class="social-btns" (click)="connectTiktok()" *ngIf="tiktokStatus && tiktokIntegration && !tiktokConnected">
        Refresh
      </button>
      <button nz-button nzType="default" class="btn-connected social-btns right-spacing"
        *ngIf="tiktokStatus && tiktokIntegration && (tiktokConnected)">
        Connected!
      </button>
      <button nz-button nzType="default" (click)="upgradePlan()" class="btn-upgrade social-btns right-spacing"
        *ngIf="!tiktokStatus">
        Upgrade
      </button>
    </li>

    <!-- Connect Your Account First Message -->
    <li *ngIf="hidePosting" class="hidePosting">
      <div class="social-headings" *ngIf="!linkedinConnected">
        <h2>Connect Your Account First!</h2>
        <p>Click the 'Connect' button to connect your account</p>
        <p>So we can auto-post all your content</p>
      </div>
      <!-- LinkedIn Connect -->
      <div class="hidePostingConnect" *ngIf="!linkedinConnected">
        <img class="box-image" src=".././../../assets/icons/linkedin.png" alt="" />
        <span class="fw-600 l-text">Share your content to LinkedIn</span>
        <div class="spiner-center loading-overlay" *ngIf="loading">
          <nz-spin nzSimple [nzSize]="'small'"></nz-spin>
        </div>
        <button nz-button nzType="default" class="social-btns right-spacing" (click)="connectLinkedIn()">
          Connect
        </button>
      </div>
      <!-- Successfully Connected Message -->
      <div class="hidePostingConnect" *ngIf="linkedinConnected || (getLinkedInCode && getLinkedInCode.status === 'success')">
        <div class="social-headings">
          <h2>Successfully Connected!</h2>
          <p>Click the 'Done' button to close this pop-up to go back to Settings.</p>
        </div>
        <div class="social-connect-posted">
          <div class="status-container">
            <div class="success-checkmark">
              <div class="check-icon">
                <span class="icon-line line-tip"></span>
                <span class="icon-line line-long"></span>
                <div class="icon-circle"></div>
                <div class="icon-fix"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</div>
