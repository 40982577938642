import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'humanize'
})
export class HumanizePipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return value;

    // Replace underscores or dashes with spaces and split camelCase
    return value
      .replace(/([a-z])([A-Z])/g, '$1 $2') // Split camelCase
      .replace(/[_-]+/g, ' ') // Replace underscores or dashes with space
      .toLowerCase() // Convert to lowercase
      .replace(/\b\w/g, firstLetter => firstLetter.toUpperCase()); // Capitalize the first letter of each word
  }

}
