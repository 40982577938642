<ng-template let-notificationMSG="data" #statusTmp>
  <div class="ant-notification-notice-content">
    <div class="ant-notification-notice-with-icon">
      <span class="ant-notification-notice-icon position-relative">
        <i nz-icon nzType="check-circle" [style.color]="'#0070f7'" [style.font-size]="'22px'"></i>
      </span>
      <div class="ant-notification-notice-message d-inline" [style.color]="'#0070f7'" [style.font-weight]="'bold'"
        [style.margin-left]="'20px'">
        {{notificationMSG.message}} Successfully!
      </div>
    </div>
  </div>
</ng-template>
<div class="title-header" [ngTemplateOutlet]="SettingsTitle"></div>
<div nz-row class="spacing-top">
  <div nz-col nzSpan="6" class="spacing-right">
    <ul nz-menu>
      <li nz-menu-item (click)="setSideMenu(item)" [class.ant-menu-item-selected]="selectedMenu === item.key"
        *ngFor="let item of sideMenuList;">{{item?.title}}</li>
    </ul>
  </div>
  <div nz-col nzSpan="17" class="white-back">
    <div *ngIf="selectedMenu === 'content_posting'">
      <div [ngTemplateOutlet]="contentPostingList"></div>
    </div>
    <div *ngIf="selectedMenu === 'roi'">
      <h1 class="setting-title">ROI Reports</h1>
      <div [ngTemplateOutlet]="roiReports"></div>
    </div>
    <div *ngIf="selectedMenu === 'styles'">
      <h1 class="setting-title">Content Styles</h1>
      <div [ngTemplateOutlet]="styles"></div>
    </div>
    <div *ngIf="selectedMenu === 'captions'">
      <h1 class="setting-title">Captions</h1>
      <div [ngTemplateOutlet]="captions"></div>
    </div>
    <div *ngIf="selectedMenu !== 'content_posting' && selectedMenu !== 'roi' && selectedMenu !== 'styles' && selectedMenu !== 'captions' && selectedMenu !== 'team_settings' && selectedMenu !== 'billing' && selectedMenu !== 'zentap_card'">
      <h1 class="setting-title">
        {{getSelectedItem(selectedMenuItem.key).titleize()}}
      </h1>
    </div>

    <!-- <div *ngIf="selectedMenu === 'digest_email'">
      <h1 class="setting-title">Digest Email</h1>
      <nz-list [nzDataSource]="digest_settings" [nzRenderItem]="item" [nzItemLayout]="'horizontal'" *ngIf="digest_settings">
        <ng-template #item let-item>
          <nz-list-item class="ant-list-item ng-star-inserted">
            <nz-list-item-meta
              [nzDescription]="nzDescription"
              class="st-list">
              <ng-template #nzDescription>
                <div [ngTemplateOutlet]="digestList"
                     [ngTemplateOutletContext]="{subscription: item}"
                ></div>
              </ng-template>
            </nz-list-item-meta>
          </nz-list-item>
        </ng-template>
      </nz-list>
    </div> -->
    <div *ngIf="selectedMenu === 'MLS_settings'" class="account-settings">
      <nz-spin [nzSize]="'large'" *ngIf="loading">
        <app-mls-connection [model]="customer"></app-mls-connection>
      </nz-spin>
      <nz-spin [nzSize]="'large'" *ngIf="loading">
      </nz-spin>
      <!-- <h1 class="section-title section-title-small">Realtor Details</h1>
      <h5 class="section-description">Information about you including your brokerage and license
        number.
      </h5>
      <app-customer-form [limitShow]="true" #customerForm *ngIf="!loading" [valid]="valid" (retry)="save()"
        (validChange)="this.valid = $event" [showFields]="brokerageShowFields" [model]="customer">
      </app-customer-form>
      <div nz-row nzJustify="end"> -->
      <!-- <button nz-button (click)="cancel()">Cancel</button> -->
      <form #realtorForm="ngForm" nz-form>
        <h1 class="section-title section-title-small">Realtor Details</h1>
        <h5 class="section-description">Information about you including your brokerage and license
          number.
        </h5>
        <div class="customer-form-realtor" nz-row nzType="flex" nzJustify="space-between">
          <nz-form-item class="gutter-row" nz-col [nzSpan]="11">
            <nz-form-label nzFor="brokerage" nzRequired>Brokerage
            </nz-form-label>
            <nz-form-control nzErrorTip="Please input your brokerage!">
              <input placeholder="Brokerage" required nz-input [(ngModel)]="mlsRealtor.brokerage" name="brokerage"
                [nzAutocomplete]="auto" />
              <nz-autocomplete #auto>
                <nz-auto-option *ngFor="let brokerage of brokerageList" [nzValue]="brokerage.title">{{brokerage?.title}}
                </nz-auto-option>
              </nz-autocomplete>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item class="gutter-row" nz-col [nzSpan]="11" nzOffset="2">
            <nz-form-label nzFor="license_number" nzRequired>Realtor License Number
            </nz-form-label>
            <nz-form-control nzErrorTip="Please input your License Number!">
              <input type="text" nz-input type="text" id="license_number" [(ngModel)]="mlsRealtor.license_number"
                name="license_number" required placeholder="Realtor License Number" />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-row nzJustify="end">
          <button nz-button nzType="primary" [disabled]="!realtorForm.valid" class="space-right"
            (click)="saveRealtor()">Save</button>
        </div>
      </form>
      <!-- <nz-divider [style.margin]="'16px'"></nz-divider> -->
      <app-mls-connection [mlsRealtor]="mlsRealtorClone" *ngIf="!loading" [model]="customer"></app-mls-connection>

    </div>
    <div *ngIf="selectedMenu === 'account_details'" class="account-settings">
      <nz-spin [nzSize]="'large'" *ngIf="loading">
        <app-customer-form #customerForm [limitShow]="true" [valid]="valid" [showFields]="showFields" (retry)="save()"
          (validChange)="this.valid = $event" [model]="customer">
        </app-customer-form>
      </nz-spin>
      <app-customer-form [limitShow]="true" #customerForm *ngIf="!loading" [valid]="valid" (retry)="save()"
        (validChange)="this.valid = $event" [showFields]="showFields" [model]="customer">
      </app-customer-form>
      <nz-list>
        <nz-list-item class="ant-list-item ant-subscription-item settings-sub-menu-item" [nzExtra]="narExtra">
          <nz-list-item-meta [nzTitle]="nzTitlecontentnar" class="sub-list setting-list">
            <ng-template #nzTitlecontentnar>
              Part of NAR
            </ng-template>
            <ng-template #narExtra>
              <nz-switch [(ngModel)]="customer.settings['part_of_nar']"
                (ngModelChange)="toggleSetting('part_of_nar', $event)"></nz-switch>
            </ng-template>
          </nz-list-item-meta>
        </nz-list-item>
      </nz-list>
      <br>

      <div nz-row nzJustify="end">
        <!-- <button nz-button (click)="cancel()">Cancel</button> -->
        <button nz-button nzType="primary" class="space-right" (click)="save()">Save</button>
      </div>
    </div>
    <div *ngIf="selectedMenu === 'data_points'" class="account-settings">
      <nz-spin [nzSize]="'large'" *ngIf="loading">
        loading...
      </nz-spin>
      <div *ngIf="!loading">
        <div cdkDropListGroup>
          <nz-collapse>
            <nz-collapse-panel nzHeader="Selected" nzActive="true">
              <div cdkDropList [cdkDropListData]="selectedDataPoints" class="example-list"
                (cdkDropListDropped)="drop($event)">
                <div *ngFor="let dataKey of selectedDataPoints" cdkDrag>
                  <div nz-row class="data-point-item" nz-tooltip nzTooltipColor="red">
                    <div nz-col nzSpan="1">
                      <i nz-icon nzType="drag" nzTheme="outline"></i>
                    </div>
                    <div nz-col nzSpan="10">{{dataPointsTitle[dataKey]}}</div>
                  </div>
                </div>
              </div>
            </nz-collapse-panel>
          </nz-collapse>
          <nz-collapse class="non-selected">
            <nz-collapse-panel nzHeader="Non Selected" nzActive="true">
              <div cdkDropList [cdkDropListData]="nonSelectedDataPoints" class="example-list"
                (cdkDropListDropped)="drop($event)">
                <div [cdkDragDisabled]="selectedDataPoints.length >= 9" class="example-box"
                  *ngFor="let dataKey of nonSelectedDataPoints" cdkDrag>
                  <div nz-row nz-tooltip [nzTooltipTitle]="selectedDataPoints.length >= 9 ? limitMessage: '' "
                    nzTooltipColor="red" [ngStyle]="{'cursor': selectedDataPoints.length >= 9 ? 'no-drop' : 'move'}"
                    class="data-point-item">
                    <div nz-col nzSpan="1">
                      <i nz-icon nzType="drag" nzTheme="outline"></i>
                    </div>
                    <div nz-col nzSpan="10">{{dataPointsTitle[dataKey]}}</div>
                  </div>
                </div>
              </div>
            </nz-collapse-panel>
          </nz-collapse>
        </div>
      </div>
      <!-- <div nz-row nzJustify="end" class="datapoints-action">
          <button nz-button nzType="primary" class="space-right" (click)="saveDataPoints()">Save</button>
        </div> -->
    </div>
    <div *ngIf="selectedMenu !== 'digest_email' && selectedMenu !== 'content_posting'&& selectedMenu !== 'styles' && selectedMenu !== 'account_details' && selectedMenu !== 'data_points' && selectedMenu !== 'MLS_settings' && selectedMenu !== 'captions' &&  selectedMenu !== 'team_settings' && selectedMenu !== 'billing' && selectedMenu !== 'zentap_card'">
      <nz-list [nzDataSource]="filteredSettings" [nzRenderItem]="item" [nzItemLayout]="'horizontal'">
        <ng-template #item let-item>
          <nz-list-item class="ant-list-item ant-subscription-item settings-sub-menu-item bottom-border-none"
            *ngIf="selectedMenu=='listing'" [nzExtra]="nzextra1">
            <nz-list-item-meta [nzTitle]="nzTitle1" [nzDescription]="nzDescription1" class="sub-list setting-list">
              <ng-template #nzTitle1>
                Always show listing price
                <a nz-tooltip nzTooltipTitle="Enables listing content to always have the listing price in the content">
                  <i nz-icon nzType="info-circle" nzTheme="outline" class="info-icon"></i>
                </a>
              </ng-template>
              <ng-template #nzDescription1>
                <div class="subscription-text">
                </div>
              </ng-template>
              <ng-template #nzextra1>
                <nz-switch [(ngModel)]="customer.settings['show_price']"
                  (ngModelChange)="toggleSetting('show_price', $event)"></nz-switch>
              </ng-template>
            </nz-list-item-meta>
          </nz-list-item>
          <nz-list-item class="ant-list-item ng-star-inserted">
            <nz-list-item-meta [nzDescription]="nzDescription" class="st-list">
              <ng-template #nzDescription>
                <div [ngTemplateOutlet]="subscriptionList" [ngTemplateOutletContext]="{subscription: item}"></div>
              </ng-template>
            </nz-list-item-meta>
          </nz-list-item>
        </ng-template>
      </nz-list>
    </div>
    <div *ngIf="selectedMenu === 'billing' && session_url" class="account-settings">
        <nz-list [nzItemLayout]="'horizontal'" class="subscription-list">
          <div class="subscription-list">
            <div style="padding: 16px">
              <div class="email-subscription">
                <nz-list-item class="ant-list-item ant-subscription-item bottom-border-none">
                  <nz-list-item-meta [nzTitle]="nzTitlebill" [nzDescription]="nzDescriptionbill" class="sub-list setting-list">
                    <ng-template #nzTitlebill>

                      <h1 class="setting-title">Billing
                        <a nz-tooltip nzTooltipTitle="You can Change your subscription and view your invoices">
                          <i nz-icon nzType="info-circle" nzTheme="outline" class="info-icon"></i>
                        </a>
                        <p></p>
                      </h1>

                    </ng-template>
                    <ng-template #nzDescriptionbill>
                      <div class="subscription-text">
                        Take Control of your billing details, Click to open your
                        <button nz-button nzDanger class="mgbtm" >
                          <a [href]="session_url" target="_blank">
                            Billing page
                          </a>
                        </button>
                      </div>
                    </ng-template>
                  </nz-list-item-meta>
                </nz-list-item>
              </div>
            </div>
          </div>
        </nz-list>
      <app-payments></app-payments>
    </div>
    <div *ngIf="selectedMenu === 'team_settings'" class="account-settings">
      <nz-list [nzItemLayout]="'horizontal'" class="subscription-list">
        <div class="subscription-list">
          <div style="padding: 16px">
            <div class="email-subscription">
              <nz-list-item class="ant-list-item ant-subscription-item bottom-border-none" [nzExtra]="nzextraTeam">
                <nz-list-item-meta [nzTitle]="nzTitleTeam" [nzDescription]="nzDescriptionTeam" class="sub-list setting-list">
                  <ng-template #nzTitleTeam>

                    <h1 class="setting-title">Team Settings
                      <a nz-tooltip nzTooltipTitle="Whenever we make a piece of content for you It will automatically add team details">
                        <i nz-icon nzType="info-circle" nzTheme="outline" class="info-icon"></i>
                      </a>
                      <p></p>
                    </h1>

                  </ng-template>
                  <ng-template #nzDescriptionTeam>
                    <div class="subscription-text">

                      <nz-alert class="mls-banner" nzType="warning" nzMessage="Whenever we make a piece of content for you It will automatically add team details"
                                nzShowIcon></nz-alert>
                      <br>
                      <nz-alert class="mls-banner" nzType="warning" nzMessage="Now that you've turned your team setting on, be sure to navigate to your Custom Captions and make any additional plural adjustments there!"
                                nzShowIcon *ngIf="customer?.settings['team']"></nz-alert>
                    </div>
                  </ng-template>
                  <ng-template #nzextraTeam>
                    <nz-switch [(ngModel)]="customer?.settings['team']"
                               (ngModelChange)="toggleSetting('team', $event)"></nz-switch>
                  </ng-template>
                </nz-list-item-meta>
              </nz-list-item>
            </div>
          </div>
        </div>
      </nz-list>
    </div>
    <div *ngIf="selectedMenu === 'zentap_card'" class="account-settings">
      <nz-list [nzItemLayout]="'horizontal'" class="subscription-list">
        <div class="subscription-list">
          <div style="padding: 16px">
            <div class="email-subscription">
              <nz-list-item class="ant-list-item ant-subscription-item bottom-border-none">
                <nz-list-item-meta [nzTitle]="nzTitlebill" [nzDescription]="nzDescriptionbill" class="sub-list setting-list">
                  <ng-template #nzTitlebill>

                    <h1 class="setting-title">Zentap Card
                      <a nz-tooltip nzTooltipTitle="You can Change your subscription and view your invoices">
                        <i nz-icon nzType="info-circle" nzTheme="outline" class="info-icon"></i>
                      </a>
                      <p></p>
                    </h1>

                  </ng-template>
                  <ng-template #nzDescriptionbill>
                    <div class="subscription-text">
                      Create/Update your QR Code
                      <input placeholder="your public slug url e.g  app.zentap.com/johndoe" class="slug-input" required nz-input [(ngModel)]="customer.slug" name="slug"/>
                      <button nz-button  nzType="primary" class="mgbtm" (click)="createQrCode()">
                        Save
                      </button>
                    </div>
                    <div>
                      <div class="text-center qr-code"  nz-col>
                        <img [src]="customer.qrcode_url"  width="200px" />
                        <h2>Scan QR or
                          <a (click)="download()">
                            Download
                            <i nz-icon nzType="download" nzTheme="outline"></i></a></h2>
                        <p>to view Zentap Card</p>
                      </div>
                    </div>
                  </ng-template>
                </nz-list-item-meta>
              </nz-list-item>
            </div>
          </div>
        </div>
      </nz-list>

      <nz-list [nzItemLayout]="'horizontal'" class="subscription-list">
        <div class="subscription-list">
          <div style="padding: 16px">
            <div class="email-subscription">
              <nz-list-item class="ant-list-item ant-subscription-item bottom-border-none">
                <nz-list-item-meta [nzTitle]="nzTitlelinks" [nzDescription]="nzDescriptionlinks" class="sub-list setting-list">
                  <ng-template #nzTitlelinks>

                    <h1 class="setting-title">Zentap Card Social Links
                      <a nz-tooltip nzTooltipTitle="Add your Custom links for Social media!">
                        <i nz-icon nzType="info-circle" nzTheme="outline" class="info-icon"></i>
                      </a>
<!--                      <button nz-button  nzType="primary" class="mgbtm" (click)="showSocialLinksForm()">-->
<!--                        Create-->
<!--                      </button>-->
                      <p></p>
                    </h1>

                  </ng-template>
                  <ng-template #nzDescriptionlinks>



                        <div nz-row *ngFor="let link of sociallinks;">
<!--                          <nz-card class="text-center">-->
                            <a  (click)="goToLink(link.data.url)">
                              <img src="../../../assets//icons/{{link.platform}}.png" class="box-image" />
                            </a>
                          <input type="text" class="social-links-input" nz-input type="text" [(ngModel)]="link.data.url" required [disabled]="link.data.integration" />
                          <button nz-button nzType="primary" [disabled]="link.data.integration" class="space-right"
                                  (click)="handleSubmitSocialLink(link);">Save</button>
                        </div>



                  </ng-template>
                </nz-list-item-meta>
              </nz-list-item>
            </div>
          </div>
        </div>
      </nz-list>
    </div>
  </div>
</div>
<ng-template #SettingsTitle>
  <div nz-row>
    <div nz-col nzSpan="24">
      <h1 class="section-title title">
        <i nz-icon nzType="setting"></i>
        Settings
      </h1>
    </div>
  </div>
</ng-template>


<!--      NOTIFICATION SUBSCRIPTONS   -->
<ng-template #subscriptionList let-subscription="subscription">
  <nz-list [nzItemLayout]="'horizontal'" class="subscription-list">
    <nz-list-item class="ant-list-item ant-subscription-item settings-sub-menu-item bottom-border-none"
      [nzExtra]="nzextra2">
      <nz-list-item-meta [nzDescription]="nzDescription2" class="sub-list setting-list">
        <ng-template #nzTitle>
          <!-- {{get_title(subscription.key).titleize()}} -->
          <!-- Notifications -->
        </ng-template>
        <ng-template #nzDescription2>
          <div class="email-subscription">
            <span class="switch-text">Notifications</span>
            <a nz-tooltip [nzTooltipTitle]="toolTips[subscription.key]">
              <i nz-icon nzType="info-circle" nzTheme="outline" class="info-icon"></i>
            </a>
          </div>
        </ng-template>
        <ng-template #nzextra2>
          <nz-switch [(ngModel)]="subscription.subscribing"
            (click)="toggleNotificationSettings(subscription, 'subscription')"></nz-switch>
        </ng-template>
      </nz-list-item-meta>
    </nz-list-item>
    <nz-list-item class="ant-list-item ant-subscription-item settings-sub-menu-item-2 bottom-border-none"
      [nzExtra]="nzextra">
      <nz-list-item-meta [nzTitle]="nzTitle" [nzDescription]="nzDescription" class="sub-list setting-list">
        <ng-template #nzTitle>
          <!-- {{getSelectedItem(subscription.key).titleize()}} -->
          <!-- Notifications -->
        </ng-template>
        <ng-template #nzDescription>
          <!-- <div class="subscription-text"> -->
          <!--                  Turning this off will hide the price of your listing on any content relating to a listing being sold. (i.e. a congrats video or sold video)-->
          <!-- </div> -->
          <div class="email-subscription">
            <!-- <nz-switch [(ngModel)]="subscription.subscribing_to_email" (click)="toggleNotificationSettings(subscription, 'email')"></nz-switch> -->
            <span class="switch-text heading-1">Email Notifications</span>
            <a nz-tooltip [nzTooltipTitle]="toolTips[subscription.key]">
              <i nz-icon nzType="info-circle" nzTheme="outline" class="info-icon"></i>
            </a>
          </div>
        </ng-template>
        <ng-template #nzextra>
          <nz-switch [(ngModel)]="subscription.subscribing_to_email" [nzDisabled]="!subscription.subscribing"
            (click)="toggleNotificationSettings(subscription, 'email')"></nz-switch>
        </ng-template>
      </nz-list-item-meta>
    </nz-list-item>

    <nz-list-item class="ant-list-item ant-subscription-item settings-sub-menu-item-2 bottom-border-none"
      [nzExtra]="nzextra" *ngIf="subscription && subscription.optional_targets.sms">
      <nz-list-item-meta [nzDescription]="nzDescription" class="sub-list setting-list">
        <ng-template #nzDescription>
          <div class="sms-subscription">
            <span class="switch-text heading-1">SMS Notifications</span>
          </div>
        </ng-template>
        <ng-template #nzextra>
          <nz-switch [(ngModel)]="subscription.optional_targets && subscription.optional_targets.sms.subscribing"
            [nzDisabled]="!subscription.subscribing" (click)="toggleNotificationSettings(subscription, 'sms')">
          </nz-switch>
        </ng-template>
      </nz-list-item-meta>
    </nz-list-item>
  </nz-list>
</ng-template>
<!--      CONTENT POSTING SUBSCRIPTONS   -->
<ng-template #contentPostingList>
  <div [class.disabled]="customer.render_only && customer.account_name=='HOI'" >
    <nz-list [nzItemLayout]="'horizontal'" class="subscription-list">

    <!--    facebook, insta, linkedin -->
    <h1 class="setting-title padding-left-title">Your Platforms
      <a nz-tooltip nzTooltipTitle="Available platforms to connect for your content posting!">
        <i nz-icon nzType="info-circle" nzTheme="outline" class="info-icon"></i>
      </a>
    </h1>
      <nz-list-item class="ant-list-item ant-subscription-item bottom-border-none" [nzExtra]="nzextra">
        <nz-list-item-meta [nzTitle]="nzTitle" [nzDescription]="nzDescription" class="sub-list setting-list">
          <ng-template #nzTitle>

            <h1 class="setting-title">Autopost Settings
              <a nz-tooltip nzTooltipTitle="Whenever we make a piece of content for you It will automatically post">
                <i nz-icon nzType="info-circle" nzTheme="outline" class="info-icon"></i>
              </a>
            </h1>

          </ng-template>
          <ng-template #nzDescription>
            <div class="subscription-text">
            </div>
          </ng-template>
          <ng-template #nzextra>
            <nz-switch [(ngModel)]="customer.settings['auto_posting']"
                       (ngModelChange)="toggleSetting('auto_posting', $event)"></nz-switch>
          </ng-template>
        </nz-list-item-meta>
      </nz-list-item>
    <div nz-row>
      <!-- Facebook Card -->
      <div nz-col nzSpan="8">

        <nz-card class="text-center" *ngIf="manualFacebookIntegrationStatus">
          <img src="../../../assets//icons/facebook.png" class="box-image" />
          <br>
          <button nz-button nzType="primary" class="mgbtm" disabled>Managed By Zentap</button>

          <br>
          <span class="heading-2 ng-star-inserted"></span>
          <br>
          <br>
          <div class="toggle-container">
            <nz-spin [nzSpinning]="loadingState.facebook">
              <nz-switch [(ngModel)]="manualFacebookAutopost" (ngModelChange)="onServiceAutoPostSwitchChange('facebook',$event)"
                [nzDisabled]="!customer.settings['auto_posting']"></nz-switch>
            </nz-spin>
            <span class="toggle-label">Auto Post</span>
          </div>
        </nz-card>

        <nz-card class="text-center" *ngIf="!manualFacebookIntegrationStatus">
          <img src="../../../assets//icons/facebook.png" class="box-image" />
          <br>
          <button nz-button nzType="primary" class="mgbtm" (click)="connectFacebook()">{{ isFacebookPageConnected ?
            "Refresh" : "Connect"}}</button>
          <button nz-button nzDanger class="mgbtm" *ngIf=" isFacebookPageConnected && facebookPageIntegration" style="margin-left: 10px;"
                  (click)="deleteIntegration(facebookPageIntegration)">Remove</button>


          <br>
          <!--          <span *ngIf="facebookPageIntegration" class="heading-2 ng-star-inserted">{{expired(facebookPageIntegration)}}</span>-->
          <br>
          <br>
          <div class="toggle-container">
            <nz-spin [nzSpinning]="loadingState.facebook">
              <nz-switch [(ngModel)]="facebookPageAutopost" (ngModelChange)="onServiceAutoPostSwitchChange('facebook',$event)"
                [nzDisabled]="!isFacebookPageConnected"></nz-switch>
            </nz-spin>
            <span class="toggle-label">Auto Post</span>
          </div>
        </nz-card>

      </div>

      <!-- LinkedIn Card -->
      <div nz-col nzSpan="8">
        <nz-card class="text-center">
          <img src="../../../assets//icons/linkedin.png" class="box-image" />
          <br>
          <button *ngIf="linkedinStatus" nz-button nzType="primary" class="mgbtm" [disabled]="!linkedinStatus"
            (click)="connectLinkedin()">{{
            integration ? "Refresh": "Connect"}}</button>

          <button *ngIf="!linkedinStatus" nz-button nzType="primary" class="mgbtm"
            (click)="upgradePlan()">Upgrade</button>
          <button nz-button nzDanger class="mgbtm" *ngIf=" integration && linkedinStatus" style="margin-left: 10px;"
                  (click)="deleteIntegration(integration)">Remove</button>

          <br>
          <span *ngIf="integration" class="heading-2 ng-star-inserted">{{expired(integration, true)}}</span>
          <br>
          <br>
          <div class="toggle-container">
            <nz-spin [nzSpinning]="loadingState.linkedIn">
              <nz-switch [(ngModel)]="linkedinAutopost" (ngModelChange)="onServiceAutoPostSwitchChange('linkedIn',$event)"
                [nzDisabled]="!linkedinStatus || !integration"></nz-switch>
            </nz-spin>
            <span class="toggle-label">Auto Post</span>
          </div>
        </nz-card>
      </div>

      <!-- Instagram Card -->
      <div nz-col nzSpan="8">
        <nz-card class="text-center">
          <img src="../../../assets//icons/instagram.png" class="box-image" />
          <br>
          <button nz-button nzType="primary" class="mgbtm" *ngIf="instagramStatus && !instagramIntegration"
            (click)="connectInstagram()">{{ "Connect"}}</button>

          <button nz-button nz-dropdown nzType="primary"  class="mgbtm" [nzDropdownMenu]="menu1" *ngIf="instagramStatus && instagramIntegration">Refresh
            <i nz-icon nzType="down"></i>
          </button>

          <nz-dropdown-menu #menu1="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-item (click)="connectFacebook()">Refresh Connection</li>
              <li nz-menu-item (click)="refreshIgName()">Refresh IG Name</li>
            </ul>
          </nz-dropdown-menu>

          <!-- <button nz-button nzType="primary" class="mgbtm" *ngIf="instagramStatus && !instagramIntegration"
           (click)="connectInstagram()">{{ "Connect Instagram
            Page" }}</button> -->

            <button *ngIf="!instagramStatus" nz-button nzType="primary" class="mgbtm"
            (click)="upgradePlan()">Upgrade</button>
          <button nz-button nzDanger class="mgbtm" *ngIf="instagramIntegration && instagramStatus" style="margin-left: 10px;"
                  (click)="deleteIntegration(instagramIntegration)">Remove</button>

          <br>
          <!--          <span *ngIf="facebookPageIntegration" class="heading-2 ng-star-inserted">{{expired(facebookPageIntegration)}}</span>-->
          <br>
          <br>
          <div class="toggle-container">
            <nz-spin [nzSpinning]="loadingState.instagram">
              <nz-switch [(ngModel)]="instagramAutopost" (ngModelChange)="onServiceAutoPostSwitchChange('instagram', $event)"
                [nzDisabled]="!instagramStatus || !instagramIntegration"></nz-switch>
            </nz-spin>
            <span class="toggle-label">Auto Post</span>
          </div>
        </nz-card>
      </div>

      <!-- Google Card -->
      <div nz-col nzSpan="8">
        <nz-card class="text-center">
          <img src="../../../assets//icons/google.png" class="box-image" />
          <br>
          <button nz-button nzType="primary" class="mgbtm" *ngIf="!isGoogleAccountConnected && isAdmin && googleConnectedMydBusinessStatus"
             (click)="connectGoogle()">{{ "Connect"}}</button>

          <button nz-button nzType="primary" class="mgbtm" *ngIf="googleConnectedMydBusinessStatus && isAdmin && googlePageIntegration"
             (click)="connectGoogle()">{{ "Refresh" }}</button>

          <button nz-button nzType="primary" class="mgbtm"
            *ngIf="!googleConnectedMydBusinessStatus && isGoogleAccountConnected && isAdmin"
            (click)="connectGoogle()">{{ "Connect Google My Business" }}</button>
          <button nz-button nzType="primary" class="mgbtm" *ngIf="!isAdmin" [disabled]="!isAdmin">{{ "Managed by zentap"
            }}</button>
          <p class="mgbtm connected" *ngIf="!isAdmin && isGoogleAccountConnected">Connected</p>

          <button *ngIf="!googleConnectedMydBusinessStatus" nz-button nzType="primary" class="mgbtm"
                  (click)="upgradePlan()">Upgrade</button>
          <button nz-button nzDanger class="mgbtm" *ngIf="isGoogleAccountConnected && googleConnectedMydBusinessStatus" style="margin-left: 10px;"
                  (click)="deleteIntegration(googlePageIntegration)">Remove</button>

<!--          <p class="mgbtm not-connected" *ngIf="!isAdmin && !isGoogleAccountConnected">Not Connected!</p>-->
<!--          <br>-->
          <!-- <span *ngIf="googlePageIntegration" class="heading-2 ng-star-inserted">{{expired(googlePageIntegration)}}</span> -->
          <br>
          <br>
          <br>
          <div class="toggle-container">
            <nz-spin [nzSpinning]="loadingState.google">
              <nz-switch [(ngModel)]="googleBusinessAutopost" (ngModelChange)="onServiceAutoPostSwitchChange('google', $event)"
                [nzDisabled]="!googleConnectedMydBusinessStatus || !googlePageIntegration">
              </nz-switch>
            </nz-spin>
            <span class="toggle-label">Auto Post</span>
          </div>
        </nz-card>
      </div>

      <!-- Youtube Card -->
      <div nz-col nzSpan="8">
        <nz-card class="text-center">
          <img src="../../../assets//icons/youtube.png" class="yt-box-image" />
          <br>
          <button nz-button nzType="primary" class="mgbtm" *ngIf="!isYoutubeConnected && youtubeStatus"
                   (click)="connectYoutube()">{{ "Connect"}}</button>

          <button nz-button nzType="primary" class="mgbtm" *ngIf="isYoutubeConnected && youtubeStatus"
                  (click)="connectYoutube()">{{ "Refresh" }}</button>

          <button *ngIf="!youtubeStatus" nz-button nzType="primary" class="mgbtm"
                  (click)="upgradePlan()">Upgrade</button>
          <button nz-button nzDanger class="mgbtm" *ngIf="isYoutubeConnected && youtubeStatus" style="margin-left: 10px;"
                  (click)="deleteIntegration(youtubeIntegration)">Remove</button>
          <br>
          <br>
          <br>
          <div class="toggle-container">
            <nz-spin [nzSpinning]="loadingState.youtube">
              <nz-switch [(ngModel)]="youtubeAutopost" (ngModelChange)="onServiceAutoPostSwitchChange('youtube', $event)"
                         [nzDisabled]="!isYoutubeConnected">
              </nz-switch>
            </nz-spin>
            <span class="toggle-label">Auto Post</span>
          </div>
        </nz-card>
      </div>

      <!-- TikTok Card -->
      <div nz-col nzSpan="8">
        <nz-card class="text-center">
          <img src="../../../assets//icons/tiktok.png" class="yt-box-image" />
          <br>
          <button nz-button nzType="primary" class="mgbtm" *ngIf="!isTiktokConnected && tiktokStatus"
                   (click)="connectTiktok()">{{ "Connect"}}</button>

          <button nz-button nzType="primary" class="mgbtm" *ngIf="isTiktokConnected && tiktokStatus"
                  (click)="connectTiktok()">{{ "Refresh" }}</button>

          <button *ngIf="!tiktokStatus" nz-button nzType="primary" class="mgbtm"
                  (click)="upgradePlan()">Upgrade</button>
          <button nz-button nzDanger class="mgbtm" *ngIf="isTiktokConnected && tiktokStatus" style="margin-left: 10px;"
                  (click)="deleteIntegration(tiktokIntegration)">Remove</button>
          <br>
          <br>
          <br>          
          <div class="toggle-container">
            <nz-spin [nzSpinning]="loadingState.tiktok">
              <nz-switch [(ngModel)]="tiktokAutopost" (ngModelChange)="onServiceAutoPostSwitchChange('tiktok', $event)"
                          [nzDisabled]="!isTiktokConnected">
              </nz-switch>
            </nz-spin>
            <span class="toggle-label">Auto Post</span>
          </div>
        </nz-card>
      </div>
    </div>
    <!--    facebook, insta, linkedin -->

    <nz-list-item class="ant-list-item ant-subscription-item settings-sub-menu-item bottom-border-none"
      [nzExtra]="nzextraAutoPosting">
      <nz-list-item-meta [nzTitle]="nzTitle3" [nzDescription]="nzDescription3" class="sub-list setting-list">
        <!--        <ng-template #autoPostMarket>-->
        <!--          <div class="heading-1">-->
        <!--            Auto Posting Market Reports-->
        <!--          </div>-->
        <!--        </ng-template>-->
        <ng-template #nzTitle3>
          <div>
            Auto Posting Market Reports
            <a nz-tooltip
              nzTooltipTitle="Automatically posts all local market content created for your. Turning this off will enable a review process where you can view the content before it goes out and change the data on your content.">
              <i nz-icon nzType="info-circle" nzTheme="outline" class="info-icon"></i>
            </a>
          </div>
        </ng-template>
        <ng-template #nzDescription3>
          <div class="subscription-text">
          </div>
        </ng-template>
        <ng-template #nzextraAutoPosting>
          <nz-switch [(ngModel)]="customer.settings['market_report_auto_posting']"
            (ngModelChange)="toggleSetting('market_report_auto_posting', $event)"
            [hidden]="!customer.settings['auto_posting']"></nz-switch>
        </ng-template>
      </nz-list-item-meta>
    </nz-list-item>
    <nz-list-item class="ant-list-item ant-subscription-item settings-sub-menu-item bottom-border-none"
                    [nzExtra]="nzextraAutoPostingFlyers">
        <nz-list-item-meta [nzTitle]="nzTitle4" [nzDescription]="nzDescription3" class="sub-list setting-list">
          <!--        <ng-template #autoPostMarket>-->
          <!--          <div class="heading-1">-->
          <!--            Auto Posting Market Reports-->
          <!--          </div>-->
          <!--        </ng-template>-->
          <ng-template #nzTitle4>
            <div>
              Auto Posting Marketing Flyers
              <a nz-tooltip
                 nzTooltipTitle="Automatically posts all marketing Flyers content created for your. Turning this off will enable a review process where you can view the content before it goes out and change the data on your content.">
                <i nz-icon nzType="info-circle" nzTheme="outline" class="info-icon"></i>
              </a>
            </div>
          </ng-template>
          <ng-template #nzDescription3>
            <div class="subscription-text">
            </div>
          </ng-template>
          <ng-template #nzextraAutoPostingFlyers>
            <nz-switch [(ngModel)]="customer.settings['marketing_flyer_auto_posting']"
                       (ngModelChange)="toggleSetting('marketing_flyer_auto_posting', $event)"
                       [hidden]="!customer.settings['auto_posting']"></nz-switch>
          </ng-template>
        </nz-list-item-meta>
      </nz-list-item>
    <nz-list-item class="ant-list-item ant-subscription-item settings-sub-menu-item bottom-border-none"
                    [nzExtra]="nzextraAutoPostingSingleData">
        <nz-list-item-meta [nzTitle]="nzTitle5" [nzDescription]="nzDescription3" class="sub-list setting-list">
          <!--        <ng-template #autoPostMarket>-->
          <!--          <div class="heading-1">-->
          <!--            Auto Posting Market Reports-->
          <!--          </div>-->
          <!--        </ng-template>-->
          <ng-template #nzTitle5>
            <div>
              Auto Posting Single Data Snapshot
              <a nz-tooltip
                 nzTooltipTitle="Automatically posts all Single Data Snapshot content created for your. Turning this off will enable a review process where you can view the content before it goes out and change the data on your content.">
                <i nz-icon nzType="info-circle" nzTheme="outline" class="info-icon"></i>
              </a>
            </div>
          </ng-template>
          <ng-template #nzDescription3>
            <div class="subscription-text">
            </div>
          </ng-template>
          <ng-template #nzextraAutoPostingSingleData>
            <nz-switch [(ngModel)]="customer.settings['single_data_snapshot_auto_posting']"
                       (ngModelChange)="toggleSetting('single_data_snapshot_auto_posting', $event)"
                       [hidden]="!customer.settings['auto_posting']"></nz-switch>
          </ng-template>
        </nz-list-item-meta>
      </nz-list-item>
    <nz-list-item class="ant-list-item ant-subscription-item settings-sub-menu-item bottom-border-none"
      [nzExtra]="nzextraholiday">
      <nz-list-item-meta [nzTitle]="nzTitleHoliday" [nzDescription]="nzDescriptionholiday"
        class="sub-list setting-list">
        <ng-template #nzTitleHoliday>
          Autopost Holiday Content
          <a nz-tooltip nzTooltipTitle="You can decide wether or not we post holiday related content on your behalf">
            <i nz-icon nzType="info-circle" nzTheme="outline" class="info-icon"></i>
          </a>
        </ng-template>
        <ng-template #nzDescriptionholiday>
          <div class="subscription-text">
          </div>
        </ng-template>
        <ng-template #nzextraholiday>
          <nz-switch [(ngModel)]="customer.settings['holiday_auto_posting']"
            (ngModelChange)="toggleSetting('holiday_auto_posting', $event)"
            [hidden]="!customer.settings['auto_posting']"></nz-switch>
        </ng-template>
      </nz-list-item-meta>
    </nz-list-item>
    <nz-list-item class="ant-list-item ant-subscription-item settings-sub-menu-item" [nzExtra]="nzextra3">
      <nz-list-item-meta [nzTitle]="nzTitleMLS" [nzDescription]="nzDescriptionMLS" class="sub-list setting-list">

        <ng-template #nzTitleMLS>
          <div>
            MLS Listing Auto Post
            <a nz-tooltip nzTooltipTitle="Autopost listing video whenever an active listing comes in from the mls">
              <i nz-icon nzType="info-circle" nzTheme="outline" class="info-icon"></i>
            </a>
          </div>
        </ng-template>
        <ng-template #nzDescriptionMLS>
          <div class="subscription-text">
          </div>
        </ng-template>
        <ng-template #nzextra3>
          <nz-switch [(ngModel)]="customer.settings['mls_listing_video_auto_posting']"
            (ngModelChange)="toggleSetting('mls_listing_video_auto_posting', $event)"
            [hidden]="!customer.settings['auto_posting']"></nz-switch>
        </ng-template>
      </nz-list-item-meta>
    </nz-list-item>
    <nz-list-item class="ant-list-item ant-subscription-item settings-sub-menu-item" [nzExtra]="nzextraMarketingVideo">
      <nz-list-item-meta [nzTitle]="nzTitleMarketingVideo" [nzDescription]="nzDescriptionMarketingVideo"
        class="sub-list setting-list">

        <ng-template #nzTitleMarketingVideo>
          <div>
            Marketing Video Auto Post
            <a nz-tooltip
              nzTooltipTitle="Automatically posts all Marketing video content created for you. Turning this off will require you to post these manually.">
              <i nz-icon nzType="info-circle" nzTheme="outline" class="info-icon"></i>
            </a>
          </div>
        </ng-template>
        <ng-template #nzDescriptionMarketingVideo>
          <div class="subscription-text">
          </div>
        </ng-template>
        <ng-template #nzextraMarketingVideo>
          <nz-switch [(ngModel)]="customer.settings['marketing_video_auto_posting']"
            (ngModelChange)="toggleSetting('marketing_video_auto_posting', $event)"
            [hidden]="!customer.settings['auto_posting']"></nz-switch>
        </ng-template>
      </nz-list-item-meta>
    </nz-list-item>
    <nz-list-item class="ant-list-item ant-subscription-item settings-sub-menu-item" [nzExtra]="informercialExtra">
      <nz-list-item-meta [nzTitle]="informercialTitle" [nzDescription]="informercialDesc"
                         class="sub-list setting-list">

        <ng-template #informercialTitle>
          <div>
            Infomercial Auto Posting
            <a nz-tooltip
               nzTooltipTitle="Automatically posts all Infomercial video content created for you. Turning this off will require you to post these manually.">
              <i nz-icon nzType="info-circle" nzTheme="outline" class="info-icon"></i>
            </a>
          </div>
        </ng-template>
        <ng-template #informercialDesc>
          <div class="subscription-text">
          </div>
        </ng-template>
        <ng-template #informercialExtra>
          <nz-switch [(ngModel)]="customer.settings['infomercial_auto_posting']"
                     (ngModelChange)="toggleSetting('infomercial_auto_posting', $event)"
                     [hidden]="!customer.settings['auto_posting']"></nz-switch>
        </ng-template>
      </nz-list-item-meta>
    </nz-list-item>
      <nz-list-item class="ant-list-item ant-subscription-item settings-sub-menu-item" [nzExtra]="testimonialExtra">
        <nz-list-item-meta [nzTitle]="testimonial" [nzDescription]="testimonialDesc"
                           class="sub-list setting-list">

          <ng-template #testimonial>
            <div>
              Testimonial Auto Posting
              <a nz-tooltip
                 nzTooltipTitle="Automatically posts all Infomercial video content created for you. Turning this off will require you to post these manually.">
                <i nz-icon nzType="info-circle" nzTheme="outline" class="info-icon"></i>
              </a>
            </div>
          </ng-template>
          <ng-template #testimonialDesc>
            <div class="subscription-text">
            </div>
          </ng-template>
          <ng-template #testimonialExtra>
            <nz-switch [(ngModel)]="customer.settings['testimony_auto_posting']"
                       (ngModelChange)="toggleSetting('testimony_auto_posting', $event)"
                       [hidden]="!customer.settings['auto_posting']"></nz-switch>
          </ng-template>
        </nz-list-item-meta>
      </nz-list-item>

    <nz-list-item class="ant-list-item ant-subscription-item bottom-border-none">
      <nz-list-item-meta [nzTitle]="nzTitleContentPosting" class="sub-list setting-list">
        <ng-template #nzTitleContentPosting>
          <h1 class="setting-title">Content and Posting
            <a nz-tooltip>
              <i nz-icon nzType="info-circle" nzTheme="outline" class="info-icon"></i>
            </a>
          </h1>
        </ng-template>
      </nz-list-item-meta>
    </nz-list-item>

    <!--    <nz-list-item class="ant-list-item ant-subscription-item settings-sub-menu-item bottom-border-none" [nzExtra]="nzextra1">-->
    <!--      <nz-list-item-meta [nzTitle]="nzTitle1" [nzDescription]="nzDescription1" class="sub-list setting-list">-->
    <!--        <ng-template #nzTitle1>-->
    <!--          Always show listing price-->
    <!--          <a nz-tooltip nzTooltipTitle="Enables listing content to always have the listing price in the content">-->
    <!--            <i nz-icon nzType="info-circle" nzTheme="outline" class="info-icon"></i>-->
    <!--          </a>-->
    <!--        </ng-template>-->
    <!--        <ng-template #nzDescription1>-->
    <!--          <div class="subscription-text">-->
    <!--          </div>-->
    <!--        </ng-template>-->
    <!--        <ng-template #nzextra1>-->
    <!--          <nz-switch [(ngModel)]="customer.settings['show_price']"-->
    <!--            (ngModelChange)="toggleSetting('show_price', $event)"></nz-switch>-->
    <!--        </ng-template>-->
    <!--      </nz-list-item-meta>-->
    <!--    </nz-list-item>-->

    <nz-list-item
      class="ant-list-item ant-subscription-item settings-sub-menu-item bottom-border-none subscription-header bottom-border-none">
      <nz-list-item-meta [nzTitle]="nzTitleMarket" [nzDescription]="nzDescription3" class="sub-list setting-list">
        <ng-template #nzTitleMarket>
          <div>
            Market Report
            <a nz-tooltip
              nzTooltipTitle="Automatically posts all local market content created for your. Turning this off will enable a review process where you can view the content before it goes out and change the data on your content.">
              <i nz-icon nzType="info-circle" nzTheme="outline" class="info-icon"></i>
            </a>
          </div>
        </ng-template>
      </nz-list-item-meta>
    </nz-list-item>
    <nz-list-item
      class="ant-list-item ant-subscription-item settings-sub-menu-item-2 settings-sub-menu-item bottom-border-none"
      [nzExtra]="mrextranotification" *ngIf="marketReportSubscription">
      <nz-list-item-meta [nzTitle]="mrnotification" class="sub-list setting-list">
        <ng-template #mrnotification>
          <div class="heading-1">
            App Notifications
          </div>
        </ng-template>

        <ng-template #mrextranotification>
          <nz-switch [(ngModel)]="marketReportSubscription.subscribing"
            (ngModelChange)="toggleNotificationSettings(marketReportSubscription, 'subscription')"></nz-switch>
        </ng-template>
      </nz-list-item-meta>
    </nz-list-item>
    <nz-list-item class="ant-list-item ant-subscription-item settings-sub-menu-item-2"
      [nzExtra]="mrextraemailnotification" *ngIf="marketReportSubscription">
      <nz-list-item-meta [nzTitle]="mremailnotification" class="sub-list setting-list">
        <ng-template #mremailnotification>
          <div class="heading-1">
            Email Notifications
          </div>
        </ng-template>
        <ng-template #mrextraemailnotification>
          <nz-switch [nzDisabled]="!marketReportSubscription.subscribing"
            [(ngModel)]="marketReportSubscription.subscribing_to_email"
            (ngModelChange)="toggleNotificationSettings(marketReportSubscription, 'email')"></nz-switch>
        </ng-template>
      </nz-list-item-meta>
    </nz-list-item>
    <!-- <nz-list-item class="ant-list-item ant-subscription-item" [nzExtra]="contentextra" *ngIf="contentSubscription">
      <nz-list-item-meta [nzTitle]="nzTitle3" [nzDescription]="nzDescription3" class="sub-list setting-list">
        <ng-template #nzTitle3>
          Content Update
          <a nz-tooltip nzTooltipTitle="Notifies and shares the content with you whenever a piece of content is created for you">
            <i nz-icon nzType="info-circle" nzTheme="outline" class="info-icon"></i>
          </a>
        </ng-template>
        <ng-template #nzDescription3>
          <div class="subscription-text">
          </div>
        </ng-template>
        <ng-template #contentextra>
          <nz-switch [(ngModel)]="contentSubscription.subscribing" (ngModelChange)="toggleNotificationSettings(contentSubscription, 'subscription')"></nz-switch>


        </ng-template>
      </nz-list-item-meta>
    </nz-list-item> -->


    <nz-list-item
      class="ant-list-item ant-subscription-item settings-sub-menu-item bottom-border-none subscription-header bottom-border-none">
      <nz-list-item-meta [nzTitle]="postingtitle" class="sub-list setting-list">
        <ng-template #postingtitle>
          <div>
            Posting
            <a nz-tooltip
              nzTooltipTitle="Automatically posts all local market content created for your. Turning this off will enable a review process where you can view the content before it goes out and change the data on your content.">
              <i nz-icon nzType="info-circle" nzTheme="outline" class="info-icon"></i>
            </a>
          </div>
        </ng-template>
      </nz-list-item-meta>
    </nz-list-item>
    <nz-list-item
      class="ant-list-item ant-subscription-item settings-sub-menu-item-2 settings-sub-menu-item bottom-border-none"
      [nzExtra]="nzextra2" *ngIf="contentSubscription">
      <nz-list-item-meta [nzTitle]="nzTitlecontent1" [nzDescription]="nzDescription2" class="sub-list setting-list">
        <ng-template #nzTitlecontent1>
          <div class="heading-1">
            App Notifications

          </div>
          <!-- <a nz-tooltip [nzTooltipTitle]="toolTips[contentSubscription.key]">
            <i nz-icon nzType="info-circle" nzTheme="outline" class="info-icon"></i>
          </a> -->
        </ng-template>
        <ng-template #nzextra2>
          <nz-switch [(ngModel)]="contentSubscription.subscribing"
            (ngModelChange)="toggleNotificationSettings(contentSubscription, 'subscription')"></nz-switch>
        </ng-template>
      </nz-list-item-meta>
    </nz-list-item>
    <nz-list-item class="ant-list-item ant-subscription-item settings-sub-menu-item-2 settings-sub-menu-item-2"
      [nzExtra]="nzextra" *ngIf="contentSubscription">
      <nz-list-item-meta [nzTitle]="nzTitlecontent2" [nzDescription]="nzDescription" class="sub-list setting-list">
        <ng-template #nzTitlecontent2>
          <div class="heading-1">
            Email Notifications
          </div>
          <!-- <a nz-tooltip [nzTooltipTitle]="toolTips[contentSubscription.key]">
            <i nz-icon nzType="info-circle" nzTheme="outline" class="info-icon"></i>
          </a> -->
        </ng-template>
        <ng-template #nzextra>
          <nz-switch [(ngModel)]="contentSubscription.subscribing_to_email"
            [nzDisabled]="!contentSubscription.subscribing"
            (ngModelChange)="toggleNotificationSettings(contentSubscription, 'email')"></nz-switch>
        </ng-template>
      </nz-list-item-meta>
    </nz-list-item>
<!--      <nz-list-item-->
<!--        class="ant-list-item ant-subscription-item settings-sub-menu-item bottom-border-none subscription-header bottom-border-none">-->
<!--        <nz-list-item-meta [nzTitle]="failedpostingtitle" class="sub-list setting-list">-->
<!--          <ng-template #failedpostingtitle>-->
<!--            <div>-->
<!--              Post Failed-->
<!--              <a nz-tooltip-->
<!--                 nzTooltipTitle="Automatically posts all local market content created for your. Turning this off will enable a review process where you can view the content before it goes out and change the data on your content.">-->
<!--                <i nz-icon nzType="info-circle" nzTheme="outline" class="info-icon"></i>-->
<!--              </a>-->
<!--            </div>-->
<!--          </ng-template>-->
<!--        </nz-list-item-meta>-->
<!--      </nz-list-item>-->
<!--      <nz-list-item class="ant-list-item ant-subscription-item settings-sub-menu-item-2 settings-sub-menu-item-2"-->
<!--                    [nzExtra]="nzextra" *ngIf="postFailed">-->
<!--        <nz-list-item-meta [nzTitle]="nzTitlecontent2" [nzDescription]="nzDescription" class="sub-list setting-list">-->
<!--          <ng-template #nzTitlecontent2>-->
<!--            <div class="heading-1">-->
<!--              Email Notifications-->
<!--            </div>-->
<!--            &lt;!&ndash; <a nz-tooltip [nzTooltipTitle]="toolTips[contentSubscription.key]">-->
<!--              <i nz-icon nzType="info-circle" nzTheme="outline" class="info-icon"></i>-->
<!--            </a> &ndash;&gt;-->
<!--          </ng-template>-->
<!--          <ng-template #nzextra>-->
<!--            <nz-switch [(ngModel)]="postFailed.subscribing_to_email"-->
<!--                       [nzDisabled]="!postFailed.subscribing"-->
<!--                       (ngModelChange)="toggleNotificationSettings(postFailed, 'email')"></nz-switch>-->
<!--          </ng-template>-->
<!--        </nz-list-item-meta>-->
<!--      </nz-list-item>-->

    <nz-list-item class="ant-list-item ant-subscription-item settings-sub-menu-item-2" [nzExtra]="nzextra"
      *ngIf="contentSubscription && contentSubscription.optional_targets && contentSubscription.optional_targets.sms">
      <nz-list-item-meta [nzTitle]="nzTitlecontent3" [nzDescription]="nzDescription" class="sub-list setting-list">
        <ng-template #nzTitlecontent3>
          <div class="heading-1">
            SMS Notifications
          </div>
          <a nz-tooltip [nzTooltipTitle]="toolTips[contentSubscription.key]">
            <i nz-icon nzType="info-circle" nzTheme="outline" class="info-icon"></i>
          </a>
        </ng-template>
        <ng-template #nzextra>
          <nz-switch [(ngModel)]="contentSubscription.optional_targets.sms.subscribing"
            (click)="toggleNotificationSettings(contentSubscription, 'sms')"></nz-switch>
        </ng-template>
      </nz-list-item-meta>
    </nz-list-item>
  </nz-list>
  </div>
</ng-template>

<ng-template #roiReports>
  <nz-list [nzItemLayout]="'horizontal'" class="subscription-list" *ngIf="roiSubscription">
    <nz-list-item class="ant-list-item ant-subscription-item settings-sub-menu-item bottom-border-none"
      [nzExtra]="nzextra2">
      <nz-list-item-meta [nzDescription]="nzDescription2" class="sub-list setting-list">
        <ng-template #nzTitle>
        </ng-template>
        <ng-template #nzDescription2>
          <div class="email-subscription">
            <span class="switch-text">Notifications</span>
            <a nz-tooltip nzTooltipTitle="We will alert you in-app when your monthly report is ready to view">
              <i nz-icon nzType="info-circle" nzTheme="outline" class="info-icon"></i>
            </a>
          </div>
        </ng-template>
        <ng-template #nzextra2>
          <nz-switch [(ngModel)]="roiSubscription.subscribing"
            (click)="toggleNotificationSettings(roiSubscription, 'subscription')"></nz-switch>
        </ng-template>
      </nz-list-item-meta>
    </nz-list-item>
    <nz-list-item class="ant-list-item ant-subscription-item settings-sub-menu-item-2" [nzExtra]="nzextra">
      <nz-list-item-meta [nzTitle]="nzTitle" [nzDescription]="nzDescription" class="sub-list setting-list">
        <ng-template #nzTitle>
        </ng-template>
        <ng-template #nzDescription>
          <div class="email-subscription">
            <span class="switch-text heading-1">Email Notifications</span>
            <a nz-tooltip
              nzTooltipTitle="We will send you an email of your report to you whenever your monthly report is ready to view">
              <i nz-icon nzType="info-circle" nzTheme="outline" class="info-icon"></i>
            </a>
          </div>
        </ng-template>
        <ng-template #nzextra>
          <nz-switch [(ngModel)]="roiSubscription.subscribing_to_email" [nzDisabled]="!roiSubscription.subscribing"
            (click)="toggleNotificationSettings(roiSubscription, 'email')"></nz-switch>
        </ng-template>
      </nz-list-item-meta>
    </nz-list-item>

    <nz-list-item class="ant-list-item ant-subscription-item settings-sub-menu-item-2" [nzExtra]="nzextra"
      *ngIf="roiSubscription && roiSubscription.optional_targets.sms">
      <nz-list-item-meta [nzDescription]="nzDescription" class="sub-list setting-list">
        <ng-template #nzDescription>
          <div class="sms-subscription">
            <span class="switch-text heading-1">SMS Notifications</span>
          </div>
        </ng-template>
        <ng-template #nzextra>
          <nz-switch [(ngModel)]="roiSubscription.optional_targets.sms.subscribing"
            [nzDisabled]="!roiSubscription.subscribing" (click)="toggleNotificationSettings(roiSubscription, 'sms')">
          </nz-switch>
        </ng-template>
      </nz-list-item-meta>
    </nz-list-item>
  </nz-list>
</ng-template>

<ng-template #styles>
  <app-customer-product-styles></app-customer-product-styles>
</ng-template>
<!--      DIGEST SUBSCRIPTONS   -->
<ng-template #digestList let-subscription="subscription">
  <nz-list [nzItemLayout]="'horizontal'" class="subscription-list">
    <nz-list-item class="ant-list-item ant-subscription-item" [nzExtra]="nzextra">
      <nz-list-item-meta [nzTitle]="nzTitle" [nzDescription]="nzDescription" class="sub-list setting-list">
        <ng-template #nzTitle>
          {{subscription.display_name}}
        </ng-template>
        <ng-template #nzDescription>
          <div class="subscription-text">
            <!--                  Turning this off will hide the price of your listing on any content relating to a listing being sold. (i.e. a congrats video or sold video)-->
          </div>
        </ng-template>
        <ng-template #nzextra>
          <nz-switch [(ngModel)]="subscription.status" (click)="toggleDigestSetting(subscription)"></nz-switch>
        </ng-template>

      </nz-list-item-meta>
    </nz-list-item>
  </nz-list>
</ng-template>
<!--      Captions   -->
<ng-template #captions>

  <div class="subscription-list">
    <div style="padding: 16px">
      <div class="email-subscription">
        <app-captions></app-captions>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #nzDescription2>
  <div class="subscription-text">
  </div>
</ng-template>
