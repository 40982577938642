<div class="container">
  <div class="header">
    <div class="header-title">
      {{ content.display_name || content.content_attributes.display_name }}
    </div>
    <div class="header-subtitle">
      <div class="subtitle-social-icon">
        <ng-container *ngFor="let post of posts">
          <span *ngIf="post.status === 'successful'" class="icon-wrapper" (click)="openModal(post.id)">
            <img class="box-icon" [src]="'.././../../assets/icons/' + post.posted_on + '.png'" alt="" />
          </span>
        </ng-container>
      </div>
      <div class="subtitle-time">
        <span class="subtitle-time-icon">
          <span nz-icon nzType="calendar" nzTheme="outline" style="font-size: 24px;"></span>
        </span>
        <span class="subtitle-time-text">
          {{ content.created_at | date: 'EEE, MMM d' }}
        </span>
      </div>
    </div>
  </div>

  <div class="content-preview">
    <div class="content-preview-image">
      <div
        *ngIf="content.content_attributes.status !== 'rendering'"
        (click)="showPreview(content.content_attributes)"
        class="image-wrapper"
      >
        <!-- Image Content -->
        <img
          *ngIf="content.content_attributes.media_type === 'image'"
          [src]="content.content_attributes.url"
          [alt]="content.content_attributes.caption"
          loading="lazy"
        />
  
        <!-- Carousel Content -->
        <img
          *ngIf="content.content_attributes.media_type === 'carousel'"
          [src]="content.content_attributes.fileURLs[0]"
          [alt]="content.content_attributes.caption"
          loading="lazy"
        />
  
        <!-- Video Content -->
        <video
          *ngIf="content.content_attributes.media_type === 'video'"
          autoplay
          loop
          [muted]="true"
          controls
          playsinline
        >
          <source [src]="content.content_attributes.url" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  
    <div class="content-preview-details">
      <div class="description">
        <span class="title">Description:</span>
        <div class="text">
          <span [innerHTML]="sanitizedContent">
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="footer">
    <div class="button">
      <button nz-button nzType="primary" class="btnDelete" (click)="contentDeleted(content)" >
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
          <path d="M4.9165 14.5C4.54984 14.5 4.23595 14.3694 3.97484 14.1083C3.71373 13.8472 3.58317 13.5333 3.58317 13.1667V4.5H2.9165V3.16667H6.24984V2.5H10.2498V3.16667H13.5832V4.5H12.9165V13.1667C12.9165 13.5333 12.7859 13.8472 12.5248 14.1083C12.2637 14.3694 11.9498 14.5 11.5832 14.5H4.9165ZM11.5832 4.5H4.9165V13.1667H11.5832V4.5ZM6.24984 11.8333H7.58317V5.83333H6.24984V11.8333ZM8.9165 11.8333H10.2498V5.83333H8.9165V11.8333Z" fill="white"/>
        </svg>
        Delete
      </button>
      <button nz-button nzType="primary" class="btnConfirm" (click)="close()">
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
          <path d="M7.81634 11.5666L12.5163 6.86658L11.583 5.93325L7.81634 9.69992L5.91634 7.79992L4.98301 8.73325L7.81634 11.5666ZM8.74967 15.1666C7.82745 15.1666 6.96079 14.9916 6.14967 14.6416C5.33856 14.2916 4.63301 13.8166 4.03301 13.2166C3.43301 12.6166 2.95801 11.911 2.60801 11.0999C2.25801 10.2888 2.08301 9.42214 2.08301 8.49992C2.08301 7.5777 2.25801 6.71103 2.60801 5.89992C2.95801 5.08881 3.43301 4.38325 4.03301 3.78325C4.63301 3.18325 5.33856 2.70825 6.14967 2.35825C6.96079 2.00825 7.82745 1.83325 8.74967 1.83325C9.6719 1.83325 10.5386 2.00825 11.3497 2.35825C12.1608 2.70825 12.8663 3.18325 13.4663 3.78325C14.0663 4.38325 14.5413 5.08881 14.8913 5.89992C15.2413 6.71103 15.4163 7.5777 15.4163 8.49992C15.4163 9.42214 15.2413 10.2888 14.8913 11.0999C14.5413 11.911 14.0663 12.6166 13.4663 13.2166C12.8663 13.8166 12.1608 14.2916 11.3497 14.6416C10.5386 14.9916 9.6719 15.1666 8.74967 15.1666Z" fill="white"/>
        </svg>
        Confirm
      </button>
    </div>
  </div>
</div>