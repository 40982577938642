import { Resource } from '../resource/resource';

export class Infographic extends Resource {

  /**
   * id
   */
  id?: number;
  url: string;

  /**
   * title
   */
  thumbnail: string;

  media_type: string;

  caption: string;

  category: string;
  is_posted: any;
  aspect_ratio: any;

}
